// @ts-nocheck
import { Popover, Steps, StepsProps } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LoanApplicationViewDTO } from 'src/generated-api-client';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import styled from 'styled-components';

const { Step } = Steps;

export const ApplicationStepsView = observer(() => {
    const { t } = useTranslation();

    const {
        submitDate,
        creditBureauCheckDate,
        reviewApprovalDate,
        creditCommitteeApprovalDate,
        offerAcceptanceDate,
        agreementSigningDate,
    } = ApplicationStore.currentItem || ({} as LoanApplicationViewDTO);

    const stepsList = [
        {
            key: 0,
            title: t('Components.ApplicationStepsView.Application created'),
            date: submitDate,
            status: getCurrentStatus(true, submitDate),
        },
        {
            key: 1,
            title: t('Components.ApplicationStepsView.CreditBureauReview'),
            date: creditBureauCheckDate,
            status: getCurrentStatus(creditBureauCheckDate, submitDate),
        },
        {
            key: 2,
            title: t('Components.ApplicationStepsView.FirstReview'),
            date: reviewApprovalDate,
            status: getCurrentStatus(reviewApprovalDate, creditBureauCheckDate),
        },
        {
            key: 3,
            title: t('Components.ApplicationStepsView.CreditCommittee'),
            date: creditCommitteeApprovalDate,
            status: getCurrentStatus(
                creditCommitteeApprovalDate,
                reviewApprovalDate,
            ),
        },
        {
            key: 4,
            title: t('Components.ApplicationStepsView.OfferSigned'),
            date: offerAcceptanceDate,
            status: getCurrentStatus(
                offerAcceptanceDate,
                creditCommitteeApprovalDate,
            ),
        },
        {
            key: 5,
            title: t('Components.ApplicationStepsView.AgreementSigned'),
            date: agreementSigningDate,
            status: getCurrentStatus(agreementSigningDate, offerAcceptanceDate),
        },
    ];

    const currentStep = stepsList.find(
        ({ status }) => status === ApplicationStatus.WAIT,
    );

    return (
        <StyledSteps current={currentStep?.key} progressDot={customDot}>
            {stepsList.map(({ date, title, key, status }) => (
                <Step
                    title={title}
                    status={status as any}
                    key={key}
                    description={
                        date ? moment(date).format('DD MMMM YYYY') : null
                    }
                />
            ))}
        </StyledSteps>
    );
});

const customDot: StepsProps['progressDot'] = (dot) => (
    <Popover>
        <span>{dot}</span>
    </Popover>
);

const StyledSteps = styled(Steps)`
    margin-top: 20px;
    padding-top: 30px;
    .ant-steps-item-description {
        width: 150px;
    }
    .ant-steps-item-title {
        position: absolute;
        font-size: 14px;
        white-space: nowrap;
        bottom: 45px;
        transform: translate(-50%, -50%);
    }
`;

export enum ApplicationStatus {
    FINISH = 'finish',
    WAIT = 'wait',
    PROCESS = 'process',
}

const getCurrentStatus = (currentDate?: any, prevDate?: string) => {
    if (currentDate && prevDate) {
        return ApplicationStatus.FINISH;
    }
    if (!currentDate && prevDate) {
        return ApplicationStatus.WAIT;
    }
    if (!currentDate && !prevDate) {
        return ApplicationStatus.PROCESS;
    }
};
