import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { CustomerVerificationStatusView } from 'src/pages/ProfilePage/CustomerVerificationStatusView';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

const { Title } = Typography;

export const PageHeader = observer(() => {
    const { t } = useTranslation();

    return (
        <Row gutter={24} justify="space-between" align="middle">
            <Col>
                <Title level={1}>{t('Page.Dashboard.Title')}</Title>
            </Col>
            <Col>
                <Row wrap={false}>
                    <Col>
                        <StyledStatusTitle level={3}>
                            {t('Page.Dashboard.Status.Title')}
                        </StyledStatusTitle>
                    </Col>
                    <Col>
                        <CustomerVerificationStatusView
                            status={ProfileStore.user?.verificationStatus}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
});

const StyledStatusTitle = styled(Title)`
    margin-right: 20px;
`;
