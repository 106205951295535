/* eslint-disable max-lines */
import {
    Button,
    Col,
    Form as AntForm,
    Form,
    notification,
    Row,
    Typography,
} from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Brief } from 'src/components/Brief/Brief';
import { DnDUploader } from 'src/components/DnDUploader/DnDUploader';
import { FilesListComponent } from 'src/components/FilesListComponent/FilesListComponent';
import {
    FileMeasures,
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    DocumentDTO,
    DocumentDTODocumentTypeEnum,
} from 'src/generated-api-client';
import {
    CustomerDocumentsStore,
    CustomerDocumentsTypes,
} from 'src/stores/CustomerDocumentsStore/CustomerDocumentsStore';
import styled from 'styled-components';

const { Title } = Typography;

const store = CustomerDocumentsStore;

export const UpdateDocumentsPage = observer(() => {
    const documentsMatrix = [
        {
            title: (
                <Trans
                    i18nKey="Page.UpdateDocuments.CIPCDocument"
                    defaults="Company Registration Documents (COR39)"
                />
            ),
            name: DocumentDTODocumentTypeEnum.CUSTOMERCIPC,
            currentDocuments: store.customerCIPCDocs,
        },
        {
            title: (
                <Trans
                    i18nKey="Page.UpdateDocuments.DirectorsId"
                    defaults="Directors Certified ID Copy"
                />
            ),
            name: DocumentDTODocumentTypeEnum.CUSTOMERDIRECTORIDS,
            currentDocuments: store.customerDirectorIdsDocs,
        },
        {
            title: (
                <Trans
                    i18nKey="Page.UpdateDocuments.TaxDocuments"
                    defaults="Tax Clearance Document"
                />
            ),
            name: DocumentDTODocumentTypeEnum.CUSTOMERTAXCLEARANCE,
            currentDocuments: store.customerTaxClearanceDocs,
        },
        {
            title: (
                <Trans
                    i18nKey="Page.UpdateDocuments.ProofOfAddress"
                    defaults="Proof of address"
                />
            ),
            name: DocumentDTODocumentTypeEnum.CUSTOMERPROOFOFADDRESS,
            currentDocuments: store.customerProofOfAddress,
        },
        {
            title: (
                <Trans
                    i18nKey="Page.UpdateDocuments.SecurityLetter"
                    defaults="Authorization and security letter"
                />
            ),
            name: DocumentDTODocumentTypeEnum.CUSTOMERAUTHORIZATIONLETTER,
            currentDocuments: store.customerAuthorizationLetter,
        },
    ];

    const [form] = AntForm.useForm();

    useEffect(() => {
        store.loadList();
    }, []);

    return (
        <BasePage>
            <SignUpPageContainer>
                <Brief>
                    <Row>
                        <Col flex="auto">
                            <StyledPageTitle level={2}>
                                PLEASE, UPDATE YOUR COMPANY DOCUMENTS
                            </StyledPageTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col flex="auto">
                            <StyledPageTitle level={3}>
                                According to the Mincap&rsquo;s policy we need
                                to update company documents every 3 months.
                                <br />
                                Re-upload any of these documents in case if they
                                are not
                                <br /> actual
                            </StyledPageTitle>
                        </Col>
                    </Row>
                    <Form
                        form={form}
                        onFinish={async (values) => {
                            const documents = Object.entries(values).filter(
                                (item) => item[1] !== undefined,
                            );
                            if (documents.length) {
                                try {
                                    await CustomerDocumentsStore.uploadCustomerDocuments(
                                        documents as [
                                            type: CustomerDocumentsTypes,
                                            files: any,
                                        ][],
                                    );
                                    notification.success({
                                        message: 'Documents was updated',
                                    });
                                    await store.confirmDocuments();
                                } catch (error: any) {
                                    notification.error({
                                        message: t(
                                            'Error.SomethingWentWrong',
                                            'Something went wrong',
                                        ),
                                        description:
                                            error?.detail || error.title,
                                    });
                                }
                            } else {
                                store.confirmDocuments();
                            }
                        }}
                    >
                        <Row gutter={[24, 24]} justify="space-between">
                            {documentsMatrix.map((item) => (
                                <Col span={11}>
                                    <DnDUploader
                                        maxCount={20}
                                        name={item.name}
                                        label={item.title}
                                        rules={[
                                            ValidationRules.maxFileSize(
                                                25,
                                                FileSizeUnit.Mb,
                                                FileMeasures.mb,
                                            ),
                                            ValidationRules.validateFilesType,
                                        ]}
                                    />
                                    <FilesListComponent
                                        filesList={
                                            item.currentDocuments as DocumentDTO[]
                                        }
                                        store={store}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <Row gutter={24} justify="center">
                            <StyledButtonContainer>
                                <StyledResetButton
                                    key="cancel"
                                    onClick={() => {
                                        form.resetFields();
                                        Router.navigate(RoutePaths.dashboard);
                                    }}
                                >
                                    {t('Button.Cancel')}
                                </StyledResetButton>

                                <StyledSaveButton
                                    key="ok"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {t('Button.ConfirmDocuments')}
                                </StyledSaveButton>
                            </StyledButtonContainer>
                        </Row>
                    </Form>
                </Brief>
            </SignUpPageContainer>
        </BasePage>
    );
});

const SignUpPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 150px;
`;

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    margin-top: 20px;
    text-align: center;
    white-space: nowrap;
    flex-wrap: nowrap;
`;

const StyledSaveButton = styled(Button)`
    height: 42px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;
