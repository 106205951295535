import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

export type StatusViewType = {
    backgroundColor?: string;
    title?: React.ReactNode;
};

export const StatusView = observer(
    ({ backgroundColor, title }: StatusViewType) => {
        return (
            <StyledStatus backgroundColor={backgroundColor}>
                {title}
            </StyledStatus>
        );
    },
);

const StyledStatus = styled.div<{ backgroundColor?: string }>`
    min-width: 280px;
    display: flex;
    border: 1px solid #79797970;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) => props.backgroundColor};
    border-radius: 5px;
    min-height: 35px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    letter-spacing: 1px;
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    @media only screen and (max-width: 992px) {
        min-width: initial;
    }
`;
