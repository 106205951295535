/* eslint-disable import/no-cycle */
import { Env } from 'src/core/Environment';
import { LoanStepId } from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';

export const RoutePaths = {
    index: `${Env.basePath}/`,

    pageNotFound: `${Env.basePath}/not-found`,

    signupSecondStep: `${Env.basePath}/signup-step-2`,
    signupThirdStep: `${Env.basePath}/signup-step-3`,

    informationPage: `${Env.basePath}/signup-agreement`,

    applyForALoan: `${Env.basePath}/apply-loan`,
    applyForALoanStep: `${Env.basePath}/apply-loan/:stepId`,
    editApplication: (loanStep: LoanStepId, applicationId: string) =>
        `${Env.basePath}/apply-loan/${loanStep}?applicationId=${applicationId}`,

    applyForALoanDocuments: `${Env.basePath}/apply-loan-documents`,

    dashboard: `${Env.basePath}/dashboard`,
    profile: `${Env.basePath}/profile`,

    loanApplications: `${Env.basePath}/applications`,
    loanApplication: `${Env.basePath}/applications/:id`,

    updateDocuments: `${Env.basePath}/update-documents`,

    offerLetter: `${Env.basePath}/applications/:applicationId/offer-letter`,

    signContract: `${Env.basePath}/applications/:applicationId/sign-contract`,

    loans: `${Env.basePath}/loan`,
    loan: `${Env.basePath}/loan/:loanId`,

    uploadApplicationDocuments: (applicationId: string, type: string) =>
        `${Env.basePath}/api/application/document/save?applicationId=${applicationId}&type=${type}`,

    uploadCustomerDocuments: (type: string) =>
        `${Env.basePath}/api/customer/document/save?type=${type}`,

    faq: `${Env.basePath}/faq`,
};
