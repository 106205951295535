/* eslint-disable unicorn/no-abusive-eslint-disable */
import {
    FileExcelOutlined,
    FileJpgOutlined,
    FileOutlined,
    FilePdfOutlined,
    FileWordOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { DocumentDTO, DocumentDTOFileTypeEnum } from 'src/generated-api-client';
import styled from 'styled-components';

const FileTypeIconMap = (type: string) => {
    switch (type) {
        case 'pdf':
            return <FilePdfOutlined width={2} />;
        case 'doc':
            return <FileWordOutlined />;
        case 'docx':
            return <FileWordOutlined />;
        case 'xlsx':
            return <FileExcelOutlined />;
        case 'jpg':
            return <FileJpgOutlined />;
        case 'jpeg':
            return <FileJpgOutlined />;
        default:
            return <FileOutlined />;
    }
};

export const FileComponent = ({
    file,
    store,
}: {
    file: DocumentDTO;
    // eslint-disable-next-line react/require-default-props
    store?: any;
}) => {
    return (
        <FileContainer
            onClick={() =>
                store?.downloadDocuments(
                    file.documentId as number,
                    file.fileType as DocumentDTOFileTypeEnum,
                    file.fileName as string,
                )
            }
        >
            {FileTypeIconMap((file.fileName as string)?.split('.')[1])}

            <Tooltip title={file.fileName}>
                <EllipsisContainer>{file.fileName}</EllipsisContainer>
            </Tooltip>
        </FileContainer>
    );
};

const FileContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 1em;
    cursor: pointer;
    transition: all ease 0.2s;
    span {
        margin-right: 5px;
        font-size: 2.5em;
    }
    &:hover {
        opacity: 0.7;
    }
`;
