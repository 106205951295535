/* eslint-disable max-lines */
import { Col, Form, FormInstance, notification, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import {
    LoanApplicationDisclosure,
    LoanApplicationViewDTO,
} from 'src/generated-api-client';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import { ApplyForALoanStore } from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { UploadFilesStatusStore } from 'src/stores/UploadFilesStatusStore/UploadFilesStatusStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';

import { ProvideAdditionalDocument } from './ProvideAdditionalDocument';

type ProvideAdditionalInformationProps = {
    currentApplication: LoanApplicationViewDTO;
};

const { Title, Text } = Typography;

export const ProvideAdditionalInformation = observer(
    ({ currentApplication, ...props }: ProvideAdditionalInformationProps) => {
        const { t } = useTranslation();

        const [form] = Form.useForm();

        const [isRequiredBankDetails, setIsRequiredBankDetails] =
            useState(false);

        const isRequired = [
            isRequiredBankDetails ? ValidationRules.bankDetails : {},
        ];

        const bankName = Form?.useWatch(
            ['application', 'thirdPartyBankName'],
            form,
        );
        const accountName = Form?.useWatch(
            ['application', 'thirdPartyAccountName'],
            form,
        );
        const accountType = Form?.useWatch(
            ['application', 'thirdPartyAccountType'],
            form,
        );
        const branchCode = Form?.useWatch(
            ['application', 'thirdPartyBranchCode'],
            form,
        );
        const accountNumber = Form?.useWatch(
            ['application', 'thirdPartyAccountNumber'],
            form,
        );

        useEffect(() => {
            if (
                bankName ||
                accountName ||
                accountNumber ||
                accountType ||
                branchCode
            ) {
                setIsRequiredBankDetails(true);
            } else {
                setIsRequiredBankDetails(false);
                resetBankDetailsFields(form);
            }
        }, [bankName, accountType, accountName, accountNumber, branchCode]);

        const initialValue = {
            application: {
                bankAccountId: currentApplication?.bankAccount?.id,
                thirdPartyAccountName:
                    currentApplication?.thirdParty?.bankAccount?.accountName,
                thirdPartyAccountNumber:
                    currentApplication?.thirdParty?.bankAccount?.accountNumber,
                thirdPartyAccountType:
                    currentApplication?.thirdParty?.bankAccount?.accountType,
                thirdPartyAddress: currentApplication?.thirdParty?.address,
                thirdPartyBankName:
                    currentApplication?.thirdParty?.bankAccount?.bankName,
                thirdPartyBranchCode:
                    currentApplication?.thirdParty?.bankAccount?.branchCode,
                thirdPartyCipc: currentApplication?.thirdParty?.cipc,
                thirdPartyEmail: currentApplication?.thirdParty?.email,
                thirdPartyLegalName: currentApplication?.thirdParty?.legalName,
            },
        };

        useEffect(() => {
            form.setFieldsValue(initialValue);
        }, []);

        return (
            <Modal
                {...props}
                width={2000}
                form={form}
                documentLoader={
                    UploadFilesStatusStore.getUploadingProgressBarStatus
                }
                initialValues={initialValue}
                isLoading={ApplicationStore.updateRequestedInfoAction.isLoading}
                onFinish={async (values) => {
                    const documents = Object.entries(values?.documents) || [];
                    try {
                        await ApplyForALoanStore.uploadApplicationDocuments(
                            documents,
                            currentApplication?.id?.toString() as string,
                        );
                        await ApplicationStore.updateRequestedInfo({
                            ...values.application,
                            id: currentApplication?.id,
                        });

                        await ApplyForALoanStore.sendToInitialReview(
                            currentApplication?.id?.toString() as string,
                        );

                        await ApplicationStore.loadItem(
                            currentApplication?.id?.toString(),
                        );
                    } catch (error) {
                        notification.error({
                            message: t(
                                'Error.SomethingWentWrong',
                                'Something went wrong',
                            ),
                        });
                    }
                }}
            >
                <Row gutter={24} justify="center">
                    <Col span={19}>
                        <Row gutter={[24, 24]} justify="space-between">
                            <Col span={24}>
                                <Title level={3}>
                                    {t(
                                        'Application.Modal.ProvideAdditionalInformation.Title',
                                        'Please, clarify some information:',
                                    )}
                                </Title>
                            </Col>
                            <Col span={24}>
                                <Title level={4}>
                                    {t(
                                        'Application.Modal.ProvideAdditionalInformation.SubTitle',
                                        'Credit officer comment:',
                                    )}
                                </Title>
                                <Text>{currentApplication.requestNote}</Text>
                            </Col>
                            {currentApplication.disclosure ===
                                LoanApplicationDisclosure.DISCLOSED && (
                                <>
                                    <Col span={24}>
                                        <Title level={4}>
                                            {t(
                                                'Application.Modal.ProvideAdditionalInformation.Counterparty',
                                                'Counterparty:',
                                            )}
                                        </Title>
                                    </Col>
                                    <Col span={12}>
                                        <TextField
                                            name={[
                                                'application',
                                                'thirdPartyCipc',
                                            ]}
                                            label={t(
                                                'Application.Modal.ProvideAdditionalInformation.Label.CIPC',
                                            )}
                                            rules={[
                                                ValidationRules.required,
                                                ValidationRules.isTheSameCIPCValue(
                                                    ProfileStore.user?.cipc,
                                                ),
                                                ValidationRules.CIPCFormatValidation,
                                            ]}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <TextField
                                            name={[
                                                'application',
                                                'thirdPartyLegalName',
                                            ]}
                                            label={t(
                                                'Application.Modal.ProvideAdditionalInformation.Label.LegalName',
                                            )}
                                            rules={[
                                                ValidationRules.required,
                                                ValidationRules.maxLengthLegalName,
                                            ]}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <TextField
                                            name={[
                                                'application',
                                                'thirdPartyEmail',
                                            ]}
                                            label={t(
                                                'Application.Modal.ProvideAdditionalInformation.Label.Email',
                                            )}
                                            rules={[
                                                ValidationRules.required,
                                                ValidationRules.email,
                                            ]}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <TextField
                                            name={[
                                                'application',
                                                'thirdPartyAddress',
                                            ]}
                                            label={t(
                                                'Application.Modal.ProvideAdditionalInformation.Label.FullAddress',
                                            )}
                                            rules={[
                                                ValidationRules.required,
                                                ValidationRules.max255Symbols,
                                            ]}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <TextField
                                            name={[
                                                'application',
                                                'thirdPartyBankName',
                                            ]}
                                            label={t(
                                                'Application.Modal.ProvideAdditionalInformation.Label.BankName',
                                            )}
                                            rules={isRequired}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <TextField
                                            name={[
                                                'application',
                                                'thirdPartyAccountName',
                                            ]}
                                            label={t(
                                                'Application.Modal.ProvideAdditionalInformation.Label.AccountName',
                                            )}
                                            rules={isRequired}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <TextField
                                            name={[
                                                'application',
                                                'thirdPartyAccountType',
                                            ]}
                                            label={t(
                                                'Application.Modal.ProvideAdditionalInformation.Label.AccountType',
                                            )}
                                            rules={isRequired}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <TextField
                                            name={[
                                                'application',
                                                'thirdPartyBranchCode',
                                            ]}
                                            label={t(
                                                'Application.Modal.ProvideAdditionalInformation.Label.BranchCode',
                                            )}
                                            rules={isRequired}
                                        />
                                    </Col>

                                    <Col span={12}>
                                        <TextField
                                            name={[
                                                'application',
                                                'thirdPartyAccountNumber',
                                            ]}
                                            label={t(
                                                'Application.Modal.ProvideAdditionalInformation.Label.AccountNumber',
                                            )}
                                            rules={isRequired}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                        <ProvideAdditionalDocument
                            currentApplication={currentApplication}
                        />
                        <Row gutter={24}>
                            <Col span={24}>
                                <Title level={4}>
                                    {t(
                                        'Application.Modal.ProvideAdditionalInformation.Label.BankAccount',
                                    )}
                                </Title>
                            </Col>

                            <Col span={24}>
                                <Title level={5}>
                                    {t(
                                        'Application.Modal.ProvideAdditionalInformation.Label.BankAccount.SubTitle',
                                    )}
                                </Title>
                            </Col>
                            <Col span={9}>
                                <SelectField
                                    convertListItemToOption={(bank: any) => ({
                                        children: bank.accountName,
                                        value: bank.id,
                                    })}
                                    initialValue={
                                        currentApplication?.bankAccount?.id
                                    }
                                    name={['application', 'bankAccountId']}
                                    label="Bank name:"
                                    data={ProfileStore.user?.bankAccounts}
                                    rules={[ValidationRules.required]}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        );
    },
);

export const ProvideAdditionalInformationModal = addModalRenderer(
    ProvideAdditionalInformation,
);

const resetBankDetailsFields = (form: FormInstance<any>) => {
    const resetData = {
        value: '',
        touched: false,
        errors: [],
    };

    form.setFields([
        {
            name: ['application', 'thirdPartyBankName'],
            ...resetData,
        },
        {
            name: ['application', 'thirdPartyAccountName'],
            ...resetData,
        },
        {
            name: ['application', 'thirdPartyAccountType'],
            ...resetData,
        },
        {
            name: ['application', 'thirdPartyBranchCode'],
            ...resetData,
        },
        {
            name: ['application', 'thirdPartyAccountNumber'],
            ...resetData,
        },
    ]);
};
