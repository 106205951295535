/* eslint-disable max-lines */
import {
    Button,
    Col,
    Form as AntForm,
    Radio,
    Row,
    Space,
    Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'src/components/Form/Form';
import { SelectField } from 'src/components/Form/SelectField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    FeeRepaymentMethod,
    LoanApplicationType,
} from 'src/generated-api-client';
import {
    ApplyForALoanStore,
    LoanStepId,
} from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

const { Title } = Typography;

const storeProfile = ProfileStore;

const store = ApplyForALoanStore;

export const SecondStep = observer(() => {
    const { t } = useTranslation();

    const [form] = AntForm.useForm();
    const applicationId = new URLSearchParams(
        Router.getQuerySearchParams(),
    ).get('applicationId');

    const currentApplication = store.initialApplicationData;

    useEffect(() => {
        if (applicationId && !currentApplication?.type) {
            store.loadItem(applicationId as string);
        }
    }, []);

    form.setFieldsValue(currentApplication);

    const cancelForm = currentApplication?.id
        ? () => {
              store.cancelApplicationItem(Number(applicationId) as number);
              store.resetFormApplication();
          }
        : () => {
              form.resetFields();
              Router.navigate('/');
              store.resetFormApplication();
          };

    return (
        <Form
            form={form}
            initialValues={{
                feeRepaymentMethod: FeeRepaymentMethod.ADDTOLOAN,
                ...currentApplication,
            }}
            onFinish={async (values) => {
                try {
                    currentApplication?.id
                        ? await store.updateItem({
                              ...currentApplication,
                              ...values,
                          })
                        : await store.createItem({
                              ...currentApplication,
                              ...values,
                          });

                    store.resetFormApplication();
                } catch (error) {
                    console.warn(error);
                }
            }}
        >
            <Row gutter={24} justify="start">
                <Col>
                    <StyledPageTitle level={3}>
                        {t(
                            'Page.ApplyForALoan.FeeRepaymentType',
                            'Choose your initiation fee repayment option',
                        )}
                    </StyledPageTitle>
                </Col>
            </Row>
            <Row gutter={24} justify="start">
                <Col>
                    <StyledPageTitle level={5}>
                        {t(
                            'Page.ApplyForALoan.FeeRepaymentType.Info',
                            'Final fee amount will be displayed in the loan offer after application consideration',
                        )}
                    </StyledPageTitle>
                </Col>
            </Row>
            <Row gutter={24} justify="start">
                <Col>
                    <AntForm.Item name="feeRepaymentMethod">
                        <Radio.Group
                            disabled={
                                currentApplication?.type ===
                                LoanApplicationType.OUTANDOUTINVOICEFINANCE
                            }
                        >
                            <Space direction="vertical">
                                <StyledRadio
                                    value={FeeRepaymentMethod.ADDTOLOAN}
                                >
                                    {t(
                                        'Page.ApplyForALoan.FeeRepaymentType.AddToTotal',
                                        'Add to the total cost of credit',
                                    )}
                                </StyledRadio>
                                <StyledRadio
                                    value={FeeRepaymentMethod.UPFRONTPAYMENT}
                                >
                                    {t(
                                        'Page.ApplyForALoan.FeeRepaymentType.PayItBefore',
                                        'Pay it before the loan disbursement',
                                    )}
                                </StyledRadio>
                                <StyledRadio
                                    value={
                                        FeeRepaymentMethod.DEDUCTFROMDISBURSEMENT
                                    }
                                >
                                    {t(
                                        'Page.ApplyForALoan.FeeRepaymentType.Deduct',
                                        'Deduct from the disbursement amount',
                                    )}
                                </StyledRadio>
                            </Space>
                        </Radio.Group>
                    </AntForm.Item>
                </Col>
            </Row>
            <Row gutter={24} justify="start">
                <Col>
                    <StyledPageTitle level={3}>
                        {t(
                            'Page.ApplyForALoan.FeeRepaymentType.BankAccount',
                            'Choose your bank account:',
                        )}
                    </StyledPageTitle>
                </Col>
            </Row>
            <Row gutter={24} justify="start">
                <Col>
                    <StyledPageTitle level={5}>
                        {t(
                            'Page.ApplyForALoan.FeeRepaymentType.BankAccount.Info',
                            'After loan approval we will send money to this account',
                        )}
                    </StyledPageTitle>
                </Col>
            </Row>
            <Row gutter={24} justify="space-between">
                <Col span={9}>
                    <SelectField
                        convertListItemToOption={(bank: any) => ({
                            children: bank.accountName,
                            value: bank.id,
                        })}
                        initialValue={currentApplication?.bankAccount?.id}
                        name="bankAccountId"
                        label="Bank name:"
                        data={storeProfile.user?.bankAccounts}
                        rules={[ValidationRules.required]}
                    />
                </Col>
            </Row>
            <Row gutter={24} justify="center">
                <StyledButtonContainer>
                    <StyledResetButton key="cancel" onClick={cancelForm}>
                        {t('Button.Cancel')}
                    </StyledResetButton>

                    <StyledBackButton
                        key="back"
                        type="primary"
                        onClick={() => {
                            currentApplication?.id
                                ? Router.navigate(
                                      `${RoutePaths.applyForALoan}/${LoanStepId.FIRST}?applicationId=${applicationId}`,
                                  )
                                : Router.navigate(
                                      `${RoutePaths.applyForALoan}/${LoanStepId.FIRST}`,
                                  );
                        }}
                    >
                        {t('Components.Button.Back')}
                    </StyledBackButton>

                    <StyledSaveButton
                        key="ok"
                        type="primary"
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        {t('Button.Next')}
                    </StyledSaveButton>
                </StyledButtonContainer>
            </Row>
        </Form>
    );
});

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;

const StyledRadio = styled(Radio)`
    span {
        font-size: 16px;
    }
    .ant-radio-inner {
        border-color: #000000;
    }
    .ant-radio-inner::after {
        background-color: #5c5c5c;
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: #5c5c5c;
    }
`;

const StyledButtonContainer = styled(Col)`
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    text-align: center;
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const StyledBackButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: transparent;
    color: #000000;
    border: 1.5px solid #000000;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: transparent;
        color: #aaaaaa;
        border: 1.5px solid #aaaaaa;
    }
`;
