/* eslint-disable max-lines */
import { Button, Col, Form as AntForm, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { Brief } from 'src/components/Brief/Brief';
import { Form } from 'src/components/Form/Form';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { FormHelper } from 'src/utils/FormHelper';
import { PHONE_MASK } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const { Title, Paragraph } = Typography;

const store = ProfileStore;

export const SecondStepSignUpPage = observer(() => {
    const [form] = AntForm.useForm();
    const { t } = useTranslation();

    const fields = FormHelper.getFieldDataItems(
        store.setProfileSecondStepDataAction.errorData,
    );

    return (
        <BasePage isLoading={store.setProfileSecondStepDataAction.isLoading}>
            <Brief>
                <Row>
                    <Col flex="auto">
                        <StyledPageTitle level={2}>
                            {t(
                                'Page.InformationPage.RegistrationForm',
                                ' Registration form',
                            )}
                        </StyledPageTitle>
                    </Col>
                </Row>
                <Row>
                    <Col flex="auto">
                        <StyledPageTitle level={3}>
                            <Trans i18nKey="Page.InformationPage.Title" />
                        </StyledPageTitle>
                    </Col>
                </Row>
                <Form
                    form={form}
                    fields={fields}
                    onFinish={(values) => {
                        store.setProfileSecondStepDataAction.reset();
                        store.setProfileSecondStepData(values);
                    }}
                >
                    <Row>
                        <BlockTitle>
                            {t(
                                'Page.InformationPage.CompanyDetails',
                                'COMPANY DETAILS',
                            )}
                        </BlockTitle>
                    </Row>
                    <Row gutter={24} justify="space-between">
                        <Col span={6}>
                            <TextField
                                name="legalName"
                                label={t(
                                    'Page.InformationPage.LegalName',
                                    'Legal name',
                                )}
                                initialValue={store.user?.legalName}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                name="taxId"
                                label={t(
                                    'Page.InformationPage.TaxId',
                                    'Tax number',
                                )}
                                initialValue={store.user?.taxId}
                                onlyNumbers
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxTaxIDLength,
                                    ValidationRules.minTaxIDLength,
                                ]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                name="email"
                                label={t('Page.InformationPage.Email', 'Email')}
                                initialValue={store.user?.contactDetails.email}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.email,
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <TextField
                                name="telephoneNumber"
                                label={t(
                                    'Page.InformationPage.Phone',
                                    'Telephone number',
                                )}
                                initialValue={store.user?.contactDetails.phoneNumber?.replace(
                                    PHONE_MASK,
                                    '',
                                )}
                                prefix={PHONE_MASK}
                                onlyNumbers
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxPhoneLength,
                                    ValidationRules.minPhoneLength,
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <BlockTitle>
                            {t(
                                'Page.InformationPage.CompanyAdress',
                                'COMPANY ADDRESS',
                            )}
                        </BlockTitle>
                    </Row>
                    <Row gutter={24} justify="space-between">
                        <Col span={6}>
                            <SelectField
                                getData={() => ProfileStore?.getCountries()}
                                convertListItemToOption={(country: any) => ({
                                    children: country.name,
                                    value: country.name,
                                })}
                                name="country"
                                initialValue={
                                    store.user?.contactDetails.address?.country
                                        ?.name
                                }
                                label={t(
                                    'Page.InformationPage.Country',
                                    'Country',
                                )}
                                data={ProfileStore.countriesList}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                name="city"
                                label={t('Page.InformationPage.City', 'City')}
                                initialValue={
                                    store.user?.contactDetails.address?.city
                                }
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                        </Col>
                        <Col span={6}>
                            <SelectField
                                getData={() => ProfileStore?.getProvinces()}
                                convertListItemToOption={(province: any) => ({
                                    children: province.name,
                                    value: province.name,
                                })}
                                name="province"
                                label={t(
                                    'Page.InformationPage.Province',
                                    'Province',
                                )}
                                initialValue={
                                    store.user?.contactDetails.address?.province
                                        ?.name
                                }
                                data={ProfileStore.provincesList}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24} justify="space-between">
                        <Col span={6}>
                            <TextField
                                name="street"
                                label={t(
                                    'Page.InformationPage.Street',
                                    'Street',
                                )}
                                initialValue={
                                    store.user?.contactDetails.address?.city
                                }
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                name="house"
                                label={t(
                                    'Page.InformationPage.House',
                                    'Building number',
                                )}
                                initialValue={
                                    store.user?.contactDetails.address?.house
                                }
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                initialValue={
                                    store.user?.contactDetails.address
                                        ?.buildingName
                                }
                                name="buildingName"
                                label={t(
                                    'Page.InformationPage.BuildingName',
                                    'Building name',
                                )}
                                rules={[ValidationRules.maxLengthLegalName]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24} justify="space-between">
                        <Col span={6}>
                            <TextField
                                name="postalCode"
                                label={t(
                                    'Page.InformationPage.PostalCode',
                                    'Postal code',
                                )}
                                initialValue={
                                    store.user?.contactDetails.address
                                        ?.postalCode
                                }
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                        </Col>
                        <Col span={6} />
                        <Col span={6} />
                    </Row>
                    <Row>
                        <BlockTitle>
                            <Trans i18nKey="Page.InformationPage.CompanyInfo" />
                        </BlockTitle>
                    </Row>
                    <Row gutter={24} justify="space-between">
                        <Col span={6}>
                            <TextField
                                name="representativeFullName"
                                label={t(
                                    'Page.InformationPage.RepresentativeFullName',
                                    'Full name',
                                )}
                                initialValue={
                                    store.user?.contactDetails
                                        .representativeFullName
                                }
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                name="representativePhoneNumber"
                                label={t(
                                    'Page.InformationPage.RepresentativePhoneNumber',
                                    'Phone number',
                                )}
                                initialValue={store.user?.contactDetails.representativePhoneNumber?.replace(
                                    PHONE_MASK,
                                    '',
                                )}
                                prefix={PHONE_MASK}
                                onlyNumbers
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxPhoneLength,
                                    ValidationRules.minPhoneLength,
                                ]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                name={['contactDetails', 'representativeEmail']}
                                label={t(
                                    'Page.InformationPage.RepresentativeEmail',
                                    'Email',
                                )}
                                initialValue={
                                    store.user?.contactDetails
                                        .representativeEmail
                                }
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.email,
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <BlockTitle>
                            {t('Page.InformationPage.BankAccount')}
                        </BlockTitle>
                        <Paragraph>
                            {t(
                                'Page.InformationPage.BankAccount.SubText',
                                'You will be able to manage them in your profile',
                            )}
                        </Paragraph>
                    </Row>
                    <Row gutter={24} justify="space-between">
                        <Col span={6}>
                            <TextField
                                initialValue={
                                    store.user?.bankAccounts[0]?.accountName
                                }
                                name="bankName"
                                label={t(
                                    'Page.InformationPage.BankName',
                                    'Bank name',
                                )}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                initialValue={
                                    store.user?.bankAccounts[0]?.accountName
                                }
                                name="accountName"
                                label={t(
                                    'Page.InformationPage.AccountName',
                                    'Account holder name',
                                )}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                initialValue={
                                    store.user?.bankAccounts[0]?.accountType
                                }
                                name="accountType"
                                label={t(
                                    'Page.InformationPage.AccountType',
                                    'Account type',
                                )}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24} justify="space-between">
                        <Col span={6}>
                            <TextField
                                initialValue={
                                    store.user?.bankAccounts[0]?.branchCode
                                }
                                name="branchCode"
                                label={t(
                                    'Page.InformationPage.BranchCode',
                                    'Branch code',
                                )}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={6}>
                            <TextField
                                initialValue={
                                    store.user?.bankAccounts[0]?.accountNumber
                                }
                                name="accountNumber"
                                label={t(
                                    'Page.InformationPage.AccountNumber',
                                    'Account number',
                                )}
                                rules={[ValidationRules.required]}
                            />
                        </Col>
                        <Col span={6} />
                    </Row>
                    <Row gutter={24} justify="center">
                        <StyledButtonContainer span={12} flex="nowrap">
                            <StyledResetButton
                                key="cancel"
                                loading={
                                    store.setProfileSecondStepDataAction
                                        .isLoading
                                }
                                onClick={() => {
                                    form.resetFields();
                                    AuthStore.logout(
                                        `${window.location.origin}/`,
                                    );
                                }}
                            >
                                {t('Button.Cancel', 'Cancel')}
                            </StyledResetButton>
                            ,
                            <StyledSaveButton
                                key="ok"
                                type="primary"
                                loading={
                                    store.setProfileSecondStepDataAction
                                        .isLoading
                                }
                                onClick={() => {
                                    form.submit();
                                }}
                            >
                                {t('Button.Next', 'Next')}
                            </StyledSaveButton>
                        </StyledButtonContainer>
                    </Row>
                </Form>
            </Brief>
        </BasePage>
    );
});

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;

const StyledButtonContainer = styled(Col)`
    margin-top: 20px;
    text-align: center;
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;
