import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'src/pages/Errors/NotFound';
import { RouterStore } from 'src/stores/RouterStore/RouterStore';

export const RoutesLayout = observer(() => {
    return (
        <Switch>
            {RouterStore.menuItems.map((route) => {
                const Component =
                    route.unavailable === true
                        ? NotAccessPage
                        : route.Component;

                return (
                    <Route
                        key={route.key}
                        path={route.path}
                        exact={route.exact !== false}
                    >
                        <Component />
                    </Route>
                );
            })}

            <Route path="*" component={NotFound} />
        </Switch>
    );
});

const NotAccessPage = () => <div>Not access</div>;
