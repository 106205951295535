import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';

export const IndexPage = observer(() => {
    useEffect(() => {
        ProfileStore.loadProfile();
    }, []);

    return <BasePage isLoading />;
});
