import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import URLParse from 'url-parse';

const getTargetUrl = (urlTemplate: string, params: Record<string, any>) => {
    return Object.entries(params).reduce((template, [key, value]) => {
        return template.replace(`:${key}`, value || '');
    }, urlTemplate);
};

const getTokenizedUrl = (url?: string) => {
    return URLParse(url || '').set('query', {
        access_token: AuthStore.token,
    });
};

const openLinkInNewTab = (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
};

const downloadFile = (url: string, file: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = file;
    a.target = '_blank';
    a.click();
};

export const UrlHelper = {
    getTargetUrl,
    getTokenizedUrl,
    openLinkInNewTab,
    downloadFile,
};
