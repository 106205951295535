/* eslint-disable max-lines */
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form as AntForm,
    FormInstance,
    Row,
    Typography,
} from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { DatePickerField } from 'src/components/Form/DatePickerField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import styled from 'styled-components';

const { Title } = Typography;

export const InvoiceItemsFormItem = observer(
    ({ form }: { form: FormInstance<any> }) => {
        return (
            <>
                <Row gutter={24} justify="center">
                    <Col>
                        <StyledPageTitle level={5}>
                            <Trans i18nKey="Page.ApplyForALoan.InvoiceItems.Title" />
                        </StyledPageTitle>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <AntForm.List name="financedItems">
                            {(fields, { add, remove }) => (
                                <div>
                                    {fields.map((field) => (
                                        <Row gutter={24} key={field.key}>
                                            <Col span={21}>
                                                <Row
                                                    gutter={24}
                                                    justify="space-between"
                                                >
                                                    <Col span={11}>
                                                        <TextField
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'itemNumber',
                                                            ]}
                                                            label={t(
                                                                'Page.ApplyForALoan.InvoiceItems.ItemNumber',
                                                                'Document #:',
                                                            )}
                                                            rules={[
                                                                ValidationRules.required,
                                                                {
                                                                    max: 55,
                                                                    message: t(
                                                                        'Validation.Error.Max55Symbols',
                                                                        'Max 55 symbols',
                                                                    ),
                                                                },
                                                            ]}
                                                        />
                                                    </Col>
                                                    <Col span={11}>
                                                        <DatePickerField
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'originationDate',
                                                            ]}
                                                            label={t(
                                                                'Page.ApplyForALoan.InvoiceItems.ItemOriginationDate',
                                                                'Document origination date:',
                                                            )}
                                                            rules={[
                                                                ValidationRules.required,
                                                            ]}
                                                        />
                                                    </Col>
                                                    <Col span={11}>
                                                        <DatePickerField
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'maturityDate',
                                                            ]}
                                                            label={t(
                                                                'Page.ApplyForALoan.InvoiceItems.ItemMaturityDate',
                                                                'Document maturity date:',
                                                            )}
                                                            rules={[
                                                                ValidationRules.required,
                                                            ]}
                                                        />
                                                    </Col>
                                                    <Col span={11}>
                                                        <TextField
                                                            {...field}
                                                            name={[
                                                                field.name,
                                                                'amount',
                                                            ]}
                                                            label={t(
                                                                'Page.ApplyForALoan.InvoiceItems.ItemAmount',
                                                                'Document amount:',
                                                            )}
                                                            rules={[
                                                                ValidationRules.required,
                                                                ValidationRules.decimal,
                                                                ValidationRules.notMoreThan100M,
                                                                ValidationRules.notLessThan1,
                                                            ]}
                                                        />
                                                    </Col>
                                                    <Col span={11}>
                                                        <TextField
                                                            {...field}
                                                            initialValue={0}
                                                            name={[
                                                                field.name,
                                                                'creditNote',
                                                            ]}
                                                            label={t(
                                                                'Page.ApplyForALoan.InvoiceItems.ItemCreditNote',
                                                                'Document credit note (if any):',
                                                            )}
                                                            rules={[
                                                                ValidationRules.required,
                                                                ValidationRules.decimal,
                                                                ValidationRules.mustBeLessThan(
                                                                    [
                                                                        'financedItems',
                                                                        field.name,
                                                                        'amount',
                                                                    ],
                                                                    t(
                                                                        'Validation.Error.LowerThanItemAmount',
                                                                        'Must be lower than item amount',
                                                                    ),
                                                                    form,
                                                                ),
                                                            ]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={3}>
                                                <StyledDeleteButton
                                                    disabled={fields.length < 2}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                    icon={<DeleteOutlined />}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Row gutter={24} justify="center">
                                        <Col span={12}>
                                            <AntForm.Item>
                                                <StyledPlusButton
                                                    disabled={
                                                        fields.length >= 20
                                                    }
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    icon={
                                                        <PlusCircleOutlined />
                                                    }
                                                />
                                            </AntForm.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </AntForm.List>
                    </Col>
                </Row>
            </>
        );
    },
);

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;

const StyledPlusButton = styled(Button)`
    width: 100%;
    height: 42px;
    color: #000000;
    border-color: #000000;
    svg {
        font-size: 1.5em;
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const StyledDeleteButton = styled(Button)`
    width: 100%;
    height: 42px;
    border: none;
    svg {
        font-size: 1.5em;
    }
    &:focus,
    &:active,
    &:hover {
        color: #222325;
        border: none;
    }
    &[disabled] {
        background: #ffffff;
        border: none;
    }
`;
