import { Form, FormItemProps } from 'antd';
import { Input } from 'src/components/Input/Input';

export type TextFieldProps = FormItemProps & {
    // eslint-disable-next-line react/require-default-props
    label?: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    readOnly?: boolean;
    // eslint-disable-next-line react/require-default-props
    disabled?: boolean;
    // eslint-disable-next-line react/require-default-props
    maxLength?: number;
    // eslint-disable-next-line react/require-default-props
    onlyNumbers?: boolean;
    // eslint-disable-next-line react/require-default-props
    prefix?: any;
    // eslint-disable-next-line react/require-default-props
    handleChange?: (_: any) => void;
};

export const TextField = ({
    name,
    label,
    readOnly,
    disabled,
    maxLength,
    onlyNumbers,
    prefix,
    handleChange,
    ...props
}: TextFieldProps) => {
    return (
        <Form.Item {...props} name={name} label={label}>
            <Input
                onChange={handleChange}
                readOnly={readOnly}
                disabled={disabled}
                maxLength={maxLength}
                onlyNumbers={onlyNumbers}
                prefix={prefix}
            />
        </Form.Item>
    );
};
