import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { LoanApplicationDisclosure } from 'src/generated-api-client';
import { FinancedDocumentDetails } from 'src/pages/LoanApplicationPage/components/FinancedDocumentDetails';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

export const ApplicationGeneralInfoTab = observer(() => {
    const { t } = useTranslation();
    const store = ApplicationStore.currentItem;
    const idDisclosure =
        store?.disclosure === LoanApplicationDisclosure.DISCLOSED;

    return (
        <>
            <Card title={t('Page.LoanApplicationPage.Tabs.ApplicationDetails')}>
                <DataView
                    label={t('Page.LoanApplicationPage.Tabs.Disclosure')}
                    value={store?.disclosure}
                    format="enum"
                    formatterProps={{ i18nKey: 'Loan.Application.Disclosure.' }}
                />
                <DataView
                    label={t(
                        'Page.LoanApplicationPage.Tabs.RequestedConditions',
                    )}
                    value={
                        store?.requestedAmount &&
                        store?.requestedTerm && (
                            <>
                                <StyledAmountContainer>
                                    <span>
                                        {NumberHelper.formatThousandSeparator(
                                            store?.requestedAmount,
                                        )}
                                    </span>
                                    {t('Loan.Application.Term.RandsFor')}
                                </StyledAmountContainer>
                                <StyledAmountContainer>
                                    <span>
                                        {t(
                                            `Loan.Application.Term.${store?.requestedTerm}`,
                                        )}
                                    </span>
                                    {t('Loan.Application.Term.Period')}
                                </StyledAmountContainer>
                            </>
                        )
                    }
                />
                <DataView
                    label={t(
                        'Page.LoanApplicationPage.Tabs.ApprovedConditions',
                    )}
                    value={
                        store?.approvedAmount &&
                        store?.approvedTerm && (
                            <>
                                <StyledAmountContainer>
                                    <span>
                                        {NumberHelper.formatThousandSeparator(
                                            store?.approvedAmount,
                                        )}
                                    </span>
                                    {t('Loan.Application.Term.RandsFor')}
                                </StyledAmountContainer>
                                <StyledAmountContainer>
                                    <span>
                                        {t(
                                            `Loan.Application.Term.${store?.approvedTerm}`,
                                        )}
                                    </span>
                                    {t('Loan.Application.Term.Period')}
                                </StyledAmountContainer>
                            </>
                        )
                    }
                />
                <DataView
                    label={t('Page.LoanApplicationPage.Tabs.ApplicationID')}
                    value={store?.id}
                />
                <DataView
                    label={t(
                        'Page.LoanApplicationPage.Tabs.FeeRepaymentMethod',
                    )}
                    value={store?.feeRepaymentMethod}
                    format="enum"
                    formatterProps={{ i18nKey: 'Fee.Repayment.Method.' }}
                />
            </Card>

            <DataView
                label={t('Page.LoanApplicationPage.Tabs.FinancedDocuments')}
                value={<FinancedDocumentDetails />}
            />

            {idDisclosure && (
                <Card
                    title={t(
                        'Page.LoanApplicationPage.Tabs.CounterpartyDetails',
                    )}
                >
                    <DataView
                        label={t('Page.LoanApplicationPage.Tabs.CIPC')}
                        value={store?.thirdParty?.cipc}
                    />
                    <DataView
                        label={t('Page.LoanApplicationPage.Tabs.LegalName')}
                        value={store?.thirdParty?.legalName}
                    />
                    <DataView
                        label={t('Page.LoanApplicationPage.Tabs.Email')}
                        value={store?.thirdParty?.email}
                    />
                    <DataView
                        label={t('Page.LoanApplicationPage.Tabs.FullAddress')}
                        value={store?.thirdParty?.address}
                    />
                    <DataView
                        label={t('Page.LoanApplicationPage.Tabs.BankName')}
                        value={store?.thirdParty?.bankAccount?.bankName}
                    />
                    <DataView
                        label={t('Page.LoanApplicationPage.Tabs.AccountName')}
                        value={store?.thirdParty?.bankAccount?.accountName}
                    />
                    <DataView
                        label={t('Page.LoanApplicationPage.Tabs.AccountType')}
                        value={store?.thirdParty?.bankAccount?.accountType}
                    />
                    <DataView
                        label={t('Page.LoanApplicationPage.Tabs.BranchCode')}
                        value={store?.thirdParty?.bankAccount?.branchCode}
                    />
                    <DataView
                        label={t('Page.LoanApplicationPage.Tabs.AccountNumber')}
                        value={store?.thirdParty?.bankAccount?.accountNumber}
                    />
                </Card>
            )}

            <Card title={t('Page.LoanApplicationPage.Tabs.BankAccount')}>
                <DataView
                    label={t('Page.LoanApplicationPage.Tabs.BankName')}
                    value={store?.bankAccount?.bankName}
                />
                <DataView
                    label={t('Page.LoanApplicationPage.Tabs.accountName')}
                    value={store?.bankAccount?.accountName}
                />
                <DataView
                    label={t('Page.LoanApplicationPage.Tabs.AccountType')}
                    value={store?.bankAccount?.accountType}
                />
                <DataView
                    label={t('Page.LoanApplicationPage.Tabs.BranchCode')}
                    value={store?.bankAccount?.branchCode}
                />
                <DataView
                    label={t('Page.LoanApplicationPage.Tabs.AccountNumber')}
                    value={store?.bankAccount?.accountNumber}
                />
            </Card>
        </>
    );
});

const StyledAmountContainer = styled.div`
    font-size: 20px;
    span {
        font-weight: 600;
    }
    span::after {
        content: ' ';
    }
`;
