import { Layout, Progress, Typography } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Spinner } from 'src/components/Spinner/Spinner';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { UploadFilesStatusStore } from 'src/stores/UploadFilesStatusStore/UploadFilesStatusStore';
import styled from 'styled-components';

const uploadingFilesStatusStore = UploadFilesStatusStore;

export type BasePageProps = Omit<React.HTMLAttributes<any>, 'title'> & {
    title?: React.ReactNode;
    header?: React.ReactNode;
    isLoading?: boolean;
    isUpdating?: boolean;
    showOnlyLoading?: boolean;
};
export const BasePage: React.FC<BasePageProps> = observer(
    ({ children, isLoading, title, header, showOnlyLoading, ...props }) => {
        const fileLoadingProgress = Math.round(
            uploadingFilesStatusStore.getUploadingProgressBarStatus,
        );

        if (showOnlyLoading && isLoading) {
            return (
                <StyledContent {...props}>
                    <StyledSpinner />
                </StyledContent>
            );
        }

        useEffect(() => {
            ApplicationsStore.loadList();
        }, []);

        return (
            <StyledContent {...props}>
                {isLoading && <StyledSpinner />}
                {uploadingFilesStatusStore.getUploadingProgressBarStatus >
                    0 && (
                    <StyledProgress
                        strokeColor={
                            fileLoadingProgress === 100 ? '#52c41a' : '#d00824'
                        }
                        type="circle"
                        percent={fileLoadingProgress}
                    />
                )}
                {title && <StyledPageTitle>{title}</StyledPageTitle>}
                {header && <StyledPageHeader>{header}</StyledPageHeader>}
                {children}
            </StyledContent>
        );
    },
);

export const StyledContent = styled<React.FC<BasicProps>>(Layout.Content)`
    max-width: 80%;
    margin: 0 auto;
    position: relative;
    padding-top: 20px;
    min-height: 200px;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 99;
    background-color: white;
    opacity: 0.9;
`;

const StyledPageTitle = styled(Typography.Title)`
    font-size: 40px;
    line-height: 105%;
    color: #0d0f1c;

    &.ant-typography {
        margin-bottom: 20px;
    }
`;

const StyledPageHeader = styled.div`
    position: relative;
    margin-bottom: 20px;
`;

const StyledProgress = styled(Progress)`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 99;
    background-color: white;
    opacity: 0.9;
`;
