import LogoIcon from 'src/icons/logoOfficeMinCap.png';
import styled from 'styled-components';

export const Logo = () => {
    return (
        <StyledLogoText>
            <img src={LogoIcon} alt="" />
        </StyledLogoText>
    );
};

const StyledLogoText = styled.div`
    max-width: 170px;
    max-height: 100%;
    img {
        width: 100%;
        max-height: 100%;
    }
`;
