import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { ListView } from 'src/components/ListView/ListView';
import { LoanApplicationFinancedItemDTO } from 'src/generated-api-client';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import styled from 'styled-components';

export const FinancedDocumentDetails = observer(() => {
    return (
        <StyledListView
            columns={columns}
            store={
                {
                    list: ApplicationStore.sortedFinancedItemsByASC || [],
                } as any
            }
            summary={(pageData) => {
                let sumAmount = 0;
                let sumCreditNote = 0;
                let sumTotalAmount = 0;

                pageData.forEach(({ amount, creditNote, totalAmount }) => {
                    sumAmount += amount || 0;
                    sumCreditNote += creditNote || 0;
                    sumTotalAmount += totalAmount || 0;
                });

                return (
                    <Table.Summary.Row className="app-table-row-total">
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} />
                        <Table.Summary.Cell index={2} />
                        <Table.Summary.Cell index={3}>
                            <DataView
                                value={sumAmount}
                                format="number-int"
                                hideLabel
                            />
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                            <DataView
                                value={sumCreditNote}
                                format="number-int"
                                hideLabel
                            />
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                            <DataView
                                value={sumTotalAmount}
                                format="number-int"
                                hideLabel
                            />
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6} />
                    </Table.Summary.Row>
                );
            }}
        />
    );
});

const columns: ColumnsType<LoanApplicationFinancedItemDTO> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: (
            <Trans i18nKey="Component.FinancedDocumentDetails.List.LineNumber" />
        ),
        render(value: string) {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'itemNumber',
        dataIndex: 'itemNumber',
        title: (
            <Trans i18nKey="Component.FinancedDocumentDetails.List.DocumentNumber" />
        ),
        render(value: string) {
            return <DataView value={value} hideLabel />;
        },
    },
    {
        key: 'originationDate',
        dataIndex: 'originationDate',
        width: 150,
        title: (
            <Trans i18nKey="Component.FinancedDocumentDetails.List.OriginationDate" />
        ),
        render(value: string) {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: (
            <Trans i18nKey="Component.FinancedDocumentDetails.List.OriginationPrice" />
        ),
        render(value: number) {
            return <DataView value={value} format="number-int" hideLabel />;
        },
    },
    {
        key: 'creditNote',
        dataIndex: 'creditNote',
        title: (
            <Trans i18nKey="Component.FinancedDocumentDetails.List.CreditNote" />
        ),
        render(value: number) {
            return <DataView value={value} format="number-int" hideLabel />;
        },
    },
    {
        key: 'totalAmount',
        dataIndex: 'totalAmount',
        title: (
            <Trans i18nKey="Component.FinancedDocumentDetails.List.TotalAmount" />
        ),
        width: 220,
        render(value: number) {
            return <DataView value={value} format="number-int" hideLabel />;
        },
    },
    {
        key: 'maturityDate',
        dataIndex: 'maturityDate',
        width: 150,
        title: (
            <Trans i18nKey="Component.FinancedDocumentDetails.List.MaturityDate" />
        ),
        render(value: string) {
            return <DataView value={value} format="date" hideLabel />;
        },
    },
];

const StyledListView = styled(ListView)`
    margin-top: 12px;
    .ant-table-thead > tr > th {
        font-weight: bold;
        text-align: center;
        word-break: normal;
    }
    .ant-table table {
        text-align: center;
    }
    .ant-table-tbody {
        border-bottom: 1px solid black;
    }

    .app-data-view {
        margin-bottom: 0;
        div {
            word-break: normal;
        }
    }
    .app-table-row-total {
        background-color: #80808030;
    }
`;
