/* eslint-disable max-lines */
import { Form, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import {
    StyledResetButton,
    StyledSubmitButton,
} from 'src/components/Buttons/Buttons';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    CustomerContactDetailsDto,
    CustomerUserDTO,
} from 'src/generated-api-client';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import { PHONE_MASK } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const EditContactDetailsComponent = observer(
    ({ ...props }: ModalRendererProps) => {
        const { t } = useTranslation();
        const [form] = Form.useForm();
        const fields = FormHelper.getFieldDataItems(
            ProfileStore.updateContactDetailsLoader.errorData,
        );
        const { contactDetails } = ProfileStore.user || ({} as CustomerUserDTO);

        const onFinish = async (values: CustomerContactDetailsDto) => {
            ProfileStore.updateContactDetailsLoader.reset();
            await ProfileStore.updateContactDetails(values);
            if (!ProfileStore.updateContactDetailsLoader.hasError) {
                await ProfileStore.loadProfileData();
                props.onCancel();
            }
        };

        const onCancel = () => {
            props.onCancel();
            ProfileStore.updateContactDetailsLoader.reset();
        };

        return (
            <StyledModal
                {...props}
                title={t('Page.Profile.EditContactDetailsDialog.Header')}
                onCancel={onCancel}
                width={800}
                footer={[
                    <StyledResetButton onClick={onCancel}>
                        {t('Button.Cancel')}
                    </StyledResetButton>,
                    <StyledSubmitButton
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        {t('Button.Save')}
                    </StyledSubmitButton>,
                ]}
            >
                <BasePage
                    isLoading={
                        ProfileStore.updateContactDetailsLoader.isLoading
                    }
                >
                    <Form
                        form={form}
                        fields={fields}
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={getInitialValues(contactDetails)}
                    >
                        <StyledTitle>
                            {t(
                                'Page.Profile.EditContactDetailsDialog.CompanyDetails',
                            )}
                        </StyledTitle>
                        <StyledWrapper>
                            <TextField
                                prefix={PHONE_MASK}
                                name="phoneNumber"
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.Phone',
                                )}
                                onlyNumbers
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxPhoneLength,
                                    ValidationRules.minPhoneLength,
                                ]}
                            />
                            <TextField
                                name="email"
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.Email',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.email,
                                ]}
                            />
                        </StyledWrapper>

                        <StyledTitle>
                            {t('Page.Profile.EditContactDetailsDialog.Address')}
                        </StyledTitle>
                        <StyledWrapper>
                            <SelectField
                                getData={() => ProfileStore?.getCountries()}
                                convertListItemToOption={(country: any) => ({
                                    children: country.name,
                                    value: country.name,
                                })}
                                name={['address', 'country', 'name']}
                                initialValue={
                                    ProfileStore.user?.contactDetails.address
                                        ?.country?.name
                                }
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.Country',
                                )}
                                data={ProfileStore.countriesList}
                                rules={[ValidationRules.required]}
                            />
                            <TextField
                                name={['address', 'city']}
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.City',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                            <SelectField
                                getData={() => ProfileStore?.getProvinces()}
                                convertListItemToOption={(province: any) => ({
                                    children: province.name,
                                    value: province.name,
                                })}
                                name={['address', 'province', 'name']}
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.Province',
                                )}
                                initialValue={
                                    ProfileStore.user?.contactDetails.address
                                        ?.province?.name
                                }
                                data={ProfileStore.provincesList}
                                rules={[ValidationRules.required]}
                            />
                            <TextField
                                name={['address', 'street']}
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.Street',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                            <TextField
                                name={['address', 'house']}
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.BuildingNumber',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                            <TextField
                                name={['address', 'buildingName']}
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.BuildingName',
                                )}
                                rules={[ValidationRules.maxLengthLegalName]}
                            />
                            <TextField
                                name={['address', 'postalCode']}
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.PostalCode',
                                    'Postal code',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                        </StyledWrapper>

                        <StyledTitle>
                            {t('Page.Profile.EditContactDetailsDialog.Company')}
                        </StyledTitle>
                        <StyledWrapper>
                            <TextField
                                name="representativeFullName"
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.RepresentativeFullName',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxLengthLegalName,
                                ]}
                            />
                            <TextField
                                name="representativePhoneNumber"
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.RepresentativePhoneNumber',
                                )}
                                prefix={PHONE_MASK}
                                onlyNumbers
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.maxPhoneLength,
                                    ValidationRules.minPhoneLength,
                                ]}
                            />
                            <TextField
                                name="representativeEmail"
                                label={t(
                                    'Page.Profile.EditContactDetailsDialog.RepresentativeEmail',
                                )}
                                rules={[
                                    ValidationRules.required,
                                    ValidationRules.email,
                                ]}
                            />
                        </StyledWrapper>
                    </Form>
                </BasePage>
            </StyledModal>
        );
    },
);

export const EditContactDetailsDialog = addModalRenderer(
    EditContactDetailsComponent,
);

const getInitialValues = (contactDetails: CustomerContactDetailsDto) => {
    return {
        ...contactDetails,
        phoneNumber: contactDetails.phoneNumber?.replace(PHONE_MASK, ''),
        representativePhoneNumber:
            contactDetails.representativePhoneNumber?.replace(PHONE_MASK, ''),
    };
};

const StyledWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
`;

const StyledModal = styled(Modal)`
    .ant-layout-content {
        margin: 0;
        min-width: 100%;
    }
    .ant-modal-body {
        padding: 0 24px;
    }
`;

const StyledTitle = styled.div`
    font-size: 1.6rem;
    font-weight: 600;
    padding-bottom: 12px;
`;
