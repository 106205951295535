import { Col, Row } from 'antd';
import { FileComponent } from 'src/components/FileComponent/FileComponent';
import { DocumentDTO } from 'src/generated-api-client';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

type FilesListComponentProps = {
    filesList: DocumentDTO[];
    store: BasicStore<any>;
};

export const FilesListComponent = ({
    filesList,
    store,
}: FilesListComponentProps) => (
    <StyledContainer gutter={[24, 24]}>
        {filesList?.map((file) => (
            <Col span={12}>
                <FileComponent
                    key={file.documentId}
                    file={file}
                    store={store}
                />
            </Col>
        ))}
    </StyledContainer>
);

const StyledContainer = styled(Row)`
    margin-bottom: 10px;
`;
