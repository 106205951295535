import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import styled from 'styled-components';

const PREFIX_LOAN_BALANCES = 'R';

const store = LoansStore;

export const LoanBalances = observer(() => {
    const { t } = useTranslation();
    const currentLoan = store.currentItem;

    return (
        <>
            <Row>
                <BlockTitle>{t('Page.LoanBalances.Header')}</BlockTitle>
            </Row>
            <Row gutter={32}>
                <Col span={32}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.principalDue}
                                format="number"
                                label={t('Page.LoanBalances.PrincipalDue')}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.accruedInterestDue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.AccruedInterestDue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.initiationFeeDue}
                                format="number"
                                label={t('Page.LoanBalances.InitiationFeeDue')}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.vatInitiationFeeDue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.VATInitiationFeeDue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.monthlyServiceFeeDue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.MonthlyServiceFeeDue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.vatMonthlyServiceFeeDue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.VATMonthlyServiceFeeDue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.insuranceFeeDue}
                                format="number"
                                label={t('Page.LoanBalances.InsuranceFee')}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.vatInsuranceFeeDue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.VATInsuranceFeeDue',
                                )}
                            />
                        </Col>
                    </Row>
                </Col>
                <StyledDivider span={32} />
                <Col span={32}>
                    <Row gutter={32}>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.principalOverdue}
                                format="number"
                                label={t('Page.LoanBalances.PrincipalOverdue')}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.accruedInterestOverdue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.AccruedInterestOverdue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.initiationFeeOverdue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.InitiationFeeOverdue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.vatInitiationFeeOverdue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.VatInitiationFeeOverdue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.monthlyServiceFeeOverdue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.MonthlyServiceFeeOverdue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.vatMonthlyServiceFeeOverdue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.VatMonthlyServiceFeeOverdue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.insuranceFeeOverdue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.InsuranceFeeOverdue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.vatInsuranceFeeOverdue}
                                format="number"
                                label={t(
                                    'Page.LoanBalances.VatInsuranceFeeOverdue',
                                )}
                            />
                        </Col>
                        <Col span={8}>
                            <DataView
                                prefix={PREFIX_LOAN_BALANCES}
                                value={currentLoan?.penaltyInterest}
                                format="number"
                                label={t('Page.LoanBalances.PenaltyInterest')}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
});

const StyledDivider = styled(Col)`
    width: 100%;
    margin-bottom: 30px;
    border-bottom: 1px solid #000000;
`;
