import { Card as AntCard, CardProps as AntCardProps } from 'antd';
import styled from 'styled-components';

type CardProps = AntCardProps & {
    // eslint-disable-next-line react/require-default-props
    columns?: string;
};
export const Card = ({ bordered = false, ...props }: CardProps) => {
    return <StyledCard {...props} bordered={bordered} />;
};

const StyledCard = styled(AntCard)<{ columns?: string }>`
    .ant-card-head,
    .ant-card-body {
        padding-left: 0;
    }
    .ant-card-head-title {
        font-size: 1.8rem;
        font-weight: 600;
        padding-bottom: 0;
    }
    .ant-card-head {
        border-bottom: 1px solid #000000;
    }
    .ant-card-body {
        display: grid;
        grid-template-columns: ${(props) =>
            `repeat(${props.columns || 3}, 1fr)`};
        column-gap: 24px;
        row-gap: 10px;
        &:before {
            display: none;
        }
    }
`;
