import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { BasePage } from 'src/components/BasePage/BasePage';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import { LoanApplicationStatus } from 'src/generated-api-client';
import { ApplicationGeneralInfoTab } from 'src/pages/LoanApplicationPage/components/ApplicationGeneralInfoTab';
import { ApplicationMasterElement } from 'src/pages/LoanApplicationPage/components/ApplicationMasterElement';
import { DocumentsTab } from 'src/pages/LoanApplicationPage/components/DocumentsTab';
import { LoansTab } from 'src/pages/LoanApplicationPage/components/LoansTab';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';

export const LoanApplicationPage = observer(() => {
    const { params } = useRouteMatch<{ id: string }>();

    useEffect(() => {
        ApplicationStore.loadItem(params.id);

        return () => {
            ApplicationStore.itemLoader.reset();
        };
    }, [params.id]);

    const applicationTabs: TabDefinition[] = [
        {
            tabKey: 'general-info',
            title: <Trans i18nKey="Page.Application.Tabs.GeneralInformation" />,
            Component: <ApplicationGeneralInfoTab />,
        },
        {
            tabKey: 'documents',
            title: <Trans i18nKey="Page.Application.Tabs.Documents" />,
            Component: <DocumentsTab />,
        },
        {
            tabKey: 'loans',
            title: <Trans i18nKey="Page.Application.Tabs.Loans" />,
            Component: <LoansTab />,
            visible:
                ApplicationStore.currentItem?.status ===
                LoanApplicationStatus.SIGNED,
        },
    ];

    return (
        <BasePage
            header={<ApplicationMasterElement />}
            isLoading={ApplicationStore.itemLoader.isLoading}
        >
            <Tabs tabs={applicationTabs} />
        </BasePage>
    );
});
