import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

const { Title } = Typography;

const store = ProfileStore;

export const InformationPage = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        store.loadProfileData();
    }, []);

    return (
        <BasePage isLoading={store.setProfileSecondStepDataAction.isLoading}>
            <SignUpPageContainer>
                <Title level={5}>
                    {store.user?.legalName &&
                        t('Page.InformationPage.CompanyName', {
                            name: store.user?.legalName,
                        })}
                </Title>
                <Title level={5}>
                    {t('Page.InformationPage.Date', {
                        date: new Date().toLocaleDateString(),
                    })}
                </Title>
                <p>{t('Page.InformationPage.Company.SubText')}</p>
                <Title level={5}>{t('Page.InformationPage.Consent')}</Title>
                <p>
                    {t('Page.InformationPage.Consent.Text', {
                        fullName:
                            store.user?.contactDetails.representativeFullName,
                        legalName: store.user?.legalName,
                        cipc: store.user?.cipc,
                    })}
                </p>
                <p>{t('Page.InformationPage.Consent.SubText')}</p>

                <Title level={5}>
                    {t('Page.InformationPage.Consent.RepresentativeFullName', {
                        name: store.user?.contactDetails.representativeFullName,
                    })}
                </Title>
            </SignUpPageContainer>
        </BasePage>
    );
});

const SignUpPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 150px;
`;
