import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { ListView } from 'src/components/ListView/ListView';
import { CustomerUserDTO } from 'src/generated-api-client';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

export const CompanyBankAccounts = observer(() => {
    return (
        <StyledListView
            store={{ list: ProfileStore.user?.bankAccounts || [] } as any}
            columns={getColumns()}
        />
    );
});

const getColumns = (): ColumnsType<CustomerUserDTO> => [
    {
        key: 'bankName',
        dataIndex: 'bankName',
        title: <Trans i18nKey="Component.CompanyBankAccounts.List.BankName" />,
    },
    {
        key: 'accountName',
        dataIndex: 'accountName',
        title: (
            <Trans i18nKey="Component.CompanyBankAccounts.List.AccountName" />
        ),
    },
    {
        key: 'accountType',
        dataIndex: 'accountType',
        title: (
            <Trans i18nKey="Component.CompanyBankAccounts.List.AccountType" />
        ),
    },
    {
        key: 'branchCode',
        dataIndex: 'branchCode',
        title: (
            <Trans i18nKey="Component.CompanyBankAccounts.List.BranchCode" />
        ),
    },
    {
        key: 'accountNumber',
        dataIndex: 'accountNumber',
        title: (
            <Trans i18nKey="Component.CompanyBankAccounts.List.AccountNumber" />
        ),
    },
];

const StyledListView = styled(ListView)`
    .ant-table-tbody > tr > td {
        max-width: 200px;
    }
`;
