/* eslint-disable max-lines */
import {
    Button,
    Col,
    Form as AntForm,
    notification,
    Row,
    Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DnDUploader } from 'src/components/DnDUploader/DnDUploader';
import { Form } from 'src/components/Form/Form';
import {
    FileMeasures,
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { ThirdStepCommonFiles } from 'src/pages/ApplyLoanPage/Components/ThirdStepCommonFiles';
import {
    ApplyForALoanStore,
    LoanStepId,
} from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';
import styled from 'styled-components';

const { Title } = Typography;

const store = ApplyForALoanStore;

export const ThirdStep = observer(
    ({ setDocumentLoading }: { setDocumentLoading: (_: boolean) => void }) => {
        const { t } = useTranslation();

        const [form] = AntForm.useForm();

        const applicationId =
            new URLSearchParams(Router.getQuerySearchParams()).get(
                'applicationId',
            ) || store.initialApplicationData?.id;

        const cancelApplication = () => {
            store.cancelApplicationItem(applicationId as number);
            store.resetFormApplication();
            form.resetFields();
        };

        const uploadApplicationDocuments = async (
            documents: [type: string, files: any][],
        ) => {
            setDocumentLoading(true);
            try {
                await store.uploadApplicationDocuments(
                    documents,
                    applicationId as string,
                );
                await store.confirmApplicationItem(applicationId as string);
                store.resetFormApplication();
            } catch (error) {
                notification.error({
                    message: t(
                        'Error.SomethingWentWrong',
                        'Something went wrong',
                    ),
                    // @ts-ignore
                    description: error?.detail || error.title,
                });
            } finally {
                setDocumentLoading(false);
            }
        };

        useEffect(() => {
            store.loadItem(applicationId);
        }, []);

        return (
            <Form
                form={form}
                onFinish={(values) => {
                    const documents = Object.entries(values);
                    uploadApplicationDocuments(documents);
                }}
            >
                <ThirdStepCommonFiles />

                <Row gutter={24} justify="start">
                    <Col>
                        <StyledPageTitle level={3}>
                            {t(
                                'Page.ApplyForALoan.ApplicationDocuments.SolvencyDocuments.Title',
                                'Solvency documents:',
                            )}
                        </StyledPageTitle>
                    </Col>
                </Row>
                <Row gutter={24} justify="space-between">
                    <Col span={11}>
                        <DnDUploader
                            maxCount={20}
                            name="LOAN_APPLICATION_BANK_STATEMENT"
                            label={t(
                                'Page.ApplyForALoan.ApplicationDocuments.BankLabel',
                                'The most recent bank statement (at least 3-6 month)',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.maxFileSize(
                                    25,
                                    FileSizeUnit.Mb,
                                    FileMeasures.mb,
                                ),
                                ValidationRules.validateFilesType,
                            ]}
                        />
                    </Col>
                </Row>
                <Row gutter={24} justify="center">
                    <StyledButtonContainer span={12}>
                        <StyledResetButton
                            key="cancel"
                            onClick={cancelApplication}
                        >
                            {t('Button.Cancel')}
                        </StyledResetButton>

                        <StyledBackButton
                            key="back"
                            type="primary"
                            onClick={() => {
                                Router.navigate(
                                    RoutePaths.editApplication(
                                        LoanStepId.SECOND,
                                        applicationId as string,
                                    ),
                                );
                            }}
                        >
                            {t('Components.Button.Back')}
                        </StyledBackButton>

                        <StyledSaveButton
                            key="ok"
                            type="primary"
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            {t('Button.Next')}
                        </StyledSaveButton>
                    </StyledButtonContainer>
                </Row>
            </Form>
        );
    },
);

const StyledButtonContainer = styled(Col)`
    margin-top: 20px;
    text-align: center;
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const StyledBackButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: transparent;
    color: #000000;
    border: 1.5px solid #000000;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: transparent;
        color: #aaaaaa;
        border: 1.5px solid #aaaaaa;
    }
`;

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: left;
    margin-top: 25px;
`;
