import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { LoanScheduleDTO } from 'src/generated-api-client';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

export const HeaderTable = observer(
    ({ dataSource }: { dataSource: LoanScheduleDTO[] }) => {
        return (
            <StyledTable
                pagination={false}
                columns={columns}
                dataSource={dataSource}
                locale={{ emptyText: ' ' }}
            />
        );
    },
);

const columns = [
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.PaymentDate" />,
        dataIndex: 'paymentDate',
        key: 'paymentDate',
        render: (date: any) => <>{moment(date).format('DD.MM.YYYY')}</>,
    },
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.TotalAmount" />,
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (amount: any) => (
            <>{NumberHelper.formatThousandSeparator(amount, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.Principal" />,
        dataIndex: 'principal',
        key: 'principal',
        render: (principal: any) => (
            <>{NumberHelper.formatThousandSeparator(principal, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.Interest" />,
        dataIndex: 'interest',
        key: 'interest',
        render: (interest: any) => (
            <>{NumberHelper.formatThousandSeparator(interest, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.MonthlyServiceFee" />,
        dataIndex: 'monthlyServiceFee',
        key: 'monthlyServiceFee',
        render: (monthlyServiceFee: any) => (
            <>{NumberHelper.formatThousandSeparator(monthlyServiceFee, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.VatMonthlyServiceFee" />,
        dataIndex: 'vatMonthlyServiceFee',
        key: 'vatMonthlyServiceFee',
        render: (vatMonthlyServiceFee: any) => (
            <>{NumberHelper.formatThousandSeparator(vatMonthlyServiceFee, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.InitiationFee" />,
        dataIndex: 'initiationFee',
        key: 'initiationFee',
        render: (initiationFee: any) => (
            <>{NumberHelper.formatThousandSeparator(initiationFee, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.VatInitiationFee" />,
        dataIndex: 'vatInitiationFee',
        key: 'vatInitiationFee',
        render: (vatInitiationFee: any) => (
            <>{NumberHelper.formatThousandSeparator(vatInitiationFee, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.InsuranceFee" />,
        dataIndex: 'insuranceFee',
        key: 'insuranceFee',
        render: (insuranceFee: any) => (
            <>{NumberHelper.formatThousandSeparator(insuranceFee, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.HeaderTable.VatInsuranceFee" />,
        dataIndex: 'vatInsuranceFee',
        key: 'vatInsuranceFee',
        render: (vatInsuranceFee: any) => (
            <>{NumberHelper.formatThousandSeparator(vatInsuranceFee, 2)}</>
        ),
    },
];

const StyledTable = styled(Table)`
    margin-top: 10px;
    .ant-table-thead {
        .ant-table-cell {
            background: transparent;
            font-weight: 600;
        }
        .ant-table-cell::before {
            display: none;
        }
    }
    .ant-table-tbody {
        .ant-table-cell {
            color: #000000;
            font-size: 15px;
        }
    }
    .ant-table-summary {
        background: #bfbfbf5c;
    }
`;
