/* eslint-disable max-lines */
import { Button, Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { Brief } from 'src/components/Brief/Brief';
import { Logo } from 'src/components/Logo';
import {
    LoanApplicationDisclosure,
    LoanApplicationType,
} from 'src/generated-api-client';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { OfferLetterTable } from 'src/pages/OfferLetterPage/OfferLetterTable';
import { AddOtherConditionsModal } from 'src/pages/OfferLetterPage/RequestOthersConditionsModal';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import styled from 'styled-components';

const store = ApplicationStore;

const { Paragraph, Title } = Typography;

export const OfferLetterPage = observer(() => {
    const { t } = useTranslation();
    const { applicationId } = useApplicationId();
    const offerLaterData = store.offerLetter;
    const currentApplication = store.currentItem;
    const unUndestantableText =
        offerLaterData?.applicationDisclosure ===
        LoanApplicationDisclosure.DISCLOSED
            ? 'being paid directly to our designated bank account'
            : 'verified and confirmed';

    const disclosureTextTypes = [
        LoanApplicationType.INVOICEFINANCE,
        LoanApplicationType.OUTANDOUTINVOICEFINANCE,
    ];

    useEffect(() => {
        store.getOfferLetter(applicationId);
        store.loadItem(applicationId);
    }, [applicationId]);

    return (
        <BasePage isLoading={store.getOfferLetterAction.isLoading}>
            <Brief>
                <Row>
                    <BlockTitle>{t('OfferLetter.Header.MainTitle')}</BlockTitle>
                </Row>
                <Row wrap={false}>
                    <Col flex="auto">
                        <Row gutter={24}>
                            <Col>
                                {t('OfferLetter.Header.CompanyName', {
                                    name: offerLaterData?.customerLegalName,
                                })}
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col>
                                {t('OfferLetter.Header.RegistrationNumber', {
                                    number: offerLaterData?.customerCipc,
                                })}
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                {t('OfferLetter.Header.PhysicalAddress', {
                                    address:
                                        offerLaterData?.customerFullAddress,
                                })}
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                {t('OfferLetter.Header.PostalAddress', {
                                    address:
                                        offerLaterData?.customerFullAddress,
                                })}
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Logo />
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col>
                        {t('OfferLetter.Header.TelephoneNumber', {
                            phone: offerLaterData?.customerPhoneNumber,
                        })}
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col>
                        {t('OfferLetter.Header.EmailAddress', {
                            mail: offerLaterData?.customerEmail,
                        })}
                    </Col>
                    <br />
                    <br />
                </Row>
                <Row gutter={24}>
                    <Col span={9}>
                        <Title level={5}>
                            {t('OfferLetter.Header.ApplicationNumber', {
                                number: offerLaterData?.applicationId,
                            })}
                        </Title>
                    </Col>
                </Row>
                <br />
                <Row gutter={24}>
                    <Col span={24}>
                        <Paragraph>
                            <Trans>
                                {t('OfferLetter.Body.FirstParagraph', {
                                    approvedAmount:
                                        offerLaterData?.approvedAmount,
                                    approvedAmountInwords:
                                        offerLaterData?.approvedAmountInWords,
                                    customerLegalName:
                                        offerLaterData?.customerLegalName,
                                })}
                            </Trans>
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Trans>
                            <Paragraph>
                                {t('OfferLetter.Body.SecondParagraph', {
                                    discloser:
                                        offerLaterData?.applicationType &&
                                        disclosureTextTypes.includes(
                                            offerLaterData?.applicationType as LoanApplicationType,
                                        )
                                            ? t(
                                                  `OfferLetter.Body.SecondParagraph.${offerLaterData?.applicationDisclosure}.${offerLaterData?.applicationType}`,
                                              )
                                            : t(
                                                  `OfferLetter.Body.SecondParagraph.${offerLaterData?.applicationType}`,
                                              ),
                                    unUndestantable: unUndestantableText,
                                })}
                            </Paragraph>
                        </Trans>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Paragraph>
                            {t('OfferLetter.Body.ThirdParagraph')}
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={4}>
                            {t('OfferLetter.Body.SecondTitle')}
                        </Title>
                    </Col>
                </Row>
                <OfferLetterTable />
                <Row gutter={24}>
                    <Col span={24}>
                        <Trans>
                            <Paragraph>
                                {t('OfferLetter.Body.ExplanationParagraph')}
                            </Paragraph>
                        </Trans>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Trans>
                            <Paragraph>
                                {t(
                                    'OfferLetter.Body.ExplanationSecondParagraph',
                                    {
                                        holidays: offerLaterData?.holidayTerm
                                            ? 'except for months with payment holidays provided'
                                            : '',
                                    },
                                )}
                            </Paragraph>
                        </Trans>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Trans>
                            <Paragraph>
                                {t(
                                    'OfferLetter.Body.ExplanationThirdParagraph',
                                )}
                            </Paragraph>
                        </Trans>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Trans>
                            <Paragraph>
                                {t(
                                    'OfferLetter.Body.ExplanationFourthParagraph',
                                )}
                            </Paragraph>
                        </Trans>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Trans>
                            <Paragraph>
                                {t(
                                    'OfferLetter.Body.ExplanationFifthParagraph',
                                )}
                            </Paragraph>
                        </Trans>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Trans>
                            <Paragraph>
                                {t(
                                    'OfferLetter.Body.ExplanationSixthParagraph',
                                    {
                                        term: t(
                                            `Loan.Application.Term.${offerLaterData?.approvedTerm}`,
                                        ),
                                        holidaysTerm: t(
                                            `OfferLetter.Body.Holidays.${offerLaterData?.approvedTerm}`,
                                        ),
                                    },
                                )}
                            </Paragraph>
                        </Trans>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Trans>
                            <Paragraph>
                                {t(
                                    'OfferLetter.Body.ExplanationSeventhParagraph',
                                )}
                            </Paragraph>
                        </Trans>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={5}>
                            {t('OfferLetter.Body.ThirdTitle')}
                        </Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Trans>
                            <Paragraph>
                                {t(
                                    'OfferLetter.Body.ExplanationEigthParagraph',
                                    {
                                        customerLegalName:
                                            offerLaterData?.customerLegalName,
                                    },
                                )}
                            </Paragraph>
                        </Trans>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Paragraph>
                            {t('OfferLetter.Body.End', {
                                date: moment().format('DD.MM.YYYY'),
                            })}
                        </Paragraph>
                    </Col>
                </Row>
                <StyledFlexCol>
                    <StyledButtonContainer>
                        <StyledResetButton
                            key="cancel"
                            loading={store.declineOfferLetterAction.isLoading}
                            onClick={() => {
                                store.declineOfferLetter(applicationId);
                            }}
                        >
                            Decline
                        </StyledResetButton>

                        <StyledSaveButton
                            key="ok"
                            type="primary"
                            loading={store.acceptOfferLetterAction.isLoading}
                            onClick={() => {
                                store.approveOfferLetter(applicationId);
                            }}
                        >
                            Accept
                        </StyledSaveButton>
                    </StyledButtonContainer>
                    {!currentApplication?.contrOffer && (
                        <StyledButtonContainer>
                            <StyledBackButton
                                key="back"
                                onClick={() => {
                                    AddOtherConditionsModal.render();
                                }}
                            >
                                Request other conditions
                            </StyledBackButton>
                        </StyledButtonContainer>
                    )}
                </StyledFlexCol>
            </Brief>
        </BasePage>
    );
});

const StyledButtonContainer = styled.div`
    display: flex;
    margin-top: 20px;
    text-align: center;
    white-space: nowrap;
    flex-wrap: nowrap;
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledBackButton = styled(Button)`
    width: 329px;
    height: 42px;
    border-radius: 10px;
    border: none;
    background: transparent;
    color: #000000;
    border: 1.5px solid #000000;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: transparent;
        color: #aaaaaa;
        border: 1.5px solid #aaaaaa;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const StyledFlexCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
