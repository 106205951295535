import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LoanScheduleDTO } from 'src/generated-api-client';
import { HeaderTable } from 'src/pages/LoanPage/Components/MasterElement/HeaderTable';
import { MasterElementBriefInfo } from 'src/pages/LoanPage/Components/MasterElement/MasterElementBriefInfo';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const store = LoansStore;

const { Text } = Typography;

export const LoanMasterElement = observer(() => {
    const { t } = useTranslation();
    const currentLoan = store?.currentItem;
    const schedules = currentLoan?.schedules?.sort(
        (a, b) =>
            moment(a.paymentDate).valueOf() - moment(b.paymentDate).valueOf(),
    );

    return (
        <ColoredHeader align="middle" justify="space-between" gutter={24}>
            <MasterElementBriefInfo />
            <Col span={24}>
                <StyledFlexRow>
                    {(currentLoan?.initialAmount ||
                        currentLoan?.initialAmount === 0) && (
                        <Col>
                            <Text strong>
                                {t('Page.Loan.MasterElement.InitialAmount', {
                                    amount: NumberHelper.formatThousandSeparator(
                                        currentLoan?.initialAmount,
                                    ),
                                })}
                            </Text>
                        </Col>
                    )}
                    {(currentLoan?.totalExposure ||
                        currentLoan?.totalExposure === 0) && (
                        <Col>
                            <Text strong>
                                {t('Page.Loan.MasterElement.ExposureTotal', {
                                    total: NumberHelper.formatThousandSeparator(
                                        currentLoan?.totalExposure,
                                    ),
                                })}
                            </Text>
                        </Col>
                    )}
                    {(currentLoan?.penaltyInterest ||
                        currentLoan?.penaltyInterest === 0) && (
                        <Col>
                            <Text strong>
                                {t('Page.Loan.MasterElement.PenaltyInterest', {
                                    penalty:
                                        NumberHelper.formatThousandSeparator(
                                            currentLoan?.penaltyInterest as number,
                                        ),
                                })}
                            </Text>
                        </Col>
                    )}
                </StyledFlexRow>
            </Col>
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <HeaderTable
                            dataSource={schedules as LoanScheduleDTO[]}
                        />
                    </Col>
                </Row>
            </Col>
        </ColoredHeader>
    );
});

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
    gap: 1rem;
    .ant-table {
        background: #f5f6f7 !important;
        font-size: 12px !important;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #000000;
        font-size: 11px !important;
    }
`;

const StyledFlexRow = styled(Row)`
    gap: 1rem;
`;
