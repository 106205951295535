import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { LoanItem } from 'src/components/LoanItem/LoanItem';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    LoanApplicationStatus,
    LoanViewDTOStatusEnum,
} from 'src/generated-api-client';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = LoansStore;

export const LoansPage = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        store.loadList();

        return () => {
            store.listLoader.reset();
        };
    }, []);

    return (
        <BasePage
            isLoading={store.listLoader.isLoading}
            title={t('Page.Loans.Title')}
        >
            {store.loansSortedByDateInDesc.map((application) => (
                <LoanItem
                    id={application?.id}
                    date={application?.startDate}
                    dateText={
                        <Trans
                            i18nKey="Page.Loans.DateText"
                            tOptions={{ id: application.id }}
                        />
                    }
                    status={t(`Loan.View.DTOStatus.${application?.status}`)}
                    linkAddress={UrlHelper.getTargetUrl(RoutePaths.loan, {
                        loanId: application?.id,
                    })}
                    statusColor={
                        applicationStatusMapColors[
                            application.status as LoanViewDTOStatusEnum
                        ]
                    }
                    isFinished={finishedLoanStatuses.includes(
                        application.status as any,
                    )}
                    amount={application.initialAmount}
                    period={application.approvedTerm}
                    type={application.type}
                    discloserType={application.disclosure}
                />
            ))}
        </BasePage>
    );
});

export const applicationStatusesForApprovedProperties = [
    LoanApplicationStatus.OFFERISSUED,
    LoanApplicationStatus.SIGNINGPROCESS,
    LoanApplicationStatus.SIGNED,
    LoanApplicationStatus.EXPIRED,
    LoanApplicationStatus.DECLINEDBYCUSTOMER,
];

export const applicationStatusMapColors = {
    [LoanViewDTOStatusEnum.ACTIVE]: '#D6F7A0',
    [LoanViewDTOStatusEnum.OVERDUE]: '#EB7382',
    [LoanViewDTOStatusEnum.DEFAULT]: '#EB7382',
    [LoanViewDTOStatusEnum.PAIDUP]: '#D6F7A0',
    [LoanViewDTOStatusEnum.WRITTENOFF]: '#EB7382',
};

const finishedLoanStatuses = [
    LoanViewDTOStatusEnum.PAIDUP,
    LoanViewDTOStatusEnum.WRITTENOFF,
];
