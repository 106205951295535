/* eslint-disable import/no-cycle */
import axios from 'axios';
import { Env } from 'src/core/Environment';
import { RoutePaths } from 'src/core/router/RoutePaths';
import {
    Configuration,
    CustomerDashboardControllerApiFactory,
    CustomerLoanApplicationControllerApiFactory,
    CustomerLoanControllerApiFactory,
    CustomerUserControllerApiFactory,
    CustomerUserDocumentControllerApiFactory,
    LoanApplicationDocumentControllerApiFactory,
    UiSettingsControllerApiFactory,
} from 'src/generated-api-client';

const apiConfig: Configuration | undefined = undefined;

export const uiSettingsApi = UiSettingsControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const usersApi = CustomerUserControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const customerDocumentsApi = CustomerUserDocumentControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const applicationsApi = CustomerLoanApplicationControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const loanApplicationsApi = CustomerLoanApplicationControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const loanApplicationDocument =
    LoanApplicationDocumentControllerApiFactory(apiConfig, Env.basePath);

export const loansApi = CustomerLoanControllerApiFactory(
    apiConfig,
    Env.basePath,
);

export const dashboardApi = CustomerDashboardControllerApiFactory(
    apiConfig,
    Env.basePath,
);

// @ts-ignore
applicationsApi.UploadDocuments = (
    applicationId: string,
    type: string,
    files: any,
    options?: any,
) => {
    const formData = new FormData();
    files.forEach((file: any) => {
        formData.append('files', file.originFileObj);
    });

    return axios.post(
        RoutePaths.uploadApplicationDocuments(applicationId, type),
        formData,
        options,
    );
};

// @ts-ignore
customerDocumentsApi.saveDocuments = (
    type: string,
    files: any,
    options?: any,
) => {
    const formData = new FormData();
    files.forEach((file: any) => {
        formData.append('files', file.originFileObj);
    });

    return axios.post(RoutePaths.uploadCustomerDocuments(type), formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...options,
    });
};
