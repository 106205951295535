import moment, { Moment } from 'moment';

export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_TIME_SERVER_FORMAT = 'HH:mm:ss';
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm';
export const DEFAULT_DATE_TIME_SECONDS_FORMAT = 'DD/MM/YYYY hh:mm:ss';

const convertToServerDate = (value: Moment | string) => {
    return format(value, DEFAULT_DATE_FORMAT);
};

const format = (value: Moment | string, format = DEFAULT_DATE_FORMAT) => {
    const mValue = moment.isMoment(value) ? value : moment(value);

    return mValue.format(format);
};

const formatDate = (value: Moment | string) => {
    return format(value);
};

const formatDateTime = (value: Moment | string) => {
    return format(value, DEFAULT_DATE_TIME_FORMAT);
};

const formatDateTimeSeconds = (value: Moment | string) => {
    return format(value, DEFAULT_DATE_TIME_SECONDS_FORMAT);
};

const formatTime = (value: Moment | string) => {
    return moment(value, DEFAULT_TIME_SERVER_FORMAT).format(
        DEFAULT_TIME_FORMAT,
    );
};

const formatTimeSeconds = (value: Moment | string) => {
    return moment(value, DEFAULT_TIME_SERVER_FORMAT).format(
        DEFAULT_TIME_SERVER_FORMAT,
    );
};

export const DateHelper = {
    convertToServerDate,
    format,
    formatDate,
    formatDateTime,
    formatDateTimeSeconds,
    formatTime,
    formatTimeSeconds,
};
