import { Collapse, Space, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

const { Panel } = Collapse;

const { Text } = Typography;

const store = ProfileStore;

export const QaPage = observer(() => {
    useEffect(() => {
        store.loadProfileData();
    }, []);

    return (
        <BasePage>
            <MainContainer>
                <Space direction="vertical">
                    <Collapse collapsible="header">
                        <Panel
                            header={
                                <Trans i18nKey="Page.QA.HowCanIApply.Question" />
                            }
                            key="1"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.HowCanIApply.Answer">
                                    <a
                                        target="_blank"
                                        href="https://mincap.co.za/"
                                        rel="noreferrer"
                                    >
                                        www.mincap.co.za
                                    </a>
                                    <a href="mailto:business-loans@mincap.co.za">
                                        business-loans@mincap.co.za
                                    </a>
                                </Trans>
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={
                                <Trans i18nKey="Page.QA.WhatType.Question" />
                            }
                            key="2"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.WhatType.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={
                                <Trans i18nKey="Page.QA.Documentations.Question" />
                            }
                            key="3"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.Documentations.Answer" />
                            </Text>
                            <Text>
                                <Trans i18nKey="Page.QA.Documentations.Answer2" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={<Trans i18nKey="Page.QA.Amount.Question" />}
                            key="4"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.Amount.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={<Trans i18nKey="Page.QA.Term.Question" />}
                            key="5"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.Term.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={
                                <Trans i18nKey="Page.QA.WhoCannotApply.Question" />
                            }
                            key="6"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.WhoCannotApply.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={
                                <Trans i18nKey="Page.QA.InterestRate.Question" />
                            }
                            key="7"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.InterestRate.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={
                                <Trans i18nKey="Page.QA.PaymentTerms.Question" />
                            }
                            key="8"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.PaymentTerms.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={
                                <Trans i18nKey="Page.QA.Repayments.Question" />
                            }
                            key="9"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.Repayments.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={
                                <Trans i18nKey="Page.QA.LoanEarlier.Question" />
                            }
                            key="10"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.LoanEarlier.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={<Trans i18nKey="Page.QA.Miss.Question" />}
                            key="11"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.Miss.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel
                            header={
                                <Trans i18nKey="Page.QA.Johannesburg.Question" />
                            }
                            key="12"
                        >
                            <Text>
                                <Trans i18nKey="Page.QA.Johannesburg.Answer" />
                            </Text>
                        </Panel>
                    </Collapse>
                </Space>
            </MainContainer>
        </BasePage>
    );
});

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 150px;
`;
