import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const NotFound = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledError>
            <h1>
                {t('Page.NotFound.Title', {
                    defaultValue: 'Page not found',
                })}
            </h1>
        </StyledError>
    );
});

const StyledError = styled.div`
    z-index: 115;
    height: 100%;
    min-height: 440px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
