/* eslint-disable max-lines */
import { Col, Form, Radio, Row, Slider, Space, Typography } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    LoanApplicationDisclosure,
    LoanApplicationTerm,
} from 'src/generated-api-client';
import { DashboardStore } from 'src/stores/DashboardStore/DashboardStore';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const { Text } = Typography;

const RUAND_PREFIX = 'R';
const sliderMarksList: SliderMarks = {
    0: '30',
    50: '60',
    100: '90',
};

export const RightSide = observer(() => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const amount = Form?.useWatch('amount', form);
    const term = Form?.useWatch('term', form);
    const disclosure = Form?.useWatch('disclosure', form);

    useEffect(() => {
        DashboardStore.load();

        return () => {
            DashboardStore.reset();
        };
    }, []);

    useEffect(() => {
        if (amount && disclosure && (term || term === 0)) {
            form.submit();
        }
    }, [amount, term, disclosure]);

    const {
        principalDebt = 0,
        totalMonthlyFee = 0,
        totalVatAmount = 0,
        totalPayment = 0,
        monthlyPayment = 0,
        insuranceFee = 0,
        interest = 0,
        initiationFee = 0,
    } = DashboardStore.calculatedParams || {};

    return (
        <Row gutter={[24, 24]} justify="space-between" align="middle">
            <Col span={24}>
                <BlockTitle>
                    <Trans i18nKey="Page.Dashboard.LoanCalculator.Title" />
                </BlockTitle>
            </Col>
            <StyledTextFieldContainer span={24}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={DashboardStore.initialValues}
                >
                    <TextField
                        name="amount"
                        requiredMark="optional"
                        label={
                            <Trans i18nKey="Page.Dashboard.DesiredLoanAmount.Label" />
                        }
                        rules={[
                            ValidationRules.required,
                            ValidationRules.validCalculateAmount,
                        ]}
                    />

                    <Form.Item
                        name="term"
                        label={<Trans i18nKey="Page.Dashboard.Term.Label" />}
                    >
                        <StyledSlider
                            step={50}
                            marks={sliderMarksList}
                            tooltipVisible={false}
                        />
                    </Form.Item>

                    <Form.Item
                        name="disclosure"
                        label={
                            <Trans i18nKey="Page.Dashboard.Disclosed.Label" />
                        }
                        tooltip={
                            <Trans i18nKey="Page.Dashboard.Disclosure.Tooltip" />
                        }
                    >
                        <StyledRadioGroup>
                            <Space direction="horizontal">
                                <StyledRadioSelect
                                    value={
                                        LoanApplicationDisclosure.UNDISCLOSED
                                    }
                                >
                                    {t('Component.Disclosure.No')}
                                </StyledRadioSelect>
                                <StyledRadioSelect
                                    value={LoanApplicationDisclosure.DISCLOSED}
                                >
                                    {t('Component.Disclosure.Yes')}
                                </StyledRadioSelect>
                            </Space>
                        </StyledRadioGroup>
                    </Form.Item>
                </Form>
            </StyledTextFieldContainer>

            <Col span={10}>
                <Trans i18nKey="Page.Dashboard.TotalPayment.Label" />
            </Col>
            <Col span={14}>
                <Text strong>
                    {`${RUAND_PREFIX} ${NumberHelper.formatThousandSeparator(
                        totalPayment,
                    )}`}
                </Text>
            </Col>
            <Col span={24}>
                <Trans i18nKey="Page.Dashboard.Including.Label" />
            </Col>
            <Col span={10}>
                <Trans i18nKey="Page.Dashboard.PrincipalDebt.Label" />
            </Col>
            <Col span={14}>
                {`${RUAND_PREFIX} ${NumberHelper.formatThousandSeparator(
                    principalDebt,
                )}`}
            </Col>
            <Col span={10}>
                <Trans i18nKey="Page.Dashboard.Interest.Label" />
            </Col>
            <Col span={14}>
                {`${RUAND_PREFIX} ${NumberHelper.formatThousandSeparator(
                    interest,
                )}`}
            </Col>
            <Col span={10}>
                <Trans i18nKey="Page.Dashboard.InitiationFee.Label" />
            </Col>
            <Col span={14}>
                {`${RUAND_PREFIX} ${NumberHelper.formatThousandSeparator(
                    initiationFee,
                )}`}
            </Col>
            <Col span={10}>
                <Trans i18nKey="Page.Dashboard.InsuranceFee.Label" />
            </Col>
            <Col span={14}>
                {`${RUAND_PREFIX} ${NumberHelper.formatThousandSeparator(
                    insuranceFee,
                )}`}
            </Col>
            <Col span={10}>
                <Trans i18nKey="Page.Dashboard.MonthlyServiceFee.Label" />
            </Col>
            <Col span={14}>
                {`${RUAND_PREFIX} ${NumberHelper.formatThousandSeparator(
                    totalMonthlyFee,
                )}`}
            </Col>
            <Col span={10}>
                <Trans i18nKey="Page.Dashboard.VAT.Label" />
            </Col>
            <Col span={14}>
                {`${RUAND_PREFIX} ${NumberHelper.formatThousandSeparator(
                    totalVatAmount,
                )}`}
            </Col>
            <Col span={10}>
                <Trans i18nKey="Page.Dashboard.MonthlyPayment.Label" />
            </Col>
            <Col span={14}>
                <Text strong>
                    {`${RUAND_PREFIX} ${NumberHelper.formatThousandSeparator(
                        monthlyPayment,
                    )}`}
                </Text>
            </Col>
        </Row>
    );
});

const onFinish = async (values: any) => {
    if (
        values?.amount &&
        (values?.term || values?.term === 0) &&
        values?.disclosure
    ) {
        await DashboardStore.calculate({
            ...values,
            term: getTermForServer(values?.term),
        });
    }
};

const getTermForServer = (term?: number) => {
    switch (term) {
        case 0: {
            return LoanApplicationTerm.THIRTY;
        }
        case 50: {
            return LoanApplicationTerm.SIXTY;
        }
        case 100: {
            return LoanApplicationTerm.NINETY;
        }
        default: {
            return LoanApplicationTerm.THIRTY;
        }
    }
};

const StyledTextFieldContainer = styled(Col)`
    .ant-row {
        justify-content: space-between;
    }
    .ant-form-horizontal .ant-form-item-control {
        max-width: 314px;
    }
    .ant-form-item-label > label::after {
        content: '';
    }
`;

const StyledRadioGroup = styled(Radio.Group)`
    width: 100%;
    .ant-space {
        width: 100%;
    }
    .ant-space-item {
        width: 50%;
    }
`;

const StyledRadioSelect = styled(Radio.Button)`
    width: 100%;
    text-align: center;
    border-color: #000000;
    border-left-color: #000000 !important;
    &.ant-radio-button-wrapper-checked {
        border-color: #d8021c !important;
        border-right-color: #d8021c !important;
        color: #ffffff;
        background: #d8021c;
    }
    &.ant-radio-button-wrapper-checked:hover,
    &:hover {
        color: #000000;
    }
    &.ant-radio-button-wrapper-disabled {
        color: #d9d9d9;
    }
    &.ant-radio-button-wrapper-disabled:hover {
        color: #d9d9d9;
    }
    .ant-radio-button-wrapper {
        border: none;
    }
    &.ant-radio-button-wrapper-disabled:first-child {
        border-left-color: #d9d9d9 !important;
    }
`;

const StyledSlider = styled(Slider)`
    color: #000000;
    .ant-slider {
        background-color: #000000;
    }
    .ant-slider-track {
        background-color: #d7031c !important;
    }
    .ant-slider:hover .ant-slider-track {
        background-color: #000000 !important;
    }
    .ant-slider:hover {
        .ant-slider-handle {
            border-color: #000000 !important;
        }
    }
    .ant-slider-step {
        .ant-slider-dot-active {
            border-color: #374043;
            background-color: #374043;
        }
    }
    .ant-slider-handle {
        border-color: #000000;
    }
    .ant-slider-handle:focus {
        box-shadow: none;
    }
`;
