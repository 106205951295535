import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LoanApplicationViewDTO } from 'src/generated-api-client';
import { ApplicationActions } from 'src/pages/LoanApplicationPage/components/ApplicationActions';
import { ApplicationStepsView } from 'src/pages/LoanApplicationPage/components/ApplicationStepsView';
import { applicationStatusMapColors } from 'src/pages/LoanApplicationsPage/LoanApplications';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import styled from 'styled-components';

export const ApplicationMasterElement = observer(() => {
    const { t } = useTranslation();
    const { submitDate, type, status } =
        ApplicationStore.currentItem || ({} as LoanApplicationViewDTO);

    return (
        <StyledWrapper>
            <StyledBriefInfo>
                <StyledItem>
                    {t(`Loan.Application.Type.${type}`)}
                    <span
                        style={{
                            minHeight: '24px',
                        }}
                    >
                        {submitDate ? (
                            moment(submitDate).format('DD.MM.YYYY')
                        ) : (
                            <StyledEmptyDate />
                        )}
                    </span>
                </StyledItem>
                <StyledItem>
                    <StyledStatus
                        color={
                            applicationStatusMapColors[
                                status as keyof typeof applicationStatusMapColors
                            ]
                        }
                    >
                        {t(`Loan.Application.Status.${status}`)}
                    </StyledStatus>
                </StyledItem>
                <StyledItem>
                    <ApplicationActions />
                </StyledItem>
            </StyledBriefInfo>

            <ApplicationStepsView />
        </StyledWrapper>
    );
});

const StyledItem = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: black;
    font-weight: bold;
    font-size: 16px;
`;

const StyledBriefInfo = styled.div`
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    text-align: center;
`;

const StyledWrapper = styled.div`
    padding: 12px 40px;
    margin-bottom: 30px;
    border-radius: 8px;
    background-color: #f5f6f7;
    margin-top: 10px;
    box-shadow: 10px 11px 21px -18px rgba(0, 0, 0, 0.82);
`;

const StyledStatus = styled.div<{ color?: string }>`
    box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) => props.color};
    border-radius: 15px;
    min-height: 35px;
    font-weight: 500;
    width: fit-content;
    font-size: 16px;
    height: fit-content;
    line-height: 24px;
    color: #000000;
`;

const StyledEmptyDate = styled.div`
    height: 35px;
`;
