import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { Header } from 'src/components/Header';
import { RegistrationsPagesHeader } from 'src/components/RegistrationsPagesHeader';
import { SplashScreen } from 'src/components/SplashScreen/SplashScreen';
import { RoutesLayout } from 'src/containers/RoutesLayout/RoutesLayout';
import { Env } from 'src/core/Environment';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { AppStore } from 'src/stores/AppStore/AppStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { CssVariables } from 'src/styles/CssVariables';
import styled from 'styled-components';

export const AppLayout = observer(() => {
    if (!AuthStore.authenticated || !AppStore.started) {
        return <SplashScreen />;
    }

    const registrationPagesRoutes = [
        RoutePaths.signupSecondStep,
        RoutePaths.signupThirdStep,
        RoutePaths.informationPage,
    ];

    const { pathname } = useLocation();

    const currentPageRoute = `${Env.basePath}${pathname}`;

    const isRegistrationPage =
        registrationPagesRoutes.includes(currentPageRoute);

    return (
        <Layout>
            {!isRegistrationPage ? <Header /> : <RegistrationsPagesHeader />}
            <StyledContent isregistrationpage={isRegistrationPage.toString()}>
                <StyledLayoutContent>
                    <RoutesLayout />
                </StyledLayoutContent>
            </StyledContent>
        </Layout>
    );
});

const StyledContent = styled(Layout.Content)<{ isregistrationpage?: string }>`
    background-color: white;
    margin-top: ${(props) =>
        props.isregistrationpage === 'true' ? 0 : CssVariables.headerHeight};
    position: relative;
    display: flex;
`;

const StyledLayoutContent = styled(Layout.Content)`
    padding: 0;
    position: relative;
    overflow-y: auto;
`;
