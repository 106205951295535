import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DataView } from 'src/components/DataView/DataView';
import { FileComponent } from 'src/components/FileComponent/FileComponent';
import { DocumentDTO } from 'src/generated-api-client';
import { CustomerDocumentsStore } from 'src/stores/CustomerDocumentsStore/CustomerDocumentsStore';
import styled from 'styled-components';

export const CompanyDocuments = observer(() => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <Title>{t('Document.DTODocument.Type.CUSTOMER_CIPC')}</Title>
                <Row gutter={[24, 24]}>
                    {CustomerDocumentsStore.customerCIPCDocs?.map(
                        renderFileComponent,
                    )}
                </Row>
            </div>
            <div>
                <Title>
                    {t('Document.DTODocument.Type.CUSTOMER_TAX_CLEARANCE')}
                </Title>
                <Row gutter={[24, 24]}>
                    {CustomerDocumentsStore.customerTaxClearanceDocs?.map(
                        renderFileComponent,
                    )}
                </Row>
            </div>
            <div>
                <Title>
                    {t('Document.DTODocument.Type.CUSTOMER_PROOF_OF_ADDRESS')}
                </Title>
                <Row gutter={[24, 24]}>
                    {CustomerDocumentsStore.customerProofOfAddress?.map(
                        renderFileComponent,
                    )}
                </Row>
            </div>
            <div>
                <Title>
                    {t('Document.DTODocument.Type.CUSTOMER_DIRECTOR_IDS')}
                </Title>
                <Row gutter={[24, 24]}>
                    {CustomerDocumentsStore.customerDirectorIdsDocs?.map(
                        renderFileComponent,
                    )}
                </Row>
            </div>
            <div>
                <Title>
                    {t(
                        'Document.DTODocument.Type.CUSTOMER_AUTHORIZATION_LETTER',
                    )}
                </Title>
                <Row gutter={[24, 24]}>
                    {CustomerDocumentsStore.customerAuthorizationLetter?.map(
                        renderFileComponent,
                    )}
                </Row>
            </div>
        </>
    );
});

const renderFileComponent = (file: DocumentDTO) => {
    return (
        <Col span={12}>
            <DataView
                hideLabel
                value={
                    <FileComponent
                        store={CustomerDocumentsStore}
                        key={file.documentId}
                        file={file}
                    />
                }
            />
        </Col>
    );
};

const Title = styled.div`
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: black;
`;
