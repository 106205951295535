import { DatePicker, Form, FormItemProps } from 'antd';
import React from 'react';
import { DEFAULT_DATE_FORMAT } from 'src/utils/DateHelper';
import styled from 'styled-components';

export type DatePickerFieldProps = FormItemProps & {
    label: React.ReactNode;
    // eslint-disable-next-line react/require-default-props
    format?: string;
};

export const DatePickerField = ({
    name,
    label,
    format = DEFAULT_DATE_FORMAT,
    ...props
}: DatePickerFieldProps) => {
    return (
        <Form.Item {...props} name={name} label={label}>
            <DatePickerStyled allowClear={false} format={format} />
        </Form.Item>
    );
};

export const DatePickerStyled = styled(DatePicker)`
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border-color: #000000;
    .ant-picker-input {
        display: flex;
    }
    .ant-picker-suffix {
        color: #000000;
    }
`;
