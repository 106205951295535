/* eslint-disable max-lines */
import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Brief } from 'src/components/Brief/Brief';
import { useLoanStepId } from 'src/hooks/useLoanStepId';
import { FirstStep } from 'src/pages/ApplyLoanPage/FirstStep';
import { SecondStep } from 'src/pages/ApplyLoanPage/SecondStep';
import { ThirdStep } from 'src/pages/ApplyLoanPage/ThirdStep';
import {
    ApplyForALoanStore,
    LoanStepId,
} from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';
import { CustomerDocumentsStore } from 'src/stores/CustomerDocumentsStore/CustomerDocumentsStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import styled from 'styled-components';

const { Title } = Typography;

const store = ApplyForALoanStore;

const documentStore = CustomerDocumentsStore;

export const ApplyLoanPage = observer(() => {
    const { stepId } = useLoanStepId();
    const [isDocumentLoading, setIsDocumentLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        documentStore.getDocumentsForUpdates();
    }, []);

    const loadingStatus = useMemo(() => {
        return new CombinedLoadingStatus([
            store.createApplicationItemAction,
            documentStore.getDocumentsForUpdatesAction,
            store.updateItemLoader,
            store.createItemLoader,
            store.cancelApplicationItemAction,
        ]);
    }, [store]);

    return (
        <BasePage isLoading={isDocumentLoading || loadingStatus.isLoading}>
            <Brief>
                <Row>
                    <Col flex="auto">
                        <StyledPageTitle level={2}>
                            {t('Page.ApplyForALoan.Title')}
                        </StyledPageTitle>
                    </Col>
                </Row>
                <Row>
                    <Col flex="auto">
                        <StyledPageTitle level={3}>
                            {t('Page.ApplyForALoan.SubTitle', {
                                currentStep: stepId,
                            })}
                            <br />
                            {stepId === LoanStepId.FIRST &&
                                t(
                                    'Page.ApplyForALoan.SubTitle1',
                                    'Please, fill in desired loan conditions',
                                )}
                            {stepId === LoanStepId.SECOND &&
                                t(
                                    'Page.ApplyForALoan.SubTitle2',
                                    'Please, fill in financial details',
                                )}
                            {stepId === LoanStepId.THIRD &&
                                t(
                                    'Page.ApplyForALoan.SubTitle3',
                                    'Please, provide the following documents:',
                                )}
                        </StyledPageTitle>
                    </Col>
                </Row>
                {stepId === LoanStepId.FIRST && <FirstStep />}
                {stepId === LoanStepId.SECOND && <SecondStep />}
                {stepId === LoanStepId.THIRD && (
                    <ThirdStep setDocumentLoading={setIsDocumentLoading} />
                )}
            </Brief>
        </BasePage>
    );
});

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;
