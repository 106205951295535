import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const store = LoansStore;

export const LoanDetails = observer(() => {
    const { t } = useTranslation();

    const currentLoan = store.currentItem;

    return (
        <>
            <Row>
                <BlockTitle>{t('Page.LoanDetails.header')}</BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.startDate}
                        format="date"
                        label={t('Page.LoanDetails.StartDate')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.maturityDate}
                        format="date"
                        label={t('Page.LoanDetails.MaturityDate')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.interestDaily}
                        prefix="R"
                        format="number-int"
                        label={t('Page.LoanDetails.InterestDaily')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="link-internal"
                        formatterProps={{
                            to: UrlHelper.getTargetUrl(
                                RoutePaths.loanApplication,
                                {
                                    id: currentLoan?.loanApplicationId,
                                },
                            ),
                        }}
                        value={currentLoan?.loanApplicationId}
                        label={t('Page.LoanDetails.LoanApplicationId')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.feeRepaymentMethod}
                        format="enum"
                        formatterProps={{ i18nKey: 'Fee.Repayment.Method.' }}
                        label={t('Page.LoanDetails.FeeRepayment')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.daysOverdue}
                        label={t('Page.LoanDetails.DaysOverdue')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={
                            currentLoan?.calculationData?.interestRatePercentage
                        }
                        label={t('Page.LoanDetails.30daysInterestRate')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        format="percent"
                        value={currentLoan?.dailyPenaltyRatePercentage}
                        label={t('Page.LoanDetails.DailyPenaltyRate')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.finishedAt}
                        label={t('Page.LoanDetails.ClosingDate')}
                    />
                </Col>
            </Row>
        </>
    );
});
