import { action, computed } from 'mobx';
import { AttachmentsFormat } from 'src/components/Form/ValidationRules';
import {
    DocumentDTO,
    DocumentDTODocumentTypeEnum,
    DocumentDTOFileTypeEnum,
} from 'src/generated-api-client';
import { loanApplicationDocument } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ApplicationDocumentsStoreClass extends BasicStore<DocumentDTO> {
    api: BasicStoreApi<DocumentDTO> = {
        loadList: async () => {
            if (!this.applicationId) {
                return [];
            }
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loanApplicationDocument.getDocumentForApplication(
                    this.applicationId,
                ),
            );

            return result.data || [];
        },
    };

    @action async downloadDocuments(
        id: number,
        type: DocumentDTOFileTypeEnum,
        fileName: string,
    ) {
        const res = await this.downloadDocumentsAction.call(id);
        const file = window.URL.createObjectURL(
            new Blob([res], {
                type: AttachmentsFormat[
                    type.toLocaleLowerCase() as keyof typeof AttachmentsFormat
                ],
            }),
        );
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    @computed get applicationTaxInvoice() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONTAXINVOICE,
        );
    }

    @computed get applicationInvoiceContract() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONINVOICECONTRACT,
        );
    }

    @computed get applicationBankStatement() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONBANKSTATEMENT,
        );
    }

    @computed get applicationProdLetter() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONTHIRDPARTYPROOFLETTER,
        );
    }

    @computed get applicationQuotations() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONQUOTATION,
        );
    }

    @computed get applicationPurcheseOrder() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONPURCHASEORDER,
        );
    }

    @computed get applicationPurcheseOrderContract() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONPURCHASEORDERCONTRACT,
        );
    }

    @computed get applicationProfitAndLossBudget() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONPROFITANDLOSSBUDGET,
        );
    }

    @computed get applicationFinancialStatement() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONFINANCIALSTATEMENT,
        );
    }

    @computed get customerSignedAgreement() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONACCOUNTSAGING,
        );
    }

    @computed get applicationSignedAgreement() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONMAINCONTRACT,
        );
    }

    downloadDocumentsAction = new AsyncOperationWithStatus((id: number) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationDocument.downloadDocument(id, {
                responseType: 'arraybuffer',
            }),
        ),
    );

    @computed get applicationDebtorsContract() {
        return this.list?.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.LOANAPPLICATIONDEBTORSCONTRACT,
        );
    }

    constructor(private applicationId?: number) {
        super();
    }
}

export const ApplicationDocumentsStore = new EntityProvider(
    ApplicationDocumentsStoreClass,
);
