import axios from 'axios';
import { action, computed } from 'mobx';
import { AttachmentsFormat } from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    DocumentDTO,
    DocumentDTODocumentTypeEnum,
    DocumentDTOFileTypeEnum,
} from 'src/generated-api-client';
import { customerDocumentsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

import { UploadFilesStatusStore } from '../UploadFilesStatusStore/UploadFilesStatusStore';

export type CustomerDocumentsTypes =
    | 'CUSTOMER_CIPC'
    | 'CUSTOMER_TAX_CLEARANCE'
    | 'CUSTOMER_PROOF_OF_ADDRESS'
    | 'CUSTOMER_DIRECTOR_IDS'
    | 'CUSTOMER_CREDIT_BUREAU'
    | 'CUSTOMER_SOLVENCY'
    | 'CUSTOMER_AUTHORIZATION_LETTER'
    | 'LOAN_APPLICATION_MAIN_CONTRACT'
    | 'LOAN_APPLICATION_DEBTORS_CONTRACT'
    | 'LOAN_APPLICATION_FINANCIAL_STATEMENT'
    | 'LOAN_APPLICATION_PURCHASE_ORDER'
    | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT'
    | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET'
    | 'LOAN_APPLICATION_ACCOUNTS_AGING'
    | 'LOAN_APPLICATION_TAX_INVOICE'
    | 'LOAN_APPLICATION_INVOICE_CONTRACT'
    | 'LOAN_APPLICATION_BANK_STATEMENT'
    | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER';

export class CustomerDocumentsStoreClass extends BasicStore<DocumentDTO> {
    api: BasicStoreApi<DocumentDTO> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                customerDocumentsApi.getForProfile(),
            );

            return result.data || [];
        },
    };

    uploadCustomerDocumentsAction = new AsyncOperationWithStatus(
        (type: CustomerDocumentsTypes, files: any) => {
            return RequestHelper.unwrapFromAxiosPromise(
                customerDocumentsApi.saveDocuments(type, files, {
                    onUploadProgress: (progressEvent: any) => {
                        UploadFilesStatusStore.onUploadProgressAction(
                            progressEvent,
                            type,
                        );
                    },
                }),
            );
        },
    );

    getDocumentsForUpdatesAction = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(
            customerDocumentsApi.getCustomerDocuments(),
        ),
    );

    downloadDocumentsAction = new AsyncOperationWithStatus((id: number) =>
        RequestHelper.unwrapFromAxiosPromise(
            customerDocumentsApi.downloadCustomerDocuments(id, {
                responseType: 'arraybuffer',
            }),
        ),
    );

    confirmDocumentsAction = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(
            customerDocumentsApi.isAllRequireDocumentsUploaded(),
        ),
    );

    @action async uploadCustomerDocuments(
        documents: [type: CustomerDocumentsTypes, files: any][],
    ) {
        await axios.all(
            documents.map(([type, files]) => {
                return this.uploadCustomerDocumentsAction.call(
                    type as CustomerDocumentsTypes,
                    files,
                );
            }),
        );
        UploadFilesStatusStore.resetProgress();
    }

    @action async getDocumentsForUpdates() {
        const result = await this.getDocumentsForUpdatesAction.call();
        if (result?.data?.length) {
            Router.navigate(RoutePaths.updateDocuments);
        }
    }

    @action async downloadDocuments(
        id: number,
        type: DocumentDTOFileTypeEnum,
        fileName: string,
    ) {
        const res = await this.downloadDocumentsAction.call(id);
        const file = window.URL.createObjectURL(
            new Blob([res], {
                type: AttachmentsFormat[
                    type.toLocaleLowerCase() as keyof typeof AttachmentsFormat
                ],
            }),
        );
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    @action async confirmDocuments() {
        await this.confirmDocumentsAction.call();
        if (!this.confirmDocumentsAction.hasError) {
            Router.goBack();
        }
    }

    @computed get customerCIPCDocs() {
        return this.list.filter(
            (doc) =>
                doc.documentType === DocumentDTODocumentTypeEnum.CUSTOMERCIPC,
        );
    }

    @computed get customerDirectorIdsDocs() {
        return this.list.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.CUSTOMERDIRECTORIDS,
        );
    }

    @computed get customerProofOfAddress() {
        return this.list.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.CUSTOMERPROOFOFADDRESS,
        );
    }

    @computed get customerAuthorizationLetter() {
        return this.list.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.CUSTOMERAUTHORIZATIONLETTER,
        );
    }

    @computed get customerTaxClearanceDocs() {
        return this.list.filter(
            (doc) =>
                doc.documentType ===
                DocumentDTODocumentTypeEnum.CUSTOMERTAXCLEARANCE,
        );
    }
}

export const CustomerDocumentsStore = new CustomerDocumentsStoreClass();
