import { computed, observable } from 'mobx';
import moment from 'moment';
import {
    LoanApplicationListDTO,
    LoanApplicationStatus,
} from 'src/generated-api-client';
import { loanApplicationsApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ApplicationsStoreClass extends BasicStore<LoanApplicationListDTO> {
    @observable private hasWaitingDocuments = false;

    api: BasicStoreApi<LoanApplicationListDTO> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.entityList2(),
            );

            this.hasWaitingDocuments = Boolean(
                result?.data?.filter(
                    (item) =>
                        item.status ===
                        LoanApplicationStatus.INITIALWAITINGDOCUMENTS,
                ).length,
            );

            return result.data || [];
        },
    };

    @computed get applicationsSortedByDateInDesc() {
        return this.list?.sort((a, b) => {
            return (
                moment(b.submitDate).valueOf() - moment(a.submitDate).valueOf()
            );
        });
    }

    @computed get hasWaitingDocumentsApplicationsList() {
        return this.hasWaitingDocuments;
    }
}

export const ApplicationsStore = new ApplicationsStoreClass();
