import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MasterElementBriefInfo } from 'src/pages/LoanPage/Components/MasterElement/MasterElementBriefInfo';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const store = LoansStore;
const { Text } = Typography;

export const OutAndOutMasterElement = observer(() => {
    const { t } = useTranslation();
    const currentLoan = store?.currentItem;

    return (
        <ColoredHeader align="middle" justify="space-between" gutter={24}>
            <MasterElementBriefInfo />
            <Col span={24}>
                <StyledFlexRow>
                    {currentLoan?.financedItemTotalAmount && (
                        <Col>
                            <Text strong>
                                {t('Page.Loan.MasterElement.PaymentAmount', {
                                    amount: NumberHelper.formatThousandSeparator(
                                        currentLoan?.financedItemTotalAmount,
                                    ),
                                })}
                            </Text>
                        </Col>
                    )}
                    <Col>
                        {currentLoan?.latestPaymentDate && (
                            <Text strong>
                                {t('Page.Loan.MasterElement.PaymentDate', {
                                    date: moment(
                                        currentLoan?.latestPaymentDate,
                                    ).format('DD.MM.YYYY'),
                                })}
                            </Text>
                        )}
                    </Col>
                </StyledFlexRow>
            </Col>
            <Col span={24}>
                <StyledFlexRow>
                    {(currentLoan?.initialAmount ||
                        currentLoan?.initialAmount === 0) && (
                        <Col>
                            <Text strong>
                                {t('Page.Loan.MasterElement.FinancinsAmount', {
                                    amount: NumberHelper.formatThousandSeparator(
                                        currentLoan?.initialAmount,
                                    ),
                                })}
                            </Text>
                        </Col>
                    )}
                    {(currentLoan?.totalExposure ||
                        currentLoan?.totalExposure === 0) && (
                        <Col>
                            <Text strong>
                                {t('Page.Loan.MasterElement.TotalExposure', {
                                    total: NumberHelper.formatThousandSeparator(
                                        currentLoan?.totalExposure,
                                    ),
                                })}
                            </Text>
                        </Col>
                    )}
                    {(currentLoan?.balance || currentLoan?.balance === 0) && (
                        <Col>
                            <Text strong>
                                {t('Page.Loan.MasterElement.ReturingAmount', {
                                    amount: NumberHelper.formatThousandSeparator(
                                        currentLoan?.balance as number,
                                    ),
                                })}
                            </Text>
                        </Col>
                    )}
                </StyledFlexRow>
            </Col>
        </ColoredHeader>
    );
});

const ColoredHeader = styled(Row)`
    background-color: #f5f6f7;
    padding: 18px 27px;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 10px 11px 28px -26px rgba(0, 0, 0, 0.52);
    gap: 1rem;
`;

const StyledFlexRow = styled(Row)`
    gap: 1rem;
`;
