import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { LoanItem } from 'src/components/LoanItem/LoanItem';
import { Spinner } from 'src/components/Spinner/Spinner';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { LoanViewDTOStatusEnum } from 'src/generated-api-client';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const store = LoansStore;

export const LoansTab = observer(() => {
    const { params } = useRouteMatch<{ id: string }>();
    const { t } = useTranslation();

    useEffect(() => {
        store.getApplicationLoans(params.id);

        return () => {
            store.listLoader.reset();
        };
    }, [params.id]);

    return store.listLoader.isLoading ? (
        <StyledSpinnerContainer>
            <Spinner />{' '}
        </StyledSpinnerContainer>
    ) : (
        <div>
            {store.applicationLoanSortedByDate?.map((application) => (
                <LoanItem
                    id={application?.id}
                    date={application?.startDate}
                    dateText={
                        <Trans
                            i18nKey="Page.Loans.DateText"
                            tOptions={{ id: application.id }}
                        />
                    }
                    status={t(`Loan.View.DTOStatus.${application?.status}`)}
                    linkAddress={UrlHelper.getTargetUrl(RoutePaths.loan, {
                        loanId: application?.id,
                    })}
                    statusColor={
                        applicationStatusMapColors[
                            application.status as LoanViewDTOStatusEnum
                        ]
                    }
                    isFinished={finishedLoanStatuses.includes(
                        application.status as any,
                    )}
                    amount={application.initialAmount}
                    period={application.approvedTerm}
                    type={application.type}
                    discloserType={application.disclosure}
                />
            ))}
        </div>
    );
});

export const applicationStatusMapColors = {
    [LoanViewDTOStatusEnum.ACTIVE]: '#D6F7A0',
    [LoanViewDTOStatusEnum.OVERDUE]: '#EB7382',
    [LoanViewDTOStatusEnum.DEFAULT]: '#EB7382',
    [LoanViewDTOStatusEnum.PAIDUP]: '#D6F7A0',
    [LoanViewDTOStatusEnum.WRITTENOFF]: '#EB7382',
};

const finishedLoanStatuses = [
    LoanViewDTOStatusEnum.PAIDUP,
    LoanViewDTOStatusEnum.WRITTENOFF,
];

const StyledSpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
