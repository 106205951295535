import React from 'react';

export type RouteItem = {
    menuTitle?: () => React.ReactNode;
    label?: React.ReactNode;
    Component?: any;
    key: string;
    path: string;
    unavailable?: boolean;
    exact?: boolean;
    hidden?: boolean;
};

export type RouteGroup = {
    menuTitle?: () => React.ReactNode;
    Component?: any;
    key: string;
    path: string;
    menuLink?: string;
    unavailable?: boolean;
    exact?: boolean;
    hidden?: boolean;
    children?: RouteItem[];
};

export type RouteDefinition = RouteItem | RouteGroup;

export const isRouteGroup = (route: RouteDefinition): route is RouteGroup => {
    return Boolean((route as any)?.children);
};
