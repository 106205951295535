import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { useApplicationId } from 'src/hooks/useApplicationId';
import { usePandaDoc } from 'src/hooks/usePandaDoc';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import styled from 'styled-components';

const store = ApplicationStore;

export const SignContractPage = observer(() => {
    const { t } = useTranslation();
    const { contractLink } = store;
    const { applicationId } = useApplicationId();

    usePandaDoc(applicationId);

    useEffect(() => {
        if (!contractLink) {
            store.requestAgreement(applicationId);
        }

        return () => {
            store.resetContractLink();
            store.resetSetTimeOut();
        };
    }, []);

    return (
        <BasePage isLoading={!contractLink}>
            <Row gutter={24}>
                <Col span={24}>
                    <iframe
                        title="Agreement"
                        src={contractLink}
                        width="100%"
                        height="700px"
                    />
                </Col>
            </Row>
            <StyledButtonContainer>
                <StyledSaveButton
                    onClick={() => store.downloadAgreement(applicationId)}
                >
                    {t('Components.Button.DownloadAgreement')}
                </StyledSaveButton>
                <StyledDeclineButton
                    loading={store.declineOfferLetterAction.isLoading}
                    onClick={() => store.declineAgreement(applicationId)}
                >
                    {t('Components.Button.Decline')}
                </StyledDeclineButton>
            </StyledButtonContainer>
        </BasePage>
    );
});

const StyledButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 30px;
`;

const StyledSaveButton = styled(Button)`
    width: 190px;
    height: 42px;
    border-radius: 10px;
    font-weight: 600;
    border: none;
    background: #ffffff00;
    color: #096dd9;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #ffffff00;
        color: #096dd990;
        border: none;
    }
`;

const StyledDeclineButton = styled(Button)`
    width: 190px;
    height: 42px;
    margin-top: 9px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;
