/* eslint-disable sonarjs/no-identical-functions */
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { LoanSnapshotDTO, LoanViewDTO } from 'src/generated-api-client';
import { loansApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoansStoreClass extends BasicStore<LoanViewDTO> {
    @observable private operations?: LoanSnapshotDTO[];
    @observable private currentApplicationLoans?: LoanViewDTO[];

    api: BasicStoreApi<LoanViewDTO, any, any, any> = {
        loadList: async () => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loansApi.entityList(),
            );

            return result.data || [];
        },

        loadItem: async (id: string) => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loansApi.entityView(Number(id)),
            );

            return result.data;
        },
    };

    getOperationsAction = new AsyncOperationWithStatus((id: number) => {
        return RequestHelper.unwrapFromAxiosPromise(
            loansApi.getSnapshotDTOList(id),
        );
    });

    @action
    async getApplicationLoans(id: string) {
        await this.loadList();
        const res = this.list.filter(
            ({ loanApplicationId }) => Number(id) === loanApplicationId,
        );
        this.currentApplicationLoans = res;
    }

    @computed get getAllSnapshots() {
        return this.operations?.sort(
            (a, b) =>
                moment(b.dateTime).valueOf() - moment(a.dateTime).valueOf(),
        );
    }

    @action
    async getOperations(id: string | number) {
        const res = await this.getOperationsAction.call(Number(id));
        this.operations = res?.data;
    }

    @computed get loansSortedByDateInDesc(): LoanViewDTO[] {
        return this.list?.sort((a, b) => {
            if (
                moment(b.startDate).valueOf() ===
                    moment(a.startDate).valueOf() &&
                b?.id &&
                a?.id
            ) {
                return b.id - a.id;
            }

            return (
                moment(b.startDate).valueOf() - moment(a.startDate).valueOf()
            );
        });
    }

    @computed get applicationLoanSortedByDate() {
        return this.currentApplicationLoans?.sort((a, b) => {
            if (
                moment(b.startDate).valueOf() ===
                    moment(a.startDate).valueOf() &&
                b?.id &&
                a?.id
            ) {
                return b.id - a.id;
            }

            return (
                moment(b.startDate).valueOf() - moment(a.startDate).valueOf()
            );
        });
    }
}

export const LoansStore = new LoansStoreClass();
