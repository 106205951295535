import { Button, Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type EntityActionsProps = { items: ItemType[] };

export const EntityActions = observer(({ items }: EntityActionsProps) => {
    const { t } = useTranslation();

    return (
        <Dropdown overlay={<Menu items={items} />} trigger={['click']}>
            <StyledActionButton>
                {t('Component.EntityActions.Trigger')}
            </StyledActionButton>
        </Dropdown>
    );
});

const StyledActionButton = styled(Button)`
    width: 160px;
    margin-left: 20px;
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    line-height: 22px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;
