import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { SelectField } from 'src/components/Form/SelectField';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    LoanApplicationTerm,
    LoanApplicationType,
} from 'src/generated-api-client';
import { ApplyForALoanStore } from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';
import styled from 'styled-components';

const { Title } = Typography;

const store = ApplyForALoanStore;

export const FinancedItemsFormItem = observer(() => {
    return (
        <>
            <Row gutter={24} justify="center">
                <Col>
                    <StyledPageTitle level={5}>
                        {t(
                            'Page.ApplyForALoan.FinancedItems',
                            'Financed items',
                        )}
                    </StyledPageTitle>
                </Col>
            </Row>
            <Row gutter={24} justify="space-between">
                <Col span={9}>
                    <SelectField
                        convertListItemToOption={(term: any) => ({
                            children: term.label,
                            value: term.value,
                        })}
                        name="requestedTerm"
                        label={t('Page.ApplyForALoan.LoanTerm', 'Loan term:')}
                        data={[
                            {
                                value: LoanApplicationTerm.THIRTY,
                                label: '30',
                            },
                            {
                                value: LoanApplicationTerm.SIXTY,
                                label: '60',
                            },
                            {
                                value: LoanApplicationTerm.NINETY,
                                label: '90',
                            },
                        ]}
                        rules={[ValidationRules.required]}
                    />
                </Col>
                {store.formDeatType ===
                    LoanApplicationType.PURCHASEORDERFINANCE && (
                    <Col span={9}>
                        <TextField
                            name="purchaseOrderDescription"
                            label={t(
                                'Page.ApplyForALoan.PurchaseOrderDescription',
                                'Purchase order description:',
                            )}
                            rules={[
                                ValidationRules.required,
                                ValidationRules.max255Symbols,
                            ]}
                        />
                    </Col>
                )}
            </Row>
        </>
    );
});

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;
