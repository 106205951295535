import { ItemType } from 'antd/es/menu/hooks/useItems';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { EntityActions } from 'src/components/EntityActions/EntityActions';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    LoanApplicationStatus,
    LoanApplicationViewDTO,
} from 'src/generated-api-client';
import { ProvideAdditionalInformationModal } from 'src/pages/LoanApplicationPage/Dialogs/ProvideAdditionalInformation';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';

export const ApplicationActions = observer(() => {
    const currentItem = ApplicationStore?.currentItem;

    return getMenuItems(currentItem || {}).length > 0 ? (
        <EntityActions items={getMenuItems(currentItem || {})} />
    ) : null;
});

const getMenuItems = (application: LoanApplicationViewDTO): ItemType[] =>
    [
        application.status === LoanApplicationStatus.INITIALWAITINGDOCUMENTS
            ? {
                  key: 'upload-documents',
                  label: (
                      <Trans i18nKey="Page.LoanApplicationPage.Actions.UploadDocuments" />
                  ),
                  onClick: () => {
                      Router.setQuerySearchParams(
                          `${RoutePaths.applyForALoan}/3`,
                          `applicationId=${application?.id}&dealType=${application?.type}&disclosure=${application?.disclosure}`,
                      );
                  },
              }
            : null,
        application.status === LoanApplicationStatus.OFFERISSUED ||
        application.status === LoanApplicationStatus.FINALOFFERISSUED
            ? {
                  key: 'review-offer',
                  label: (
                      <Trans i18nKey="Page.LoanApplicationPage.Actions.ReviewTheOffer" />
                  ),
                  onClick: () => {
                      Router.navigate(
                          `${RoutePaths.loanApplications}/${application?.id}/offer-letter`,
                      );
                  },
              }
            : null,
        application.status === LoanApplicationStatus.SIGNINGPROCESS
            ? {
                  key: 'sign-agreement',
                  label: (
                      <Trans i18nKey="Page.LoanApplicationPage.Actions.SignAnAgreement" />
                  ),
                  onClick: () => {
                      Router.navigate(
                          `${RoutePaths.loanApplications}/${application?.id}/sign-contract`,
                      );
                  },
              }
            : null,
        application.status === LoanApplicationStatus.INFOREQUESTED
            ? {
                  key: 'provide-info',
                  label: (
                      <Trans i18nKey="Page.LoanApplicationPage.Actions.ProvideAdditionalInfo" />
                  ),
                  onClick: () => {
                      ProvideAdditionalInformationModal.render({
                          currentApplication: application,
                      });
                  },
              }
            : null,
    ].filter((item) => item !== null);
