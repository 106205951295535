/* eslint-disable no-param-reassign */
const NUMBER_LOCALE = 'mn-MN';
export const PHONE_MASK = '+';

(function (prototype) {
    const { toFixed } = prototype;

    prototype.toFixed = function (fractionDigits) {
        const fractionSplittedNumber = this.toString().split('.');
        const splittedNumber = this.toString().split('');
        const lastNumber = splittedNumber[splittedNumber.length - 1];
        const isLastNumberFive = Number(lastNumber) === 5;
        const isMoreThenTwoFraction =
            fractionSplittedNumber?.[1]?.split('')?.length >= 3;
        const number = +(!fractionSplittedNumber[1]
            ? fractionSplittedNumber[0]
            : isLastNumberFive && isMoreThenTwoFraction
            ? `${fractionSplittedNumber.join('.')}1`
            : this);

        return toFixed.call(number, fractionDigits);
    };
})(Number.prototype);

const roundNumber = (num: number, countDigits = 2) => {
    return +`${Math.round(Number(`${num}e+${countDigits}`))}e-${countDigits}`;
};

const formatToPercent = (value: number, minimumFractionDigits = 2) => {
    return Number(value / 100).toLocaleString(NUMBER_LOCALE, {
        style: 'percent',
        minimumFractionDigits,
    });
};

const formatThousandSeparator = (value: number, minimumFractionDigits = 2) => {
    return Number(roundNumber(value)).toLocaleString(NUMBER_LOCALE, {
        minimumFractionDigits,
    });
};

const commonNumberFormatter = (value: number) => {
    return Intl.NumberFormat('en-us', {
        minimumFractionDigits: 2,
        maximumSignificantDigits: 2,
    }).format(value);
};

const formatToMNT = (value: number, minimumFractionDigits = 2) => {
    return Intl.NumberFormat(NUMBER_LOCALE, {
        currency: 'MNT',
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits,
    }).format(value);
};

const enFormatToMNT = (value: number, minimumFractionDigits = 2) => {
    return Intl.NumberFormat('en-us', {
        currency: 'MNT',
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits,
    }).format(value);
};

const formatToPhoneNumber = (value?: string | number, mask = PHONE_MASK) => {
    return `${mask}${value}`;
};

export const NumberHelper = {
    formatToPercent,
    formatThousandSeparator,
    formatToMNT,
    enFormatToMNT,
    formatToPhoneNumber,
    commonNumberFormatter,
};
