/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable sonarjs/cognitive-complexity */
import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

const store = ApplicationStore;

const { Text } = Typography;

export const OfferLetterTable = observer(() => {
    const { t } = useTranslation();
    const offerLaterData = store.offerLetter;

    return (
        <>
            {offerLaterData?.applicationSubmitDate && (
                <Row gutter={24} justify="space-between">
                    <Col flex="auto">
                        {t('OfferLetter.Table.ApplicationDate')}
                    </Col>
                    <Col>
                        {moment(offerLaterData?.applicationSubmitDate).format(
                            'DD.MM.YYYY',
                        )}
                    </Col>
                </Row>
            )}
            {offerLaterData?.applicationId && (
                <Row gutter={24} justify="space-between">
                    <Col flex="auto">
                        {t('OfferLetter.Table.ApplicationNumber')}
                    </Col>
                    <Col>{offerLaterData?.applicationId}</Col>
                </Row>
            )}
            {offerLaterData?.approvedAmount !== null &&
                Boolean((offerLaterData?.approvedAmount as number) >= 0) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            {t('OfferLetter.Table.PrincipalDebt')}
                        </Col>
                        <Col>
                            R{' '}
                            {NumberHelper.formatThousandSeparator(
                                offerLaterData?.approvedAmount as number,
                                2,
                            )}
                        </Col>
                    </Row>
                )}
            {offerLaterData?.initiationFee !== null &&
                Boolean((offerLaterData?.initiationFee as number) >= 0) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            {t('OfferLetter.Table.InitiationFee')}
                        </Col>
                        <Col>
                            R{' '}
                            {NumberHelper.formatThousandSeparator(
                                offerLaterData?.initiationFee || 0,
                                2,
                            )}
                        </Col>
                    </Row>
                )}
            {offerLaterData?.totalAmount !== null &&
                Boolean((offerLaterData?.totalAmount as number) >= 0) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>{t('OfferLetter.Table.Total')}</Trans>
                        </Col>
                        <Col>
                            <Text strong>
                                R{' '}
                                {NumberHelper.formatThousandSeparator(
                                    offerLaterData?.totalAmount as number,
                                    2,
                                )}
                            </Text>
                        </Col>
                    </Row>
                )}
            <StyledDivider />
            {offerLaterData?.totalInterestRatePercentage !== null &&
                Boolean(
                    (offerLaterData?.totalInterestRatePercentage as number) >=
                        0,
                ) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>
                                {t('OfferLetter.Table.InterestRatePayable', {
                                    days: t(
                                        `Loan.Application.Term.${offerLaterData?.approvedTerm}`,
                                    ),
                                })}
                            </Trans>
                        </Col>
                        <Col>
                            {NumberHelper.formatToPercent(
                                offerLaterData?.totalInterestRatePercentage as number,
                            )}
                        </Col>
                    </Row>
                )}
            {offerLaterData?.interestAmount !== null &&
                Boolean((offerLaterData?.interestAmount as number) >= 0) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>
                                {t('OfferLetter.Table.InterestAmountPayable', {
                                    months: offerLaterData?.totalNumberOfPeriods,
                                })}
                            </Trans>
                        </Col>
                        <Col>
                            R{' '}
                            {NumberHelper.formatThousandSeparator(
                                offerLaterData?.interestAmount || 0,
                                2,
                            )}
                        </Col>
                    </Row>
                )}
            {offerLaterData?.creditCost !== null &&
                Boolean((offerLaterData?.creditCost as number) >= 0) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>
                                {t('OfferLetter.Table.IllustratedCostOfCredit')}
                            </Trans>
                        </Col>
                        <Col>
                            R{' '}
                            {NumberHelper.formatThousandSeparator(
                                offerLaterData?.creditCost || 0,
                                2,
                            )}
                        </Col>
                    </Row>
                )}
            {offerLaterData?.insuranceAmount !== null &&
                Boolean((offerLaterData?.insuranceAmount as number) >= 0) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>
                                {t('OfferLetter.Table.InsuranceAtPrincipal', {
                                    percent:
                                        offerLaterData?.insuranceRateMultiplierPercentage,
                                })}
                            </Trans>
                        </Col>
                        <Col>
                            R{' '}
                            {NumberHelper.formatThousandSeparator(
                                offerLaterData?.insuranceAmount || 0,
                                2,
                            )}
                        </Col>
                    </Row>
                )}
            {offerLaterData?.totalMonthlyFee !== null &&
                Boolean((offerLaterData?.totalMonthlyFee as number) >= 0) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>
                                {t('OfferLetter.Table.MonthlyCreditService', {
                                    month: offerLaterData?.totalNumberOfPeriods,
                                })}
                            </Trans>
                        </Col>
                        <Col>
                            R{' '}
                            {NumberHelper.formatThousandSeparator(
                                offerLaterData?.totalMonthlyFee || 0,
                                2,
                            )}
                        </Col>
                    </Row>
                )}
            {offerLaterData?.payableGrossAmount !== null &&
                Boolean(
                    (offerLaterData?.payableGrossAmount as number) >= 0,
                ) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>
                                {t('OfferLetter.Table.IllustratedGrossAmount', {
                                    month: offerLaterData?.totalNumberOfPeriods,
                                })}
                            </Trans>
                        </Col>
                        <Col>
                            R{' '}
                            {NumberHelper.formatThousandSeparator(
                                offerLaterData?.payableGrossAmount || 0,
                                2,
                            )}
                        </Col>
                    </Row>
                )}
            {offerLaterData?.vatRateAmount !== null &&
                Boolean((offerLaterData?.vatRateAmount as number) >= 0) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>
                                {t('OfferLetter.Table.VATCharged', {
                                    percents: offerLaterData?.vatRatePercentage,
                                })}
                            </Trans>
                        </Col>
                        <Col>
                            R{' '}
                            {NumberHelper.formatThousandSeparator(
                                offerLaterData?.vatRateAmount || 0,
                                2,
                            )}
                        </Col>
                    </Row>
                )}
            <StyledDivider />
            {offerLaterData?.totalCreditCost !== null &&
                Boolean((offerLaterData?.totalCreditCost as number) >= 0) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>
                                {t('OfferLetter.Table.IllustratedTotal')}
                            </Trans>
                        </Col>
                        <Col>
                            <Text strong>
                                R{' '}
                                {NumberHelper.formatThousandSeparator(
                                    offerLaterData?.totalCreditCost || 0,
                                    2,
                                )}
                            </Text>
                        </Col>
                    </Row>
                )}
            {offerLaterData?.firstPaymentAmount !== null &&
            Boolean((offerLaterData?.firstPaymentAmount as number) >= 0) ? (
                <Row gutter={24} justify="space-between">
                    <Col flex="auto">
                        <Trans>
                            {t('OfferLetter.Table.FirstPaymentAmount')}
                        </Trans>
                    </Col>
                    <Col>
                        R{' '}
                        {NumberHelper.formatThousandSeparator(
                            offerLaterData?.firstPaymentAmount || 0,
                            2,
                        )}
                    </Col>
                </Row>
            ) : null}
            {offerLaterData?.secondPaymentAmount !== null &&
            Boolean((offerLaterData?.secondPaymentAmount as number) >= 0) ? (
                <Row gutter={24} justify="space-between">
                    <Col flex="auto">
                        <Trans>
                            {t('OfferLetter.Table.SecondPaymentAmount')}
                        </Trans>
                    </Col>
                    <Col>
                        R{' '}
                        {NumberHelper.formatThousandSeparator(
                            offerLaterData?.secondPaymentAmount || 0,
                            2,
                        )}
                    </Col>
                </Row>
            ) : null}
            {offerLaterData?.thirdPaymentAmount !== null &&
                Boolean(
                    (offerLaterData?.thirdPaymentAmount as number) >= 0,
                ) && (
                    <Row gutter={24} justify="space-between">
                        <Col flex="auto">
                            <Trans>
                                {t('OfferLetter.Table.ThirdPaymentAmount')}
                            </Trans>
                        </Col>
                        <Col>
                            R{' '}
                            {NumberHelper.formatThousandSeparator(
                                offerLaterData?.thirdPaymentAmount as number,
                                2,
                            )}
                        </Col>
                    </Row>
                )}
            {(offerLaterData?.totalCreditCost ||
                offerLaterData?.firstPaymentAmount ||
                offerLaterData?.secondPaymentAmount ||
                offerLaterData?.thirdPaymentAmount) && <StyledDivider />}
        </>
    );
});

const StyledDivider = styled.div`
    margin: 15px 0;
    border-bottom: 1px solid #000000;
`;
