import { History, State } from 'history';

// eslint-disable-next-line no-underscore-dangle, init-declarations
let _history: History;

const setHistory = (history: History) => {
    _history = history;
};

const navigate = (url: string, state?: State) => {
    _history.push(url, state);
};

const replace = (url: string, state?: State) => {
    _history.replace(url, state);
};

const setQuerySearchParams = (pathname: string, params: string) => {
    _history.replace({
        pathname,
        search: params,
    });
};

const getQuerySearchParams = () => {
    return _history.location.search;
};

const goBack = () => {
    return _history.go(-1);
};

export const Router = {
    setHistory,
    navigate,
    replace,
    setQuerySearchParams,
    getQuerySearchParams,
    goBack,
};
