import { History } from 'history';
import { action, computed, observable } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { configureMobX } from 'src/mobXSettings';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';

import { ApplicationsStore } from '../ApplicationsStore/ApplicationsStore';
import { ProfileStore } from '../ProfileStore/ProfileStore';

export class AppStoreClass {
    @observable isSetHistory = false;
    @observable isInited = false;

    @action
    async init() {
        configureMobX();
        await SettingsStore.load();

        if (SettingsStore.hasSettings) {
            await SettingsStore.setupInternationalization();
            SettingsStore.setupKeycloak();
            await AuthStore.authenticate();
            await AppUser.loadUserInfo();
            await ProfileStore.loadProfileData();
            await ApplicationsStore.loadList();
            if (
                [
                    RoutePaths.signupSecondStep,
                    RoutePaths.signupThirdStep,
                    RoutePaths.dashboard,
                ].includes(window.location.pathname as string)
            ) {
                await ProfileStore.loadProfile();
            }
        }
        this.isInited = true;
    }

    @computed get started() {
        return this.isSetHistory && this.isInited;
    }

    setHistory(history: History) {
        Router.setHistory(history);
        this.isSetHistory = true;
    }
}

export const AppStore = new AppStoreClass();
