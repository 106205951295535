/* eslint-disable max-lines */
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
    Col,
    Form as AntForm,
    Form,
    FormInstance,
    Radio,
    RadioChangeEvent,
    Row,
    Space,
    Tooltip,
    Typography,
} from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    LoanApplicationDisclosure,
    LoanApplicationType,
} from 'src/generated-api-client';
import { ApplyForALoanStore } from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

const { Title } = Typography;

const store = ApplyForALoanStore;
const profileStore = ProfileStore;

export const DisclosureFormItem = observer(
    ({ form }: { form: FormInstance<any> }) => {
        const currentApplication = store.initialApplicationData;

        const [isRequiredBankDtls, setIsRequiredBankDtls] = useState(false);

        const bankName = Form?.useWatch('thirdPartyBankName', form);
        const accountName = Form?.useWatch('thirdPartyAccountName', form);
        const accountType = Form?.useWatch('thirdPartyAccountType', form);
        const branchCode = Form?.useWatch('thirdPartyBranchCode', form);
        const accountNumber = Form?.useWatch('thirdPartyAccountNumber', form);

        useEffect(() => {
            if (
                bankName ||
                accountName ||
                accountNumber ||
                accountType ||
                branchCode
            ) {
                setIsRequiredBankDtls(true);
            } else {
                setIsRequiredBankDtls(false);
                resetBankDetailsFields(form);
            }
        }, [bankName, accountType, accountName, accountNumber, branchCode]);

        const onChangeDisclosed = (e: RadioChangeEvent) => {
            store.setFormLoanApplicationData({
                ...form.getFieldsValue(),
                disclosure: e.target.value,
            });
        };

        const dealTypesWithNonDisclosure = [
            LoanApplicationType.PURCHASEORDERFINANCE,
            LoanApplicationType.FINANCEOFDEBTOR,
        ];

        const isRequired = [
            isRequiredBankDtls ? ValidationRules.bankDetails : {},
        ];

        return (
            <>
                <Row gutter={24} justify="center" align="middle">
                    <StyledTooltipWrapper>
                        <StyledPageTitle level={5}>
                            {t(
                                'Page.ApplyForALoan.Disclosure.Title',
                                'Loan deal disclosure',
                            )}
                        </StyledPageTitle>
                        <Tooltip
                            title={
                                <Trans i18nKey="Page.Dashboard.Disclosure.Tooltip" />
                            }
                        >
                            <StyledInfoIcon />
                        </Tooltip>
                    </StyledTooltipWrapper>
                </Row>
                <Row gutter={24} justify="center">
                    <Col>
                        <AntForm.Item name="disclosure">
                            <Radio.Group
                                value={store?.initialDisclosure}
                                onChange={onChangeDisclosed}
                                size="large"
                                disabled={dealTypesWithNonDisclosure.includes(
                                    currentApplication?.type as LoanApplicationType,
                                )}
                            >
                                <Space direction="horizontal">
                                    <StyledRadioSelect
                                        value={
                                            LoanApplicationDisclosure.UNDISCLOSED
                                        }
                                    >
                                        {t(
                                            'Loan.Application.Disclosure.UNDISCLOSED',
                                        )}
                                    </StyledRadioSelect>
                                    <StyledRadioSelect
                                        value={
                                            LoanApplicationDisclosure.DISCLOSED
                                        }
                                    >
                                        {t(
                                            'Loan.Application.Disclosure.DISCLOSED',
                                        )}
                                    </StyledRadioSelect>
                                </Space>
                            </Radio.Group>
                        </AntForm.Item>
                    </Col>
                </Row>
                {store.initialDisclosure ===
                    LoanApplicationDisclosure.DISCLOSED && (
                    <>
                        <Row gutter={24} justify="center">
                            <Col>
                                <StyledPageTitle level={5}>
                                    {' '}
                                    {t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.Title',
                                        'State your counterparty',
                                    )}
                                </StyledPageTitle>
                            </Col>
                        </Row>
                        <Row gutter={24} justify="space-between">
                            <Col span={9}>
                                <TextField
                                    name="thirdPartyCipc"
                                    label={t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.CIPC',
                                        'CIPC:',
                                    )}
                                    rules={[
                                        ValidationRules.required,
                                        ValidationRules.isTheSameCIPCValue(
                                            profileStore.user?.cipc,
                                        ),
                                        ValidationRules.CIPCFormatValidation,
                                    ]}
                                />
                            </Col>
                            <Col span={9}>
                                <TextField
                                    name="thirdPartyLegalName"
                                    label={t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.LegalName',
                                        'Legal name:',
                                    )}
                                    rules={[
                                        ValidationRules.required,
                                        ValidationRules.maxLengthLegalName,
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24} justify="space-between">
                            <Col span={9}>
                                <TextField
                                    name="thirdPartyEmail"
                                    label={t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.Email',
                                        'Email:',
                                    )}
                                    rules={[
                                        ValidationRules.required,
                                        ValidationRules.email,
                                        ValidationRules.isTheSameEmailValue(
                                            profileStore.user?.contactDetails
                                                .email,
                                        ),
                                    ]}
                                />
                            </Col>
                            <Col span={9}>
                                <TextField
                                    name="thirdPartyAddress"
                                    label={t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.FullAddress',
                                        'Full address:',
                                    )}
                                    rules={[
                                        ValidationRules.required,
                                        ValidationRules.max255Symbols,
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24} justify="space-between">
                            <Col span={9}>
                                <TextField
                                    name="thirdPartyBankName"
                                    label={t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.BankName',
                                        'Bank name:',
                                    )}
                                    rules={isRequired}
                                />
                            </Col>
                            <Col span={9}>
                                <TextField
                                    name="thirdPartyAccountName"
                                    label={t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.AccountName',
                                        'Account holder name:',
                                    )}
                                    rules={isRequired}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24} justify="space-between">
                            <Col span={9}>
                                <TextField
                                    name="thirdPartyAccountType"
                                    label={t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.AccountType',
                                        'Account type:',
                                    )}
                                    rules={isRequired}
                                />
                            </Col>
                            <Col span={9}>
                                <TextField
                                    name="thirdPartyBranchCode"
                                    label={t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.BranchCode',
                                        'Branch code:',
                                    )}
                                    rules={isRequired}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24} justify="start">
                            <Col span={9}>
                                <TextField
                                    name="thirdPartyAccountNumber"
                                    label={t(
                                        'Page.ApplyForALoan.Disclosure.Counterparty.AccountNumber',
                                        'Account number:',
                                    )}
                                    rules={isRequired}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </>
        );
    },
);

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;

const StyledRadioSelect = styled(Radio.Button)`
    width: 320px;
    text-align: center;
    border-color: #000000;
    border-left-color: #000000 !important;
    &.ant-radio-button-wrapper-checked {
        border-color: #d8021c !important;
        border-right-color: #d8021c !important;
        color: #ffffff;
        background: #d8021c;
    }
    &.ant-radio-button-wrapper-checked:hover,
    &:hover {
        color: #000000;
    }
    &.ant-radio-button-wrapper-disabled {
        color: #d9d9d9;
    }
    &.ant-radio-button-wrapper-disabled:hover {
        color: #d9d9d9;
    }
    .ant-radio-button-wrapper {
        border: none;
    }
    &.ant-radio-button-wrapper-disabled:first-child {
        border-left-color: #d9d9d9 !important;
    }
`;

const StyledInfoIcon = styled(QuestionCircleOutlined)`
    margin: 0 0 7px 5px;
    font-size: 0.9rem;
`;

const StyledTooltipWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const resetBankDetailsFields = (form: FormInstance<any>) => {
    const resetData = {
        touched: false,
        errors: [],
    };

    form.setFields([
        {
            name: ['thirdPartyBankName'],
            ...resetData,
        },
        {
            name: ['thirdPartyAccountName'],
            ...resetData,
        },
        {
            name: ['thirdPartyAccountType'],
            ...resetData,
        },
        {
            name: ['thirdPartyBranchCode'],
            ...resetData,
        },
        {
            name: ['thirdPartyAccountNumber'],
            ...resetData,
        },
    ]);
};
