import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';

const store = LoansStore;

export const Counterparty = observer(() => {
    const { t } = useTranslation();

    const currentLoan = store.currentItem;

    return (
        <>
            <Row>
                <BlockTitle>{t('Page.Counterparty.Header')}</BlockTitle>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.thirdPartyCipc}
                        label={t('Page.Counterparty.ThirdPartyCipc')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.thirdPartyLegalName}
                        label={t('Page.Counterparty.ThirdPartyLegalName')}
                    />
                </Col>
                <Col span={8}>
                    <DataView
                        value={currentLoan?.thirdPartyEmail}
                        label={t('Page.Counterparty.ThirdPartyEmail')}
                    />
                </Col>
            </Row>
        </>
    );
});
