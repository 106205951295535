import { action, computed, observable } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    BankAccountDTO,
    BankAccountType,
    CountryDTO,
    CustomerContactDetailsDto,
    CustomerUserDTO,
    CustomerVerificationStatus,
    ProvinceDTO,
} from 'src/generated-api-client';
import { usersApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { NumberHelper } from 'src/utils/NumberHelper';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProfileStoreClass {
    @observable private currentUser?: CustomerUserDTO;
    @observable private currentProfile?: CustomerUserDTO;
    @observable private countries?: CountryDTO[];
    @observable private provinces?: ProvinceDTO[];

    getCountriesAction = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(usersApi.getCountries()),
    );

    getProvincesAction = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(usersApi.getProvinces()),
    );

    profileLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(usersApi.getProfileByKeycloakId()),
    );

    setProfileSecondStepDataAction = new AsyncOperationWithStatus((data) => {
        return RequestHelper.unwrapFromAxiosPromise(usersApi.saveStep2(data));
    });

    uploadDocumentsAction = new AsyncOperationWithStatus((data) => {
        return RequestHelper.unwrapFromAxiosPromise(usersApi.saveStep2(data));
    });

    confirmThirdStepAction = new AsyncOperationWithStatus(() => {
        return RequestHelper.unwrapFromAxiosPromise(usersApi.saveStep3());
    });

    addNewBankAccountLoader = new AsyncOperationWithStatus(
        (data: BankAccountDTO) => {
            return RequestHelper.unwrapFromAxiosPromise(
                usersApi.addBankAccount(data),
            );
        },
    );

    updateContactDetailsLoader = new AsyncOperationWithStatus(
        (data: CustomerContactDetailsDto) => {
            return RequestHelper.unwrapFromAxiosPromise(
                usersApi.updateContactDetails(data),
            );
        },
    );

    @action updateContactDetails(data: CustomerContactDetailsDto) {
        return this.updateContactDetailsLoader.call({
            ...data,
            phoneNumber: NumberHelper.formatToPhoneNumber(data.phoneNumber),
            representativePhoneNumber: NumberHelper.formatToPhoneNumber(
                data.representativePhoneNumber,
            ),
            address: {
                ...data.address,
                country: this.countries?.find(
                    (country) => country.name === data.address.country.name,
                ),
                province: this.provinces?.find(
                    (province) => province.name === data.address.province.name,
                ),
            },
        } as CustomerContactDetailsDto);
    }

    @action addNewBankAccount(data: BankAccountDTO) {
        return this.addNewBankAccountLoader.call(data);
    }

    @action async loadProfile() {
        const result = await this.profileLoader.call();
        this.currentUser = result?.data;
        if (result?.data === null) {
            Router.navigate(RoutePaths.signupSecondStep);
        } else if (
            result?.data?.verificationStatus ===
            CustomerVerificationStatus.WAITDOCUMENTS
        ) {
            Router.navigate(RoutePaths.signupThirdStep);
        } else {
            Router.navigate(RoutePaths.dashboard);
        }
    }

    @action async loadProfileData() {
        const result = await this.profileLoader.call();
        this.currentProfile = result?.data;
    }

    @action async getCountries() {
        const result = await this?.getCountriesAction.call();
        this.countries = result?.data;
    }

    @action async getProvinces() {
        const result = await this?.getProvincesAction.call();
        this.provinces = result?.data;
    }

    @action async setProfileSecondStepData(data: any) {
        const customerData: CustomerUserDTO = {
            contactDetails: {
                address: {
                    buildingName: data.buildingName,
                    country:
                        this.countries?.find(
                            (country) => country.name === data.country,
                        ) || ({} as any),
                    province:
                        this.provinces?.find(
                            (province) => province.name === data.province,
                        ) || ({} as any),
                    house: data.house,
                    postalCode: data.postalCode,
                    street: data.street,
                    city: data.city,
                },
                email: data.email,
                representativePhoneNumber: NumberHelper.formatToPhoneNumber(
                    data.representativePhoneNumber,
                ),
                phoneNumber: NumberHelper.formatToPhoneNumber(
                    data.telephoneNumber,
                ),
                representativeEmail: data.contactDetails.representativeEmail,
                representativeFullName: data.representativeFullName,
            },
            bankAccounts: [
                {
                    accountName: data.accountName,
                    accountNumber: data.accountNumber,
                    accountType: data.accountType,
                    bankName: data.bankName,
                    branchCode: data.branchCode,
                    type: BankAccountType.CUSTOMER,
                },
            ],
            verificationStatus: CustomerVerificationStatus.WAITDOCUMENTS,
            id: this.profile?.id || undefined,
            legalName: data.legalName,
            taxId: data.taxId,
        };

        await this.setProfileSecondStepDataAction.call(customerData);

        if (!this.setProfileSecondStepDataAction.hasError) {
            Router.navigate(RoutePaths.signupThirdStep);
        }
    }

    @action async confirmThirdStep() {
        await this?.confirmThirdStepAction.call();
        if (!this.confirmThirdStepAction.hasError) {
            Router.navigate(RoutePaths.dashboard);
        }
    }

    @computed get profile() {
        return this.currentUser;
    }

    @computed get countriesList() {
        return this.countries;
    }

    @computed get provincesList() {
        return this.provinces;
    }

    @computed get user() {
        return this.currentProfile;
    }
}

export const ProfileStore = new ProfileStoreClass();
