// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    buildingName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {Country}
     * @memberof Address
     */
    country?: Country;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    fullAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    house?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {Province}
     * @memberof Address
     */
    province?: Province;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string;
}
/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    buildingName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    city: string;
    /**
     * 
     * @type {CountryDTO}
     * @memberof AddressDTO
     */
    country: CountryDTO;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    house: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    postalCode: string;
    /**
     * 
     * @type {ProvinceDTO}
     * @memberof AddressDTO
     */
    province: ProvinceDTO;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    street: string;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyBoolean
 */
export interface ApiResponseBodyBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponseBodyBoolean
     */
    data?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyBoolean
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyCustomerDashboardView
 */
export interface ApiResponseBodyCustomerDashboardView {
    /**
     * 
     * @type {CustomerDashboardView}
     * @memberof ApiResponseBodyCustomerDashboardView
     */
    data?: CustomerDashboardView;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyCustomerDashboardView
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyCustomerUserDTO
 */
export interface ApiResponseBodyCustomerUserDTO {
    /**
     * 
     * @type {CustomerUserDTO}
     * @memberof ApiResponseBodyCustomerUserDTO
     */
    data?: CustomerUserDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyCustomerUserDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyDesiredLoanCalculationResponse
 */
export interface ApiResponseBodyDesiredLoanCalculationResponse {
    /**
     * 
     * @type {DesiredLoanCalculationResponse}
     * @memberof ApiResponseBodyDesiredLoanCalculationResponse
     */
    data?: DesiredLoanCalculationResponse;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyDesiredLoanCalculationResponse
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListCountryDTO
 */
export interface ApiResponseBodyListCountryDTO {
    /**
     * 
     * @type {Array<CountryDTO>}
     * @memberof ApiResponseBodyListCountryDTO
     */
    data?: Array<CountryDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListCountryDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListDocumentDTO
 */
export interface ApiResponseBodyListDocumentDTO {
    /**
     * 
     * @type {Array<DocumentDTO>}
     * @memberof ApiResponseBodyListDocumentDTO
     */
    data?: Array<DocumentDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListDocumentDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListLoanApplicationListDTO
 */
export interface ApiResponseBodyListLoanApplicationListDTO {
    /**
     * 
     * @type {Array<LoanApplicationListDTO>}
     * @memberof ApiResponseBodyListLoanApplicationListDTO
     */
    data?: Array<LoanApplicationListDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListLoanApplicationListDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListLoanSnapshotDTO
 */
export interface ApiResponseBodyListLoanSnapshotDTO {
    /**
     * 
     * @type {Array<LoanSnapshotDTO>}
     * @memberof ApiResponseBodyListLoanSnapshotDTO
     */
    data?: Array<LoanSnapshotDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListLoanSnapshotDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListLoanViewDTO
 */
export interface ApiResponseBodyListLoanViewDTO {
    /**
     * 
     * @type {Array<LoanViewDTO>}
     * @memberof ApiResponseBodyListLoanViewDTO
     */
    data?: Array<LoanViewDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListLoanViewDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyListProvinceDTO
 */
export interface ApiResponseBodyListProvinceDTO {
    /**
     * 
     * @type {Array<ProvinceDTO>}
     * @memberof ApiResponseBodyListProvinceDTO
     */
    data?: Array<ProvinceDTO>;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyListProvinceDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyLoanApplicationOfferLetter
 */
export interface ApiResponseBodyLoanApplicationOfferLetter {
    /**
     * 
     * @type {LoanApplicationOfferLetter}
     * @memberof ApiResponseBodyLoanApplicationOfferLetter
     */
    data?: LoanApplicationOfferLetter;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyLoanApplicationOfferLetter
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyLoanApplicationViewDTO
 */
export interface ApiResponseBodyLoanApplicationViewDTO {
    /**
     * 
     * @type {LoanApplicationViewDTO}
     * @memberof ApiResponseBodyLoanApplicationViewDTO
     */
    data?: LoanApplicationViewDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyLoanApplicationViewDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyLoanProductDTO
 */
export interface ApiResponseBodyLoanProductDTO {
    /**
     * 
     * @type {LoanProductDTO}
     * @memberof ApiResponseBodyLoanProductDTO
     */
    data?: LoanProductDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyLoanProductDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyLoanViewDTO
 */
export interface ApiResponseBodyLoanViewDTO {
    /**
     * 
     * @type {LoanViewDTO}
     * @memberof ApiResponseBodyLoanViewDTO
     */
    data?: LoanViewDTO;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyLoanViewDTO
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyMapLongString
 */
export interface ApiResponseBodyMapLongString {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiResponseBodyMapLongString
     */
    data?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyMapLongString
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyString
 */
export interface ApiResponseBodyString {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseBodyString
     */
    data?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyString
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseBodyVoid
 */
export interface ApiResponseBodyVoid {
    /**
     * 
     * @type {object}
     * @memberof ApiResponseBodyVoid
     */
    data?: object;
    /**
     * 
     * @type {number}
     * @memberof ApiResponseBodyVoid
     */
    status?: number;
}
/**
 * 
 * @export
 * @interface Authentication
 */
export interface Authentication {
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    realm?: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountType?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    branchCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    createdAt?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof BankAccount
     */
    createdBy?: UserInfo;
    /**
     * 
     * @type {number}
     * @memberof BankAccount
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    lastModifiedAt?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof BankAccount
     */
    lastModifiedBy?: UserInfo;
    /**
     * 
     * @type {BankAccountType}
     * @memberof BankAccount
     */
    type?: BankAccountType;
}
/**
 * 
 * @export
 * @interface BankAccountDTO
 */
export interface BankAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    accountName: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    accountType: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDTO
     */
    branchCode: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccountDTO
     */
    id?: number;
    /**
     * 
     * @type {BankAccountType}
     * @memberof BankAccountDTO
     */
    type?: BankAccountType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BankAccountType {
    CUSTOMER = 'CUSTOMER',
    DISBURSEMENT = 'DISBURSEMENT',
    THIRDPARTY = 'THIRD_PARTY'
}

/**
 * 
 * @export
 * @interface ConstraintViolationProblem
 */
export interface ConstraintViolationProblem {
    /**
     * 
     * @type {ThrowableProblem}
     * @memberof ConstraintViolationProblem
     */
    cause?: ThrowableProblem;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    instance?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    message?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ConstraintViolationProblem
     */
    parameters?: { [key: string]: object; };
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ConstraintViolationProblem
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
    /**
     * 
     * @type {StatusType}
     * @memberof ConstraintViolationProblem
     */
    status?: StatusType;
    /**
     * 
     * @type {Array<ConstraintViolationProblemSuppressed>}
     * @memberof ConstraintViolationProblem
     */
    suppressed?: Array<ConstraintViolationProblemSuppressed>;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblem
     */
    type?: string;
    /**
     * 
     * @type {Array<Violation>}
     * @memberof ConstraintViolationProblem
     */
    violations?: Array<Violation>;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblemCause
 */
export interface ConstraintViolationProblemCause {
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemCause
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemCause
     */
    message?: string;
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ConstraintViolationProblemCause
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblemStackTrace
 */
export interface ConstraintViolationProblemStackTrace {
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    classLoaderName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ConstraintViolationProblemStackTrace
     */
    lineNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    methodName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    moduleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemStackTrace
     */
    moduleVersion?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConstraintViolationProblemStackTrace
     */
    nativeMethod?: boolean;
}
/**
 * 
 * @export
 * @interface ConstraintViolationProblemSuppressed
 */
export interface ConstraintViolationProblemSuppressed {
    /**
     * 
     * @type {ConstraintViolationProblemCause}
     * @memberof ConstraintViolationProblemSuppressed
     */
    cause?: ConstraintViolationProblemCause;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemSuppressed
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ConstraintViolationProblemSuppressed
     */
    message?: string;
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ConstraintViolationProblemSuppressed
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
}
/**
 * 
 * @export
 * @interface ContrOfferForm
 */
export interface ContrOfferForm {
    /**
     * 
     * @type {string}
     * @memberof ContrOfferForm
     */
    contrOffer: string;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CountryDTO
 */
export interface CountryDTO {
    /**
     * 
     * @type {number}
     * @memberof CountryDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CreateLoanProductForm
 */
export interface CreateLoanProductForm {
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    initiationFee: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    insuranceRateMultiplier: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    interestRate: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    interestRateRestriction: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    monthlyFee: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    penaltyInterestMultiplier: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLoanProductForm
     */
    productName: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLoanProductForm
     */
    vatRate: number;
}
/**
 * 
 * @export
 * @interface CustomerContactDetailsDto
 */
export interface CustomerContactDetailsDto {
    /**
     * 
     * @type {AddressDTO}
     * @memberof CustomerContactDetailsDto
     */
    address: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    representativeEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    representativeFullName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDetailsDto
     */
    representativePhoneNumber?: string;
}
/**
 * 
 * @export
 * @interface CustomerDashboardView
 */
export interface CustomerDashboardView {
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardView
     */
    amountDue30Days?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardView
     */
    amountDue60Days?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardView
     */
    countActiveLoans?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDashboardView
     */
    documentsValidTillDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardView
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDashboardView
     */
    maxPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDashboardView
     */
    minPaymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardView
     */
    nearestPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardView
     */
    sumTotalExposure?: number;
}
/**
 * 
 * @export
 * @interface CustomerUser
 */
export interface CustomerUser {
    /**
     * 
     * @type {BankAccount}
     * @memberof CustomerUser
     */
    actualBankAccount?: BankAccount;
    /**
     * 
     * @type {number}
     * @memberof CustomerUser
     */
    actualBankAccountId?: number;
    /**
     * 
     * @type {Address}
     * @memberof CustomerUser
     */
    address?: Address;
    /**
     * 
     * @type {Array<BankAccount>}
     * @memberof CustomerUser
     */
    bankAccounts?: Array<BankAccount>;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    cipc?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    createdAt?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof CustomerUser
     */
    createdBy?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerUser
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    keycloakId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    lastActivityAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    lastModifiedAt?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof CustomerUser
     */
    lastModifiedBy?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    representativeEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    representativeFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    representativePhoneNumber?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof CustomerUser
     */
    status?: UserStatus;
    /**
     * 
     * @type {string}
     * @memberof CustomerUser
     */
    taxId?: string;
    /**
     * 
     * @type {CustomerVerificationStatus}
     * @memberof CustomerUser
     */
    verificationStatus?: CustomerVerificationStatus;
}
/**
 * 
 * @export
 * @interface CustomerUserDTO
 */
export interface CustomerUserDTO {
    /**
     * 
     * @type {Array<BankAccountDTO>}
     * @memberof CustomerUserDTO
     */
    bankAccounts: Array<BankAccountDTO>;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserDTO
     */
    cipc?: string;
    /**
     * 
     * @type {CustomerContactDetailsDto}
     * @memberof CustomerUserDTO
     */
    contactDetails: CustomerContactDetailsDto;
    /**
     * 
     * @type {number}
     * @memberof CustomerUserDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserDTO
     */
    lastActivityAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserDTO
     */
    legalName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerUserDTO
     */
    taxId: string;
    /**
     * 
     * @type {CustomerVerificationStatus}
     * @memberof CustomerUserDTO
     */
    verificationStatus?: CustomerVerificationStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerVerificationStatus {
    WAITDOCUMENTS = 'WAIT_DOCUMENTS',
    CREDITBUREAUCHECK = 'CREDIT_BUREAU_CHECK',
    INREVIEW = 'IN_REVIEW',
    VERIFIED = 'VERIFIED',
    DECLINED = 'DECLINED',
    REJECTEDBYCREDITBUREAU = 'REJECTED_BY_CREDIT_BUREAU',
    INFOREQUESTED = 'INFO_REQUESTED'
}

/**
 * 
 * @export
 * @interface DesiredLoanCalculationParams
 */
export interface DesiredLoanCalculationParams {
    /**
     * 
     * @type {number}
     * @memberof DesiredLoanCalculationParams
     */
    amount: number;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof DesiredLoanCalculationParams
     */
    disclosure: LoanApplicationDisclosure;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof DesiredLoanCalculationParams
     */
    term: LoanApplicationTerm;
}
/**
 * 
 * @export
 * @interface DesiredLoanCalculationResponse
 */
export interface DesiredLoanCalculationResponse {
    /**
     * 
     * @type {number}
     * @memberof DesiredLoanCalculationResponse
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof DesiredLoanCalculationResponse
     */
    insuranceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof DesiredLoanCalculationResponse
     */
    interest?: number;
    /**
     * 
     * @type {number}
     * @memberof DesiredLoanCalculationResponse
     */
    monthlyPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof DesiredLoanCalculationResponse
     */
    principalDebt?: number;
    /**
     * 
     * @type {number}
     * @memberof DesiredLoanCalculationResponse
     */
    totalMonthlyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof DesiredLoanCalculationResponse
     */
    totalPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof DesiredLoanCalculationResponse
     */
    totalVatAmount?: number;
}
/**
 * 
 * @export
 * @interface DisbursementBankAccountInfo
 */
export interface DisbursementBankAccountInfo {
    /**
     * 
     * @type {number}
     * @memberof DisbursementBankAccountInfo
     */
    accountAllocationPercentage?: number;
    /**
     * 
     * @type {BankAccount}
     * @memberof DisbursementBankAccountInfo
     */
    bankAccount?: BankAccount;
    /**
     * 
     * @type {number}
     * @memberof DisbursementBankAccountInfo
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DisbursementBankAccountInfo
     */
    loanApplicationId?: number;
}
/**
 * 
 * @export
 * @interface DisbursementBankAccountInfoDTO
 */
export interface DisbursementBankAccountInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof DisbursementBankAccountInfoDTO
     */
    accountAllocationPercentage?: number;
    /**
     * 
     * @type {BankAccountDTO}
     * @memberof DisbursementBankAccountInfoDTO
     */
    bankAccount?: BankAccountDTO;
    /**
     * 
     * @type {number}
     * @memberof DisbursementBankAccountInfoDTO
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
    /**
     * 
     * @type {number}
     * @memberof DocumentDTO
     */
    documentId?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    documentType?: DocumentDTODocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    fileType?: DocumentDTOFileTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentDTODocumentTypeEnum {
    CUSTOMERCIPC = 'CUSTOMER_CIPC',
    CUSTOMERTAXCLEARANCE = 'CUSTOMER_TAX_CLEARANCE',
    CUSTOMERPROOFOFADDRESS = 'CUSTOMER_PROOF_OF_ADDRESS',
    CUSTOMERDIRECTORIDS = 'CUSTOMER_DIRECTOR_IDS',
    CUSTOMERCREDITBUREAU = 'CUSTOMER_CREDIT_BUREAU',
    CUSTOMERSOLVENCY = 'CUSTOMER_SOLVENCY',
    CUSTOMERAUTHORIZATIONLETTER = 'CUSTOMER_AUTHORIZATION_LETTER',
    LOANAPPLICATIONMAINCONTRACT = 'LOAN_APPLICATION_MAIN_CONTRACT',
    LOANAPPLICATIONDEBTORSCONTRACT = 'LOAN_APPLICATION_DEBTORS_CONTRACT',
    LOANAPPLICATIONFINANCIALSTATEMENT = 'LOAN_APPLICATION_FINANCIAL_STATEMENT',
    LOANAPPLICATIONPURCHASEORDER = 'LOAN_APPLICATION_PURCHASE_ORDER',
    LOANAPPLICATIONPURCHASEORDERCONTRACT = 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT',
    LOANAPPLICATIONPROFITANDLOSSBUDGET = 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET',
    LOANAPPLICATIONACCOUNTSAGING = 'LOAN_APPLICATION_ACCOUNTS_AGING',
    LOANAPPLICATIONTAXINVOICE = 'LOAN_APPLICATION_TAX_INVOICE',
    LOANAPPLICATIONINVOICECONTRACT = 'LOAN_APPLICATION_INVOICE_CONTRACT',
    LOANAPPLICATIONBANKSTATEMENT = 'LOAN_APPLICATION_BANK_STATEMENT',
    LOANAPPLICATIONTHIRDPARTYPROOFLETTER = 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER',
    LOANAPPLICATIONQUOTATION = 'LOAN_APPLICATION_QUOTATION'
}
/**
    * @export
    * @enum {string}
    */
export enum DocumentDTOFileTypeEnum {
    PDF = 'PDF',
    TXT = 'TXT',
    HTML = 'HTML',
    JPEG = 'JPEG',
    JPG = 'JPG',
    PNG = 'PNG',
    ZIP = 'ZIP',
    RAR = 'RAR',
    DOC = 'DOC',
    DOCX = 'DOCX',
    XLS = 'XLS',
    XLSX = 'XLSX',
    CSV = 'CSV',
    RTF = 'RTF',
    SVG = 'SVG',
    PPT = 'PPT',
    PPTX = 'PPTX'
}

/**
 * 
 * @export
 * @interface EntityModelLoanProductDTO
 */
export interface EntityModelLoanProductDTO {
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    insuranceRateMultiplier?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    interestRate?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    interestRateRestriction?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EntityModelLoanProductDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    monthlyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    penaltyInterestMultiplier?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelLoanProductDTO
     */
    productName?: string;
    /**
     * 
     * @type {LoanProductStatus}
     * @memberof EntityModelLoanProductDTO
     */
    status?: LoanProductStatus;
    /**
     * 
     * @type {number}
     * @memberof EntityModelLoanProductDTO
     */
    vatRate?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FeeRepaymentMethod {
    UPFRONTPAYMENT = 'UPFRONT_PAYMENT',
    DEDUCTFROMDISBURSEMENT = 'DEDUCT_FROM_DISBURSEMENT',
    ADDTOLOAN = 'ADD_TO_LOAN'
}

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject
     */
    files: Array<any>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {Array<any>}
     * @memberof InlineObject1
     */
    files: Array<any>;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    deprecation?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    href?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    hreflang?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    media?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    profile?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    rel?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface LoanApplication
 */
export interface LoanApplication {
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    agreementSigningDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    approvedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplication
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {BankAccount}
     * @memberof LoanApplication
     */
    bankAccount?: BankAccount;
    /**
     * 
     * @type {LoanProductCalculationData}
     * @memberof LoanApplication
     */
    calculationData?: LoanProductCalculationData;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    ccReviewer?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    ccReviewerId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    contrOffer?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    contractDocumentCustomerSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    contractDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    contractDocumentThirdPartySessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    createdAt?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof LoanApplication
     */
    createdBy?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    creditBureauCheckDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    creditCommitteeApprovalDate?: string;
    /**
     * 
     * @type {CustomerUser}
     * @memberof LoanApplication
     */
    customer?: CustomerUser;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    decliningDate?: string;
    /**
     * 
     * @type {Array<DisbursementBankAccountInfo>}
     * @memberof LoanApplication
     */
    disbursementBankAccountInfos?: Array<DisbursementBankAccountInfo>;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof LoanApplication
     */
    disclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {FeeRepaymentMethod}
     * @memberof LoanApplication
     */
    feeRepaymentMethod?: FeeRepaymentMethod;
    /**
     * 
     * @type {Array<LoanApplicationFinancedItem>}
     * @memberof LoanApplication
     */
    financedItems?: Array<LoanApplicationFinancedItem>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    financedPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    firstReviewer?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    firstReviewerId?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplication
     */
    holidayTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    lastModifiedAt?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof LoanApplication
     */
    lastModifiedBy?: UserInfo;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    loanProductId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    loansCreated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    offerAcceptanceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    precedentConditions?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    purchaseOrderDescription?: string;
    /**
     * 
     * @type {LoanApplicationRatio}
     * @memberof LoanApplication
     */
    ratio?: LoanApplicationRatio;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    requestNote?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    requestedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplication
     */
    requestedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    reviewApprovalDate?: string;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof LoanApplication
     */
    status?: LoanApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    submitDate?: string;
    /**
     * 
     * @type {LoanApplicationThirdParty}
     * @memberof LoanApplication
     */
    thirdParty?: LoanApplicationThirdParty;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof LoanApplication
     */
    type?: LoanApplicationType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanApplicationDisclosure {
    DISCLOSED = 'DISCLOSED',
    UNDISCLOSED = 'UNDISCLOSED'
}

/**
 * 
 * @export
 * @interface LoanApplicationFinancedItem
 */
export interface LoanApplicationFinancedItem {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItem
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItem
     */
    creditNote?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItem
     */
    itemNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItem
     */
    loanApplicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItem
     */
    maturityDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItem
     */
    originationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItem
     */
    totalAmount?: number;
}
/**
 * 
 * @export
 * @interface LoanApplicationFinancedItemDTO
 */
export interface LoanApplicationFinancedItemDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemDTO
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemDTO
     */
    creditNote?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItemDTO
     */
    itemNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItemDTO
     */
    maturityDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItemDTO
     */
    originationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemDTO
     */
    totalAmount?: number;
}
/**
 * 
 * @export
 * @interface LoanApplicationFinancedItemForm
 */
export interface LoanApplicationFinancedItemForm {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemForm
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationFinancedItemForm
     */
    creditNote: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItemForm
     */
    itemNumber: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItemForm
     */
    maturityDate: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationFinancedItemForm
     */
    originationDate: string;
}
/**
 * 
 * @export
 * @interface LoanApplicationForm
 */
export interface LoanApplicationForm {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationForm
     */
    bankAccountId: number;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof LoanApplicationForm
     */
    disclosure: LoanApplicationDisclosure;
    /**
     * 
     * @type {FeeRepaymentMethod}
     * @memberof LoanApplicationForm
     */
    feeRepaymentMethod: FeeRepaymentMethod;
    /**
     * 
     * @type {Array<LoanApplicationFinancedItemForm>}
     * @memberof LoanApplicationForm
     */
    financedItems: Array<LoanApplicationFinancedItemForm>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationForm
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    purchaseOrderDescription?: string;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationForm
     */
    requestedTerm: LoanApplicationTerm;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    thirdPartyAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    thirdPartyAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    thirdPartyAccountType?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    thirdPartyAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanApplicationForm
     */
    thirdPartyBankAccountFields?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    thirdPartyBankName?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    thirdPartyBranchCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    thirdPartyCipc?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    thirdPartyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationForm
     */
    thirdPartyLegalName?: string;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof LoanApplicationForm
     */
    type: LoanApplicationType;
}
/**
 * 
 * @export
 * @interface LoanApplicationListDTO
 */
export interface LoanApplicationListDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationListDTO
     */
    approvedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationListDTO
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListDTO
     */
    customerLegalName?: string;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof LoanApplicationListDTO
     */
    disclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationListDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationListDTO
     */
    requestedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationListDTO
     */
    requestedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof LoanApplicationListDTO
     */
    status?: LoanApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationListDTO
     */
    submitDate?: string;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof LoanApplicationListDTO
     */
    type?: LoanApplicationType;
}
/**
 * 
 * @export
 * @interface LoanApplicationOfferLetter
 */
export interface LoanApplicationOfferLetter {
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof LoanApplicationOfferLetter
     */
    applicationDisclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    applicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationOfferLetter
     */
    applicationSubmitDate?: string;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof LoanApplicationOfferLetter
     */
    applicationType?: LoanApplicationType;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    approvedAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationOfferLetter
     */
    approvedAmountInWords?: string;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationOfferLetter
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    creditCost?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationOfferLetter
     */
    customerCipc?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationOfferLetter
     */
    customerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationOfferLetter
     */
    customerFullAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationOfferLetter
     */
    customerLegalName?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationOfferLetter
     */
    customerPhoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    firstPaymentAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationOfferLetter
     */
    holidayTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    insuranceAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    insuranceRateMultiplierPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    interestAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    payableGrossAmount?: number;
    /**
     * 
     * @type {LoanApplication}
     * @memberof LoanApplicationOfferLetter
     */
    paymentAmounts?: LoanApplication;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    secondPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    thirdPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    totalCreditCost?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    totalInterestRatePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    totalMonthlyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    totalNumberOfPeriods?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    vatRateAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationOfferLetter
     */
    vatRatePercentage?: number;
}
/**
 * 
 * @export
 * @interface LoanApplicationQuarterFigures
 */
export interface LoanApplicationQuarterFigures {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationQuarterFigures
     */
    currentQuarter: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationQuarterFigures
     */
    previousQuarter: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationQuarterFigures
     */
    threeQuarterBefore?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationQuarterFigures
     */
    twoQuarterBefore?: number;
}
/**
 * 
 * @export
 * @interface LoanApplicationRatio
 */
export interface LoanApplicationRatio {
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    acidTestQuickRatio?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    creditorPaymentPeriod?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    currentRatio?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    debtorCollectionPeriod?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    ebidta?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    grossProfit?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    netProfit?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    operationProfit?: LoanApplicationRatioFeature;
    /**
     * 
     * @type {LoanApplicationRatioFeature}
     * @memberof LoanApplicationRatio
     */
    stockTurnoverPeriod?: LoanApplicationRatioFeature;
}
/**
 * 
 * @export
 * @interface LoanApplicationRatioFeature
 */
export interface LoanApplicationRatioFeature {
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof LoanApplicationRatioFeature
     */
    industryAverage?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof LoanApplicationRatioFeature
     */
    quarterChange?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof LoanApplicationRatioFeature
     */
    value?: LoanApplicationQuarterFigures;
    /**
     * 
     * @type {LoanApplicationQuarterFigures}
     * @memberof LoanApplicationRatioFeature
     */
    variance?: LoanApplicationQuarterFigures;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanApplicationStatus {
    INITIALWAITINGDOCUMENTS = 'INITIAL_WAITING_DOCUMENTS',
    CREDITBUREAUCHECKING = 'CREDIT_BUREAU_CHECKING',
    INITIALREVIEW = 'INITIAL_REVIEW',
    INFOREQUESTED = 'INFO_REQUESTED',
    CREDITCOMMITTEEREVIEW = 'CREDIT_COMMITTEE_REVIEW',
    OFFERISSUED = 'OFFER_ISSUED',
    FINALOFFERISSUED = 'FINAL_OFFER_ISSUED',
    SIGNINGPROCESS = 'SIGNING_PROCESS',
    SIGNED = 'SIGNED',
    EXPIRED = 'EXPIRED',
    DECLINEDBYREVIEWER = 'DECLINED_BY_REVIEWER',
    DECLINEDBYCREDITBUREAU = 'DECLINED_BY_CREDIT_BUREAU',
    DECLINEDBYCREDITCOMMITTEE = 'DECLINED_BY_CREDIT_COMMITTEE',
    DECLINEDBYCUSTOMER = 'DECLINED_BY_CUSTOMER'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanApplicationTerm {
    THIRTY = 'THIRTY',
    SIXTY = 'SIXTY',
    NINETY = 'NINETY'
}

/**
 * 
 * @export
 * @interface LoanApplicationThirdParty
 */
export interface LoanApplicationThirdParty {
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdParty
     */
    address?: string;
    /**
     * 
     * @type {BankAccount}
     * @memberof LoanApplicationThirdParty
     */
    bankAccount?: BankAccount;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdParty
     */
    cipc?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdParty
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdParty
     */
    legalName?: string;
}
/**
 * 
 * @export
 * @interface LoanApplicationThirdPartyDTO
 */
export interface LoanApplicationThirdPartyDTO {
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdPartyDTO
     */
    address?: string;
    /**
     * 
     * @type {BankAccountDTO}
     * @memberof LoanApplicationThirdPartyDTO
     */
    bankAccount?: BankAccountDTO;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdPartyDTO
     */
    cipc?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdPartyDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationThirdPartyDTO
     */
    legalName?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanApplicationType {
    INVOICEFINANCE = 'INVOICE_FINANCE',
    PURCHASEORDERFINANCE = 'PURCHASE_ORDER_FINANCE',
    FINANCEOFDEBTOR = 'FINANCE_OF_DEBTOR',
    OUTANDOUTINVOICEFINANCE = 'OUT_AND_OUT_INVOICE_FINANCE'
}

/**
 * 
 * @export
 * @interface LoanApplicationViewDTO
 */
export interface LoanApplicationViewDTO {
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    agreementSigningDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    approvedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationViewDTO
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {BankAccountDTO}
     * @memberof LoanApplicationViewDTO
     */
    bankAccount?: BankAccountDTO;
    /**
     * 
     * @type {LoanProductCalculationData}
     * @memberof LoanApplicationViewDTO
     */
    calculationData?: LoanProductCalculationData;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    ccReviewer?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    ccReviewerId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    contrOffer?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    creditBureauCheckDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    creditCommitteeApprovalDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    customerLegalName?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    decliningDate?: string;
    /**
     * 
     * @type {Array<DisbursementBankAccountInfoDTO>}
     * @memberof LoanApplicationViewDTO
     */
    disbursementBankAccountInfos?: Array<DisbursementBankAccountInfoDTO>;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof LoanApplicationViewDTO
     */
    disclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {FeeRepaymentMethod}
     * @memberof LoanApplicationViewDTO
     */
    feeRepaymentMethod?: FeeRepaymentMethod;
    /**
     * 
     * @type {Array<LoanApplicationFinancedItemDTO>}
     * @memberof LoanApplicationViewDTO
     */
    financedItems?: Array<LoanApplicationFinancedItemDTO>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    financedPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    firstReviewer?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    firstReviewerId?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationViewDTO
     */
    holidayTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    loanProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    loanProductName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationViewDTO
     */
    loansCreated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    offerAcceptanceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    precedentConditions?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    purchaseOrderDescription?: string;
    /**
     * 
     * @type {LoanApplicationRatio}
     * @memberof LoanApplicationViewDTO
     */
    ratio?: LoanApplicationRatio;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    requestNote?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationViewDTO
     */
    requestedAmount?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanApplicationViewDTO
     */
    requestedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    reviewApprovalDate?: string;
    /**
     * 
     * @type {LoanApplicationStatus}
     * @memberof LoanApplicationViewDTO
     */
    status?: LoanApplicationStatus;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationViewDTO
     */
    submitDate?: string;
    /**
     * 
     * @type {LoanApplicationThirdPartyDTO}
     * @memberof LoanApplicationViewDTO
     */
    thirdParty?: LoanApplicationThirdPartyDTO;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof LoanApplicationViewDTO
     */
    type?: LoanApplicationType;
}
/**
 * 
 * @export
 * @interface LoanProductCalculationData
 */
export interface LoanProductCalculationData {
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    insuranceRateMultiplierPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    interestRatePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    interestRateRestrictionPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    monthlyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    penaltyInterestMultiplier?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductCalculationData
     */
    vatRatePercentage?: number;
}
/**
 * 
 * @export
 * @interface LoanProductDTO
 */
export interface LoanProductDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    insuranceRateMultiplier?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    interestRate?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    interestRateRestriction?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    monthlyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    penaltyInterestMultiplier?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProductDTO
     */
    productName?: string;
    /**
     * 
     * @type {LoanProductStatus}
     * @memberof LoanProductDTO
     */
    status?: LoanProductStatus;
    /**
     * 
     * @type {number}
     * @memberof LoanProductDTO
     */
    vatRate?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanProductStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

/**
 * 
 * @export
 * @interface LoanScheduleDTO
 */
export interface LoanScheduleDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    initiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    insuranceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    interest?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    monthlyServiceFee?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanScheduleDTO
     */
    paymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    principal?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    vatInitiationFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    vatInsuranceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanScheduleDTO
     */
    vatMonthlyServiceFee?: number;
}
/**
 * 
 * @export
 * @interface LoanSnapshotDTO
 */
export interface LoanSnapshotDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    accruedInterestDue?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotDTO
     */
    calculationEvent?: LoanSnapshotDTOCalculationEventEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotDTO
     */
    dateTime?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    initiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    insuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    monthlyServiceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    overdueTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    penaltyInterest?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    principalDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    vatInitiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    vatInsuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotDTO
     */
    vatMonthlyServiceFeeDue?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LoanSnapshotDTOCalculationEventEnum {
    DISBURSEMENT = 'DISBURSEMENT',
    DAILYCALCULATION = 'DAILY_CALCULATION',
    REPAYMENT = 'REPAYMENT',
    WRITEOFF = 'WRITE_OFF'
}

/**
 * 
 * @export
 * @interface LoanViewDTO
 */
export interface LoanViewDTO {
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    accruedInterestDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    accruedInterestOverdue?: number;
    /**
     * 
     * @type {LoanApplicationTerm}
     * @memberof LoanViewDTO
     */
    approvedTerm?: LoanApplicationTerm;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    balance?: number;
    /**
     * 
     * @type {LoanProductCalculationData}
     * @memberof LoanViewDTO
     */
    calculationData?: LoanProductCalculationData;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    customerId?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    dailyPenaltyRatePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    daysOverdue?: number;
    /**
     * 
     * @type {LoanApplicationDisclosure}
     * @memberof LoanViewDTO
     */
    disclosure?: LoanApplicationDisclosure;
    /**
     * 
     * @type {FeeRepaymentMethod}
     * @memberof LoanViewDTO
     */
    feeRepaymentMethod?: FeeRepaymentMethod;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    financedItemTotalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    finishedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    initialAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    initiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    initiationFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    insuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    insuranceFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    interestDaily?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    latestPaymentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    legalName?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    loanApplicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    maturityDate?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    monthlyServiceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    monthlyServiceFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    penaltyInterest?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    principalDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    principalOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    productName?: string;
    /**
     * 
     * @type {Array<LoanScheduleDTO>}
     * @memberof LoanViewDTO
     */
    schedules?: Array<LoanScheduleDTO>;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    status?: LoanViewDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    thirdPartyCipc?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    thirdPartyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanViewDTO
     */
    thirdPartyLegalName?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    totalExposure?: number;
    /**
     * 
     * @type {LoanApplicationType}
     * @memberof LoanViewDTO
     */
    type?: LoanApplicationType;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatInitiationFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatInitiationFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatInsuranceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatInsuranceFeeOverdue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatMonthlyServiceFeeDue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanViewDTO
     */
    vatMonthlyServiceFeeOverdue?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LoanViewDTOStatusEnum {
    ACTIVE = 'ACTIVE',
    OVERDUE = 'OVERDUE',
    DEFAULT = 'DEFAULT',
    PAIDUP = 'PAID_UP',
    WRITTENOFF = 'WRITTEN_OFF'
}

/**
 * 
 * @export
 * @interface PageMetadata
 */
export interface PageMetadata {
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMetadata
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PagedModelEntityModelLoanProductDTO
 */
export interface PagedModelEntityModelLoanProductDTO {
    /**
     * 
     * @type {Array<EntityModelLoanProductDTO>}
     * @memberof PagedModelEntityModelLoanProductDTO
     */
    content?: Array<EntityModelLoanProductDTO>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PagedModelEntityModelLoanProductDTO
     */
    links?: Array<Link>;
    /**
     * 
     * @type {PageMetadata}
     * @memberof PagedModelEntityModelLoanProductDTO
     */
    page?: PageMetadata;
}
/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    instance?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Problem
     */
    parameters?: { [key: string]: object; };
    /**
     * 
     * @type {StatusType}
     * @memberof Problem
     */
    status?: StatusType;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface Province
 */
export interface Province {
    /**
     * 
     * @type {number}
     * @memberof Province
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ProvinceDTO
 */
export interface ProvinceDTO {
    /**
     * 
     * @type {number}
     * @memberof ProvinceDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProvinceDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface RequestedInformationForm
 */
export interface RequestedInformationForm {
    /**
     * 
     * @type {number}
     * @memberof RequestedInformationForm
     */
    bankAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof RequestedInformationForm
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestedInformationForm
     */
    thirdPartyAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestedInformationForm
     */
    thirdPartyAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestedInformationForm
     */
    thirdPartyAccountType?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestedInformationForm
     */
    thirdPartyAddress?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestedInformationForm
     */
    thirdPartyBankAccountFields?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RequestedInformationForm
     */
    thirdPartyBankName?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestedInformationForm
     */
    thirdPartyBranchCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestedInformationForm
     */
    thirdPartyCipc?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestedInformationForm
     */
    thirdPartyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestedInformationForm
     */
    thirdPartyLegalName?: string;
}
/**
 * 
 * @export
 * @interface StatusType
 */
export interface StatusType {
    /**
     * 
     * @type {string}
     * @memberof StatusType
     */
    reasonPhrase?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusType
     */
    statusCode?: number;
}
/**
 * 
 * @export
 * @interface ThrowableProblem
 */
export interface ThrowableProblem {
    /**
     * 
     * @type {ThrowableProblem}
     * @memberof ThrowableProblem
     */
    cause?: ThrowableProblem;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    instance?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    localizedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    message?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ThrowableProblem
     */
    parameters?: { [key: string]: object; };
    /**
     * 
     * @type {Array<ConstraintViolationProblemStackTrace>}
     * @memberof ThrowableProblem
     */
    stackTrace?: Array<ConstraintViolationProblemStackTrace>;
    /**
     * 
     * @type {StatusType}
     * @memberof ThrowableProblem
     */
    status?: StatusType;
    /**
     * 
     * @type {Array<ConstraintViolationProblemSuppressed>}
     * @memberof ThrowableProblem
     */
    suppressed?: Array<ConstraintViolationProblemSuppressed>;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ThrowableProblem
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface UiSettings
 */
export interface UiSettings {
    /**
     * 
     * @type {Authentication}
     * @memberof UiSettings
     */
    authentication?: Authentication;
}
/**
 * 
 * @export
 * @interface UpdateLoanProductForm
 */
export interface UpdateLoanProductForm {
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    initiationFee: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    insuranceRateMultiplier: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    interestRate: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    interestRateRestriction: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    monthlyFee: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    penaltyInterestMultiplier: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoanProductForm
     */
    productName: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoanProductForm
     */
    vatRate: number;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    type?: UserInfoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserInfoTypeEnum {
    BACKUSER = 'BACK_USER',
    CUSTOMER = 'CUSTOMER'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

/**
 * 
 * @export
 * @interface Violation
 */
export interface Violation {
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof Violation
     */
    message?: string;
}

/**
 * CustomerDashboardControllerApi - axios parameter creator
 * @export
 */
export const CustomerDashboardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate desired loan
         * @param {DesiredLoanCalculationParams} desiredLoanCalculationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateDesiredLoan: async (desiredLoanCalculationParams: DesiredLoanCalculationParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'desiredLoanCalculationParams' is not null or undefined
            if (desiredLoanCalculationParams === null || desiredLoanCalculationParams === undefined) {
                throw new RequiredError('desiredLoanCalculationParams','Required parameter desiredLoanCalculationParams was null or undefined when calling calculateDesiredLoan.');
            }
            const localVarPath = `/api/dashboard`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof desiredLoanCalculationParams !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(desiredLoanCalculationParams !== undefined ? desiredLoanCalculationParams : {}) : (desiredLoanCalculationParams || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer dashboard info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDashboard: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerDashboardControllerApi - functional programming interface
 * @export
 */
export const CustomerDashboardControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate desired loan
         * @param {DesiredLoanCalculationParams} desiredLoanCalculationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateDesiredLoan(desiredLoanCalculationParams: DesiredLoanCalculationParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyDesiredLoanCalculationResponse>> {
            const localVarAxiosArgs = await CustomerDashboardControllerApiAxiosParamCreator(configuration).calculateDesiredLoan(desiredLoanCalculationParams, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get customer dashboard info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerDashboard(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCustomerDashboardView>> {
            const localVarAxiosArgs = await CustomerDashboardControllerApiAxiosParamCreator(configuration).getCustomerDashboard(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerDashboardControllerApi - factory interface
 * @export
 */
export const CustomerDashboardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Calculate desired loan
         * @param {DesiredLoanCalculationParams} desiredLoanCalculationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateDesiredLoan(desiredLoanCalculationParams: DesiredLoanCalculationParams, options?: any): AxiosPromise<ApiResponseBodyDesiredLoanCalculationResponse> {
            return CustomerDashboardControllerApiFp(configuration).calculateDesiredLoan(desiredLoanCalculationParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer dashboard info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDashboard(options?: any): AxiosPromise<ApiResponseBodyCustomerDashboardView> {
            return CustomerDashboardControllerApiFp(configuration).getCustomerDashboard(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerDashboardControllerApi - object-oriented interface
 * @export
 * @class CustomerDashboardControllerApi
 * @extends {BaseAPI}
 */
export class CustomerDashboardControllerApi extends BaseAPI {
    /**
     * 
     * @summary Calculate desired loan
     * @param {DesiredLoanCalculationParams} desiredLoanCalculationParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDashboardControllerApi
     */
    public calculateDesiredLoan(desiredLoanCalculationParams: DesiredLoanCalculationParams, options?: any) {
        return CustomerDashboardControllerApiFp(this.configuration).calculateDesiredLoan(desiredLoanCalculationParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer dashboard info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerDashboardControllerApi
     */
    public getCustomerDashboard(options?: any) {
        return CustomerDashboardControllerApiFp(this.configuration).getCustomerDashboard(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CustomerLoanApplicationControllerApi - axios parameter creator
 * @export
 */
export const CustomerLoanApplicationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add contr offer and change loan application status to Credit Committee review and return dto
         * @param {number} id 
         * @param {ContrOfferForm} contrOfferForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContrOffer: async (id: number, contrOfferForm: ContrOfferForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addContrOffer.');
            }
            // verify required parameter 'contrOfferForm' is not null or undefined
            if (contrOfferForm === null || contrOfferForm === undefined) {
                throw new RequiredError('contrOfferForm','Required parameter contrOfferForm was null or undefined when calling addContrOffer.');
            }
            const localVarPath = `/api/application/{id}/contr-offer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof contrOfferForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(contrOfferForm !== undefined ? contrOfferForm : {}) : (contrOfferForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change loan application status to signing process
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOfferLetter: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling approveOfferLetter.');
            }
            const localVarPath = `/api/application/{id}/offer-letter/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelApplication: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancelApplication.');
            }
            const localVarPath = `/api/application/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Decline application contract
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningDecline: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling contractSigningDecline.');
            }
            const localVarPath = `/api/application/{applicationId}/contract/signing/decline`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download loan application contract mockup
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningDownload: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling contractSigningDownload.');
            }
            const localVarPath = `/api/application/{applicationId}/contract/signing/download`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get URL for contract signing IFrame
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningUrl: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling contractSigningUrl.');
            }
            const localVarPath = `/api/application/{applicationId}/contract/signing/url`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Decline offer-letter
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineOfferLetter: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling declineOfferLetter.');
            }
            const localVarPath = `/api/application/{id}/offer-letter/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer loan application list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList2: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/application`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer loan application by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView2: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView2.');
            }
            const localVarPath = `/api/application/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get application loan list
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoans: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling getLoans.');
            }
            const localVarPath = `/api/application/{applicationId}/loans`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offer letter data for application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferLetter: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOfferLetter.');
            }
            const localVarPath = `/api/application/{id}/offer-letter`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save info from apply for a loan form step 2
         * @param {LoanApplicationForm} loanApplicationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveStep21: async (loanApplicationForm: LoanApplicationForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanApplicationForm' is not null or undefined
            if (loanApplicationForm === null || loanApplicationForm === undefined) {
                throw new RequiredError('loanApplicationForm','Required parameter loanApplicationForm was null or undefined when calling saveStep21.');
            }
            const localVarPath = `/api/application/save-step-2`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loanApplicationForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loanApplicationForm !== undefined ? loanApplicationForm : {}) : (loanApplicationForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check uploaded documents and change loan application status to INITIAL_REVIEW
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToInitialReview: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling sendToInitialReview.');
            }
            const localVarPath = `/api/application/save-step-3`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update loan application
         * @param {LoanApplicationForm} loanApplicationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication: async (loanApplicationForm: LoanApplicationForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanApplicationForm' is not null or undefined
            if (loanApplicationForm === null || loanApplicationForm === undefined) {
                throw new RequiredError('loanApplicationForm','Required parameter loanApplicationForm was null or undefined when calling updateApplication.');
            }
            const localVarPath = `/api/application/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loanApplicationForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loanApplicationForm !== undefined ? loanApplicationForm : {}) : (loanApplicationForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update requested information in loan application 
         * @param {RequestedInformationForm} requestedInformationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRequestedInformation: async (requestedInformationForm: RequestedInformationForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestedInformationForm' is not null or undefined
            if (requestedInformationForm === null || requestedInformationForm === undefined) {
                throw new RequiredError('requestedInformationForm','Required parameter requestedInformationForm was null or undefined when calling updateRequestedInformation.');
            }
            const localVarPath = `/api/application/requested-information`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requestedInformationForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestedInformationForm !== undefined ? requestedInformationForm : {}) : (requestedInformationForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerLoanApplicationControllerApi - functional programming interface
 * @export
 */
export const CustomerLoanApplicationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add contr offer and change loan application status to Credit Committee review and return dto
         * @param {number} id 
         * @param {ContrOfferForm} contrOfferForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContrOffer(id: number, contrOfferForm: ContrOfferForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).addContrOffer(id, contrOfferForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change loan application status to signing process
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveOfferLetter(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBoolean>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).approveOfferLetter(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancel loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelApplication(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyVoid>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).cancelApplication(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Decline application contract
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractSigningDecline(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBoolean>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).contractSigningDecline(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download loan application contract mockup
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractSigningDownload(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).contractSigningDownload(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get URL for contract signing IFrame
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractSigningUrl(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyString>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).contractSigningUrl(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Decline offer-letter
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declineOfferLetter(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyBoolean>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).declineOfferLetter(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get customer loan application list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList2(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListLoanApplicationListDTO>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).entityList2(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get customer loan application by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView2(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).entityView2(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get application loan list
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoans(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListLoanViewDTO>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).getLoans(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get offer letter data for application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferLetter(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationOfferLetter>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).getOfferLetter(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save info from apply for a loan form step 2
         * @param {LoanApplicationForm} loanApplicationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveStep21(loanApplicationForm: LoanApplicationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).saveStep21(loanApplicationForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check uploaded documents and change loan application status to INITIAL_REVIEW
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendToInitialReview(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyVoid>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).sendToInitialReview(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update loan application
         * @param {LoanApplicationForm} loanApplicationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplication(loanApplicationForm: LoanApplicationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).updateApplication(loanApplicationForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update requested information in loan application 
         * @param {RequestedInformationForm} requestedInformationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRequestedInformation(requestedInformationForm: RequestedInformationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanApplicationViewDTO>> {
            const localVarAxiosArgs = await CustomerLoanApplicationControllerApiAxiosParamCreator(configuration).updateRequestedInformation(requestedInformationForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerLoanApplicationControllerApi - factory interface
 * @export
 */
export const CustomerLoanApplicationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add contr offer and change loan application status to Credit Committee review and return dto
         * @param {number} id 
         * @param {ContrOfferForm} contrOfferForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContrOffer(id: number, contrOfferForm: ContrOfferForm, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return CustomerLoanApplicationControllerApiFp(configuration).addContrOffer(id, contrOfferForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change loan application status to signing process
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOfferLetter(id: number, options?: any): AxiosPromise<ApiResponseBodyBoolean> {
            return CustomerLoanApplicationControllerApiFp(configuration).approveOfferLetter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelApplication(id: number, options?: any): AxiosPromise<ApiResponseBodyVoid> {
            return CustomerLoanApplicationControllerApiFp(configuration).cancelApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Decline application contract
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningDecline(applicationId: number, options?: any): AxiosPromise<ApiResponseBodyBoolean> {
            return CustomerLoanApplicationControllerApiFp(configuration).contractSigningDecline(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download loan application contract mockup
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningDownload(applicationId: number, options?: any): AxiosPromise<any> {
            return CustomerLoanApplicationControllerApiFp(configuration).contractSigningDownload(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get URL for contract signing IFrame
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractSigningUrl(applicationId: number, options?: any): AxiosPromise<ApiResponseBodyString> {
            return CustomerLoanApplicationControllerApiFp(configuration).contractSigningUrl(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Decline offer-letter
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineOfferLetter(id: number, options?: any): AxiosPromise<ApiResponseBodyBoolean> {
            return CustomerLoanApplicationControllerApiFp(configuration).declineOfferLetter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer loan application list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList2(options?: any): AxiosPromise<ApiResponseBodyListLoanApplicationListDTO> {
            return CustomerLoanApplicationControllerApiFp(configuration).entityList2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer loan application by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView2(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return CustomerLoanApplicationControllerApiFp(configuration).entityView2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get application loan list
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoans(applicationId: number, options?: any): AxiosPromise<ApiResponseBodyListLoanViewDTO> {
            return CustomerLoanApplicationControllerApiFp(configuration).getLoans(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offer letter data for application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferLetter(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationOfferLetter> {
            return CustomerLoanApplicationControllerApiFp(configuration).getOfferLetter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save info from apply for a loan form step 2
         * @param {LoanApplicationForm} loanApplicationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveStep21(loanApplicationForm: LoanApplicationForm, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return CustomerLoanApplicationControllerApiFp(configuration).saveStep21(loanApplicationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check uploaded documents and change loan application status to INITIAL_REVIEW
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendToInitialReview(applicationId: number, options?: any): AxiosPromise<ApiResponseBodyVoid> {
            return CustomerLoanApplicationControllerApiFp(configuration).sendToInitialReview(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update loan application
         * @param {LoanApplicationForm} loanApplicationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication(loanApplicationForm: LoanApplicationForm, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return CustomerLoanApplicationControllerApiFp(configuration).updateApplication(loanApplicationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update requested information in loan application 
         * @param {RequestedInformationForm} requestedInformationForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRequestedInformation(requestedInformationForm: RequestedInformationForm, options?: any): AxiosPromise<ApiResponseBodyLoanApplicationViewDTO> {
            return CustomerLoanApplicationControllerApiFp(configuration).updateRequestedInformation(requestedInformationForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerLoanApplicationControllerApi - object-oriented interface
 * @export
 * @class CustomerLoanApplicationControllerApi
 * @extends {BaseAPI}
 */
export class CustomerLoanApplicationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add contr offer and change loan application status to Credit Committee review and return dto
     * @param {number} id 
     * @param {ContrOfferForm} contrOfferForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public addContrOffer(id: number, contrOfferForm: ContrOfferForm, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).addContrOffer(id, contrOfferForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change loan application status to signing process
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public approveOfferLetter(id: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).approveOfferLetter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel loan application
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public cancelApplication(id: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).cancelApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Decline application contract
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public contractSigningDecline(applicationId: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).contractSigningDecline(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download loan application contract mockup
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public contractSigningDownload(applicationId: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).contractSigningDownload(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get URL for contract signing IFrame
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public contractSigningUrl(applicationId: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).contractSigningUrl(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Decline offer-letter
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public declineOfferLetter(id: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).declineOfferLetter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer loan application list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public entityList2(options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).entityList2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer loan application by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public entityView2(id: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).entityView2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get application loan list
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public getLoans(applicationId: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).getLoans(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offer letter data for application
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public getOfferLetter(id: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).getOfferLetter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save info from apply for a loan form step 2
     * @param {LoanApplicationForm} loanApplicationForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public saveStep21(loanApplicationForm: LoanApplicationForm, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).saveStep21(loanApplicationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check uploaded documents and change loan application status to INITIAL_REVIEW
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public sendToInitialReview(applicationId: number, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).sendToInitialReview(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update loan application
     * @param {LoanApplicationForm} loanApplicationForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public updateApplication(loanApplicationForm: LoanApplicationForm, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).updateApplication(loanApplicationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update requested information in loan application 
     * @param {RequestedInformationForm} requestedInformationForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanApplicationControllerApi
     */
    public updateRequestedInformation(requestedInformationForm: RequestedInformationForm, options?: any) {
        return CustomerLoanApplicationControllerApiFp(this.configuration).updateRequestedInformation(requestedInformationForm, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CustomerLoanControllerApi - axios parameter creator
 * @export
 */
export const CustomerLoanControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get customer loan list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer loan by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView.');
            }
            const localVarPath = `/api/loan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer loan snapshots
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotDTOList: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSnapshotDTOList.');
            }
            const localVarPath = `/api/loan/{id}/snapshots`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerLoanControllerApi - functional programming interface
 * @export
 */
export const CustomerLoanControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get customer loan list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListLoanViewDTO>> {
            const localVarAxiosArgs = await CustomerLoanControllerApiAxiosParamCreator(configuration).entityList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get customer loan by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanViewDTO>> {
            const localVarAxiosArgs = await CustomerLoanControllerApiAxiosParamCreator(configuration).entityView(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get customer loan snapshots
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSnapshotDTOList(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListLoanSnapshotDTO>> {
            const localVarAxiosArgs = await CustomerLoanControllerApiAxiosParamCreator(configuration).getSnapshotDTOList(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerLoanControllerApi - factory interface
 * @export
 */
export const CustomerLoanControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get customer loan list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList(options?: any): AxiosPromise<ApiResponseBodyListLoanViewDTO> {
            return CustomerLoanControllerApiFp(configuration).entityList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer loan by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanViewDTO> {
            return CustomerLoanControllerApiFp(configuration).entityView(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer loan snapshots
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSnapshotDTOList(id: number, options?: any): AxiosPromise<ApiResponseBodyListLoanSnapshotDTO> {
            return CustomerLoanControllerApiFp(configuration).getSnapshotDTOList(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerLoanControllerApi - object-oriented interface
 * @export
 * @class CustomerLoanControllerApi
 * @extends {BaseAPI}
 */
export class CustomerLoanControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get customer loan list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanControllerApi
     */
    public entityList(options?: any) {
        return CustomerLoanControllerApiFp(this.configuration).entityList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer loan by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanControllerApi
     */
    public entityView(id: number, options?: any) {
        return CustomerLoanControllerApiFp(this.configuration).entityView(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer loan snapshots
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerLoanControllerApi
     */
    public getSnapshotDTOList(id: number, options?: any) {
        return CustomerLoanControllerApiFp(this.configuration).getSnapshotDTOList(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CustomerUserControllerApi - axios parameter creator
 * @export
 */
export const CustomerUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new bank account for customer
         * @param {BankAccountDTO} bankAccountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankAccount: async (bankAccountDTO: BankAccountDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankAccountDTO' is not null or undefined
            if (bankAccountDTO === null || bankAccountDTO === undefined) {
                throw new RequiredError('bankAccountDTO','Required parameter bankAccountDTO was null or undefined when calling addBankAccount.');
            }
            const localVarPath = `/api/customer/bank-account/new`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof bankAccountDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bankAccountDTO !== undefined ? bankAccountDTO : {}) : (bankAccountDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get country list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/countries`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return customer profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileByKeycloakId: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get province list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvinces: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/provinces`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return dto for registration step 2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initStep2: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/registration/init-step-2`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save info from registration step 2 to customer
         * @param {CustomerUserDTO} customerUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveStep2: async (customerUserDTO: CustomerUserDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerUserDTO' is not null or undefined
            if (customerUserDTO === null || customerUserDTO === undefined) {
                throw new RequiredError('customerUserDTO','Required parameter customerUserDTO was null or undefined when calling saveStep2.');
            }
            const localVarPath = `/api/customer/registration/save-step-2`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof customerUserDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customerUserDTO !== undefined ? customerUserDTO : {}) : (customerUserDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check uploaded documents and start credit bureau checking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveStep3: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/registration/save-step-3`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer contact details
         * @param {CustomerContactDetailsDto} customerContactDetailsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactDetails: async (customerContactDetailsDto: CustomerContactDetailsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerContactDetailsDto' is not null or undefined
            if (customerContactDetailsDto === null || customerContactDetailsDto === undefined) {
                throw new RequiredError('customerContactDetailsDto','Required parameter customerContactDetailsDto was null or undefined when calling updateContactDetails.');
            }
            const localVarPath = `/api/customer/contact-details/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof customerContactDetailsDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(customerContactDetailsDto !== undefined ? customerContactDetailsDto : {}) : (customerContactDetailsDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerUserControllerApi - functional programming interface
 * @export
 */
export const CustomerUserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new bank account for customer
         * @param {BankAccountDTO} bankAccountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBankAccount(bankAccountDTO: BankAccountDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCustomerUserDTO>> {
            const localVarAxiosArgs = await CustomerUserControllerApiAxiosParamCreator(configuration).addBankAccount(bankAccountDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get country list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListCountryDTO>> {
            const localVarAxiosArgs = await CustomerUserControllerApiAxiosParamCreator(configuration).getCountries(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return customer profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileByKeycloakId(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCustomerUserDTO>> {
            const localVarAxiosArgs = await CustomerUserControllerApiAxiosParamCreator(configuration).getProfileByKeycloakId(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get province list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvinces(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListProvinceDTO>> {
            const localVarAxiosArgs = await CustomerUserControllerApiAxiosParamCreator(configuration).getProvinces(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return dto for registration step 2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initStep2(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCustomerUserDTO>> {
            const localVarAxiosArgs = await CustomerUserControllerApiAxiosParamCreator(configuration).initStep2(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save info from registration step 2 to customer
         * @param {CustomerUserDTO} customerUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveStep2(customerUserDTO: CustomerUserDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCustomerUserDTO>> {
            const localVarAxiosArgs = await CustomerUserControllerApiAxiosParamCreator(configuration).saveStep2(customerUserDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check uploaded documents and start credit bureau checking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveStep3(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerUserControllerApiAxiosParamCreator(configuration).saveStep3(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update customer contact details
         * @param {CustomerContactDetailsDto} customerContactDetailsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactDetails(customerContactDetailsDto: CustomerContactDetailsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyCustomerUserDTO>> {
            const localVarAxiosArgs = await CustomerUserControllerApiAxiosParamCreator(configuration).updateContactDetails(customerContactDetailsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerUserControllerApi - factory interface
 * @export
 */
export const CustomerUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add new bank account for customer
         * @param {BankAccountDTO} bankAccountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankAccount(bankAccountDTO: BankAccountDTO, options?: any): AxiosPromise<ApiResponseBodyCustomerUserDTO> {
            return CustomerUserControllerApiFp(configuration).addBankAccount(bankAccountDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get country list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: any): AxiosPromise<ApiResponseBodyListCountryDTO> {
            return CustomerUserControllerApiFp(configuration).getCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return customer profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileByKeycloakId(options?: any): AxiosPromise<ApiResponseBodyCustomerUserDTO> {
            return CustomerUserControllerApiFp(configuration).getProfileByKeycloakId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get province list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvinces(options?: any): AxiosPromise<ApiResponseBodyListProvinceDTO> {
            return CustomerUserControllerApiFp(configuration).getProvinces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return dto for registration step 2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initStep2(options?: any): AxiosPromise<ApiResponseBodyCustomerUserDTO> {
            return CustomerUserControllerApiFp(configuration).initStep2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save info from registration step 2 to customer
         * @param {CustomerUserDTO} customerUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveStep2(customerUserDTO: CustomerUserDTO, options?: any): AxiosPromise<ApiResponseBodyCustomerUserDTO> {
            return CustomerUserControllerApiFp(configuration).saveStep2(customerUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check uploaded documents and start credit bureau checking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveStep3(options?: any): AxiosPromise<void> {
            return CustomerUserControllerApiFp(configuration).saveStep3(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer contact details
         * @param {CustomerContactDetailsDto} customerContactDetailsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactDetails(customerContactDetailsDto: CustomerContactDetailsDto, options?: any): AxiosPromise<ApiResponseBodyCustomerUserDTO> {
            return CustomerUserControllerApiFp(configuration).updateContactDetails(customerContactDetailsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerUserControllerApi - object-oriented interface
 * @export
 * @class CustomerUserControllerApi
 * @extends {BaseAPI}
 */
export class CustomerUserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add new bank account for customer
     * @param {BankAccountDTO} bankAccountDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public addBankAccount(bankAccountDTO: BankAccountDTO, options?: any) {
        return CustomerUserControllerApiFp(this.configuration).addBankAccount(bankAccountDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get country list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public getCountries(options?: any) {
        return CustomerUserControllerApiFp(this.configuration).getCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return customer profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public getProfileByKeycloakId(options?: any) {
        return CustomerUserControllerApiFp(this.configuration).getProfileByKeycloakId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get province list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public getProvinces(options?: any) {
        return CustomerUserControllerApiFp(this.configuration).getProvinces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return dto for registration step 2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public initStep2(options?: any) {
        return CustomerUserControllerApiFp(this.configuration).initStep2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save info from registration step 2 to customer
     * @param {CustomerUserDTO} customerUserDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public saveStep2(customerUserDTO: CustomerUserDTO, options?: any) {
        return CustomerUserControllerApiFp(this.configuration).saveStep2(customerUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check uploaded documents and start credit bureau checking
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public saveStep3(options?: any) {
        return CustomerUserControllerApiFp(this.configuration).saveStep3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer contact details
     * @param {CustomerContactDetailsDto} customerContactDetailsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserControllerApi
     */
    public updateContactDetails(customerContactDetailsDto: CustomerContactDetailsDto, options?: any) {
        return CustomerUserControllerApiFp(this.configuration).updateContactDetails(customerContactDetailsDto, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CustomerUserDocumentControllerApi - axios parameter creator
 * @export
 */
export const CustomerUserDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download customer document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCustomerDocuments: async (documentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling downloadCustomerDocuments.');
            }
            const localVarPath = `/api/customer/document/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer expired document info dtos, if list dtos is empty customer doesn\'t have expired documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDocuments: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/document/expired`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return customer documents info for profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/document/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm customer documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAllRequireDocumentsUploaded: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/customer/document/confirm`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save documents
         * @param {'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION'} type 
         * @param {Array<any>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocuments: async (type: 'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION', files: Array<any>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling saveDocuments.');
            }
            // verify required parameter 'files' is not null or undefined
            if (files === null || files === undefined) {
                throw new RequiredError('files','Required parameter files was null or undefined when calling saveDocuments.');
            }
            const localVarPath = `/api/customer/document/save`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (files) {
            
                localVarFormParams.append('files', files.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerUserDocumentControllerApi - functional programming interface
 * @export
 */
export const CustomerUserDocumentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download customer document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCustomerDocuments(documentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await CustomerUserDocumentControllerApiAxiosParamCreator(configuration).downloadCustomerDocuments(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get customer expired document info dtos, if list dtos is empty customer doesn\'t have expired documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerDocuments(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListDocumentDTO>> {
            const localVarAxiosArgs = await CustomerUserDocumentControllerApiAxiosParamCreator(configuration).getCustomerDocuments(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Return customer documents info for profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListDocumentDTO>> {
            const localVarAxiosArgs = await CustomerUserDocumentControllerApiAxiosParamCreator(configuration).getForProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Confirm customer documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isAllRequireDocumentsUploaded(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerUserDocumentControllerApiAxiosParamCreator(configuration).isAllRequireDocumentsUploaded(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save documents
         * @param {'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION'} type 
         * @param {Array<any>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDocuments(type: 'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION', files: Array<any>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyString>> {
            const localVarAxiosArgs = await CustomerUserDocumentControllerApiAxiosParamCreator(configuration).saveDocuments(type, files, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerUserDocumentControllerApi - factory interface
 * @export
 */
export const CustomerUserDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download customer document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCustomerDocuments(documentId: number, options?: any): AxiosPromise<any> {
            return CustomerUserDocumentControllerApiFp(configuration).downloadCustomerDocuments(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer expired document info dtos, if list dtos is empty customer doesn\'t have expired documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerDocuments(options?: any): AxiosPromise<ApiResponseBodyListDocumentDTO> {
            return CustomerUserDocumentControllerApiFp(configuration).getCustomerDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return customer documents info for profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForProfile(options?: any): AxiosPromise<ApiResponseBodyListDocumentDTO> {
            return CustomerUserDocumentControllerApiFp(configuration).getForProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm customer documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAllRequireDocumentsUploaded(options?: any): AxiosPromise<void> {
            return CustomerUserDocumentControllerApiFp(configuration).isAllRequireDocumentsUploaded(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save documents
         * @param {'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION'} type 
         * @param {Array<any>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocuments(type: 'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION', files: Array<any>, options?: any): AxiosPromise<ApiResponseBodyString> {
            return CustomerUserDocumentControllerApiFp(configuration).saveDocuments(type, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerUserDocumentControllerApi - object-oriented interface
 * @export
 * @class CustomerUserDocumentControllerApi
 * @extends {BaseAPI}
 */
export class CustomerUserDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Download customer document
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserDocumentControllerApi
     */
    public downloadCustomerDocuments(documentId: number, options?: any) {
        return CustomerUserDocumentControllerApiFp(this.configuration).downloadCustomerDocuments(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer expired document info dtos, if list dtos is empty customer doesn\'t have expired documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserDocumentControllerApi
     */
    public getCustomerDocuments(options?: any) {
        return CustomerUserDocumentControllerApiFp(this.configuration).getCustomerDocuments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return customer documents info for profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserDocumentControllerApi
     */
    public getForProfile(options?: any) {
        return CustomerUserDocumentControllerApiFp(this.configuration).getForProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm customer documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserDocumentControllerApi
     */
    public isAllRequireDocumentsUploaded(options?: any) {
        return CustomerUserDocumentControllerApiFp(this.configuration).isAllRequireDocumentsUploaded(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save documents
     * @param {'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION'} type 
     * @param {Array<any>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerUserDocumentControllerApi
     */
    public saveDocuments(type: 'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION', files: Array<any>, options?: any) {
        return CustomerUserDocumentControllerApiFp(this.configuration).saveDocuments(type, files, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanApplicationDocumentControllerApi - axios parameter creator
 * @export
 */
export const LoanApplicationDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download loan application document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument: async (documentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling downloadDocument.');
            }
            const localVarPath = `/api/application/document/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get documents for loan application
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentForApplication: async (applicationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling getDocumentForApplication.');
            }
            const localVarPath = `/api/application/document`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save documents
         * @param {number} applicationId 
         * @param {'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION'} type 
         * @param {Array<any>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocuments1: async (applicationId: number, type: 'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION', files: Array<any>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling saveDocuments1.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling saveDocuments1.');
            }
            // verify required parameter 'files' is not null or undefined
            if (files === null || files === undefined) {
                throw new RequiredError('files','Required parameter files was null or undefined when calling saveDocuments1.');
            }
            const localVarPath = `/api/application/document/save`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (files) {
            
                localVarFormParams.append('files', files.join(COLLECTION_FORMATS.csv));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanApplicationDocumentControllerApi - functional programming interface
 * @export
 */
export const LoanApplicationDocumentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download loan application document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDocument(documentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await LoanApplicationDocumentControllerApiAxiosParamCreator(configuration).downloadDocument(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get documents for loan application
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentForApplication(applicationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyListDocumentDTO>> {
            const localVarAxiosArgs = await LoanApplicationDocumentControllerApiAxiosParamCreator(configuration).getDocumentForApplication(applicationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save documents
         * @param {number} applicationId 
         * @param {'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION'} type 
         * @param {Array<any>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDocuments1(applicationId: number, type: 'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION', files: Array<any>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyString>> {
            const localVarAxiosArgs = await LoanApplicationDocumentControllerApiAxiosParamCreator(configuration).saveDocuments1(applicationId, type, files, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanApplicationDocumentControllerApi - factory interface
 * @export
 */
export const LoanApplicationDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download loan application document
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument(documentId: number, options?: any): AxiosPromise<any> {
            return LoanApplicationDocumentControllerApiFp(configuration).downloadDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get documents for loan application
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentForApplication(applicationId: number, options?: any): AxiosPromise<ApiResponseBodyListDocumentDTO> {
            return LoanApplicationDocumentControllerApiFp(configuration).getDocumentForApplication(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save documents
         * @param {number} applicationId 
         * @param {'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION'} type 
         * @param {Array<any>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDocuments1(applicationId: number, type: 'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION', files: Array<any>, options?: any): AxiosPromise<ApiResponseBodyString> {
            return LoanApplicationDocumentControllerApiFp(configuration).saveDocuments1(applicationId, type, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanApplicationDocumentControllerApi - object-oriented interface
 * @export
 * @class LoanApplicationDocumentControllerApi
 * @extends {BaseAPI}
 */
export class LoanApplicationDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Download loan application document
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationDocumentControllerApi
     */
    public downloadDocument(documentId: number, options?: any) {
        return LoanApplicationDocumentControllerApiFp(this.configuration).downloadDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get documents for loan application
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationDocumentControllerApi
     */
    public getDocumentForApplication(applicationId: number, options?: any) {
        return LoanApplicationDocumentControllerApiFp(this.configuration).getDocumentForApplication(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save documents
     * @param {number} applicationId 
     * @param {'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION'} type 
     * @param {Array<any>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationDocumentControllerApi
     */
    public saveDocuments1(applicationId: number, type: 'CUSTOMER_CIPC' | 'CUSTOMER_TAX_CLEARANCE' | 'CUSTOMER_PROOF_OF_ADDRESS' | 'CUSTOMER_DIRECTOR_IDS' | 'CUSTOMER_CREDIT_BUREAU' | 'CUSTOMER_SOLVENCY' | 'CUSTOMER_AUTHORIZATION_LETTER' | 'LOAN_APPLICATION_MAIN_CONTRACT' | 'LOAN_APPLICATION_DEBTORS_CONTRACT' | 'LOAN_APPLICATION_FINANCIAL_STATEMENT' | 'LOAN_APPLICATION_PURCHASE_ORDER' | 'LOAN_APPLICATION_PURCHASE_ORDER_CONTRACT' | 'LOAN_APPLICATION_PROFIT_AND_LOSS_BUDGET' | 'LOAN_APPLICATION_ACCOUNTS_AGING' | 'LOAN_APPLICATION_TAX_INVOICE' | 'LOAN_APPLICATION_INVOICE_CONTRACT' | 'LOAN_APPLICATION_BANK_STATEMENT' | 'LOAN_APPLICATION_THIRD_PARTY_PROOF_LETTER' | 'LOAN_APPLICATION_QUOTATION', files: Array<any>, options?: any) {
        return LoanApplicationDocumentControllerApiFp(this.configuration).saveDocuments1(applicationId, type, files, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LoanProductControllerApi - axios parameter creator
 * @export
 */
export const LoanProductControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change loan product status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling activate.');
            }
            const localVarPath = `/api/loan/product/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new loan product
         * @param {CreateLoanProductForm} createLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLoanProduct: async (createLoanProductForm: CreateLoanProductForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLoanProductForm' is not null or undefined
            if (createLoanProductForm === null || createLoanProductForm === undefined) {
                throw new RequiredError('createLoanProductForm','Required parameter createLoanProductForm was null or undefined when calling createLoanProduct.');
            }
            const localVarPath = `/api/loan/product/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createLoanProductForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createLoanProductForm !== undefined ? createLoanProductForm : {}) : (createLoanProductForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change loan product status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deactivate.');
            }
            const localVarPath = `/api/loan/product/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {Array<string>} [productNames] 
         * @param {Array<LoanProductStatus>} [statuses] 
         * @param {number} [interestRateFrom] 
         * @param {number} [interestRateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList1: async (id?: string, productNames?: Array<string>, statuses?: Array<LoanProductStatus>, interestRateFrom?: number, interestRateTo?: number, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan/product`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (productNames) {
                localVarQueryParameter['productNames'] = productNames;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (interestRateFrom !== undefined) {
                localVarQueryParameter['interestRateFrom'] = interestRateFrom;
            }

            if (interestRateTo !== undefined) {
                localVarQueryParameter['interestRateTo'] = interestRateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (countDistinct !== undefined) {
                localVarQueryParameter['countDistinct'] = countDistinct;
            }

            if (display !== undefined) {
                localVarQueryParameter['display'] = display;
            }

            if (sortFields) {
                localVarQueryParameter['sortFields'] = sortFields;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling entityView1.');
            }
            const localVarPath = `/api/loan/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get map with all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMap: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan/product/all-product-map`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update loan product
         * @param {UpdateLoanProductForm} updateLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanProduct: async (updateLoanProductForm: UpdateLoanProductForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLoanProductForm' is not null or undefined
            if (updateLoanProductForm === null || updateLoanProductForm === undefined) {
                throw new RequiredError('updateLoanProductForm','Required parameter updateLoanProductForm was null or undefined when calling updateLoanProduct.');
            }
            const localVarPath = `/api/loan/product/update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateLoanProductForm !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLoanProductForm !== undefined ? updateLoanProductForm : {}) : (updateLoanProductForm || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanProductControllerApi - functional programming interface
 * @export
 */
export const LoanProductControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change loan product status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).activate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create new loan product
         * @param {CreateLoanProductForm} createLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLoanProduct(createLoanProductForm: CreateLoanProductForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).createLoanProduct(createLoanProductForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change loan product status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).deactivate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {Array<string>} [productNames] 
         * @param {Array<LoanProductStatus>} [statuses] 
         * @param {number} [interestRateFrom] 
         * @param {number} [interestRateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityList1(id?: string, productNames?: Array<string>, statuses?: Array<LoanProductStatus>, interestRateFrom?: number, interestRateTo?: number, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelEntityModelLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).entityList1(id, productNames, statuses, interestRateFrom, interestRateTo, page, count, countDistinct, display, sortFields, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entityView1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).entityView1(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get map with all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductMap(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyMapLongString>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).getProductMap(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update loan product
         * @param {UpdateLoanProductForm} updateLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoanProduct(updateLoanProductForm: UpdateLoanProductForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBodyLoanProductDTO>> {
            const localVarAxiosArgs = await LoanProductControllerApiAxiosParamCreator(configuration).updateLoanProduct(updateLoanProductForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanProductControllerApi - factory interface
 * @export
 */
export const LoanProductControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Change loan product status to Active
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).activate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new loan product
         * @param {CreateLoanProductForm} createLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLoanProduct(createLoanProductForm: CreateLoanProductForm, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).createLoanProduct(createLoanProductForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change loan product status to Inactive
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivate(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).deactivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable list of entities
         * @param {string} [id] 
         * @param {Array<string>} [productNames] 
         * @param {Array<LoanProductStatus>} [statuses] 
         * @param {number} [interestRateFrom] 
         * @param {number} [interestRateTo] 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [countDistinct] 
         * @param {boolean} [display] 
         * @param {Array<string>} [sortFields] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityList1(id?: string, productNames?: Array<string>, statuses?: Array<LoanProductStatus>, interestRateFrom?: number, interestRateTo?: number, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any): AxiosPromise<PagedModelEntityModelLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).entityList1(id, productNames, statuses, interestRateFrom, interestRateTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get entity by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityView1(id: number, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).entityView1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get map with all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMap(options?: any): AxiosPromise<ApiResponseBodyMapLongString> {
            return LoanProductControllerApiFp(configuration).getProductMap(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update loan product
         * @param {UpdateLoanProductForm} updateLoanProductForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanProduct(updateLoanProductForm: UpdateLoanProductForm, options?: any): AxiosPromise<ApiResponseBodyLoanProductDTO> {
            return LoanProductControllerApiFp(configuration).updateLoanProduct(updateLoanProductForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanProductControllerApi - object-oriented interface
 * @export
 * @class LoanProductControllerApi
 * @extends {BaseAPI}
 */
export class LoanProductControllerApi extends BaseAPI {
    /**
     * 
     * @summary Change loan product status to Active
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public activate(id: number, options?: any) {
        return LoanProductControllerApiFp(this.configuration).activate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new loan product
     * @param {CreateLoanProductForm} createLoanProductForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public createLoanProduct(createLoanProductForm: CreateLoanProductForm, options?: any) {
        return LoanProductControllerApiFp(this.configuration).createLoanProduct(createLoanProductForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change loan product status to Inactive
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public deactivate(id: number, options?: any) {
        return LoanProductControllerApiFp(this.configuration).deactivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pageable list of entities
     * @param {string} [id] 
     * @param {Array<string>} [productNames] 
     * @param {Array<LoanProductStatus>} [statuses] 
     * @param {number} [interestRateFrom] 
     * @param {number} [interestRateTo] 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [countDistinct] 
     * @param {boolean} [display] 
     * @param {Array<string>} [sortFields] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public entityList1(id?: string, productNames?: Array<string>, statuses?: Array<LoanProductStatus>, interestRateFrom?: number, interestRateTo?: number, page?: number, count?: number, countDistinct?: boolean, display?: boolean, sortFields?: Array<string>, sortOrder?: string, options?: any) {
        return LoanProductControllerApiFp(this.configuration).entityList1(id, productNames, statuses, interestRateFrom, interestRateTo, page, count, countDistinct, display, sortFields, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get entity by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public entityView1(id: number, options?: any) {
        return LoanProductControllerApiFp(this.configuration).entityView1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get map with all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public getProductMap(options?: any) {
        return LoanProductControllerApiFp(this.configuration).getProductMap(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update loan product
     * @param {UpdateLoanProductForm} updateLoanProductForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductControllerApi
     */
    public updateLoanProduct(updateLoanProductForm: UpdateLoanProductForm, options?: any) {
        return LoanProductControllerApiFp(this.configuration).updateLoanProduct(updateLoanProductForm, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SqsMessageTestControllerApi - axios parameter creator
 * @export
 */
export const SqsMessageTestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} queueName 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToQueue: async (queueName: string, body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueName' is not null or undefined
            if (queueName === null || queueName === undefined) {
                throw new RequiredError('queueName','Required parameter queueName was null or undefined when calling sendMessageToQueue.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendMessageToQueue.');
            }
            const localVarPath = `/api/test/sqs/send`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (queueName !== undefined) {
                localVarQueryParameter['queueName'] = queueName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SqsMessageTestControllerApi - functional programming interface
 * @export
 */
export const SqsMessageTestControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} queueName 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessageToQueue(queueName: string, body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SqsMessageTestControllerApiAxiosParamCreator(configuration).sendMessageToQueue(queueName, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SqsMessageTestControllerApi - factory interface
 * @export
 */
export const SqsMessageTestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} queueName 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToQueue(queueName: string, body: object, options?: any): AxiosPromise<void> {
            return SqsMessageTestControllerApiFp(configuration).sendMessageToQueue(queueName, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SqsMessageTestControllerApi - object-oriented interface
 * @export
 * @class SqsMessageTestControllerApi
 * @extends {BaseAPI}
 */
export class SqsMessageTestControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} queueName 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqsMessageTestControllerApi
     */
    public sendMessageToQueue(queueName: string, body: object, options?: any) {
        return SqsMessageTestControllerApiFp(this.configuration).sendMessageToQueue(queueName, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UiSettingsControllerApi - axios parameter creator
 * @export
 */
export const UiSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ui-settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UiSettingsControllerApi - functional programming interface
 * @export
 */
export const UiSettingsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiSettings>> {
            const localVarAxiosArgs = await UiSettingsControllerApiAxiosParamCreator(configuration).getSettings(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UiSettingsControllerApi - factory interface
 * @export
 */
export const UiSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<UiSettings> {
            return UiSettingsControllerApiFp(configuration).getSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UiSettingsControllerApi - object-oriented interface
 * @export
 * @class UiSettingsControllerApi
 * @extends {BaseAPI}
 */
export class UiSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UiSettingsControllerApi
     */
    public getSettings(options?: any) {
        return UiSettingsControllerApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

}


