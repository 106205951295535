import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Card } from 'src/components/Card/Card';
import { DataView } from 'src/components/DataView/DataView';
import { AddNewBankAccountDialog } from 'src/pages/ProfilePage/components/AddNewBankAccountDialog';
import { CompanyBankAccounts } from 'src/pages/ProfilePage/components/CompanyBankAccount';
import { EditContactDetailsDialog } from 'src/pages/ProfilePage/components/EditContactDetailsDialog';
import { CompanyDocuments } from 'src/pages/ProfilePage/components/СompanyDocuments';
import { CustomerVerificationStatusView } from 'src/pages/ProfilePage/CustomerVerificationStatusView';
import { CustomerDocumentsStore } from 'src/stores/CustomerDocumentsStore/CustomerDocumentsStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

export const ProfilePage = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        CustomerDocumentsStore.loadList();

        return () => {
            CustomerDocumentsStore.listLoader.reset();
        };
    }, []);

    return (
        <BasePage
            title={
                <Row align="middle" justify="space-between">
                    <Col>{t('Page.Profile.Title')}</Col>
                    <StyledCol>
                        <StyledButton
                            type="primary"
                            onClick={() => EditContactDetailsDialog.render()}
                        >
                            {t('Page.Profile.Edit', 'Edit')}
                        </StyledButton>
                        <CustomerVerificationStatusView
                            status={ProfileStore.user?.verificationStatus}
                        />
                    </StyledCol>
                </Row>
            }
        >
            <Card title={t('Page.Profile.CompanyDetails.Header')}>
                <DataView
                    label={t('Page.Profile.CompanyDetails.LegalName')}
                    value={ProfileStore.user?.legalName}
                />
                <DataView
                    label={t('Page.Profile.CompanyDetails.CIPC')}
                    value={ProfileStore.user?.cipc}
                />
                <DataView
                    label={t('Page.Profile.CompanyDetails.TaxId')}
                    value={ProfileStore.user?.taxId}
                />
                <DataView
                    label={t('Page.Profile.CompanyDetails.PhoneNumber')}
                    value={ProfileStore.user?.contactDetails.phoneNumber}
                />
                <DataView
                    label={t('Page.Profile.CompanyDetails.Email')}
                    value={ProfileStore.user?.contactDetails.email}
                />
            </Card>

            <Card title={t('Page.Profile.Address.Header')}>
                <DataView
                    label={t('Page.Profile.Address.COUNTRY')}
                    value={
                        ProfileStore.user?.contactDetails?.address?.country
                            ?.name
                    }
                />
                <DataView
                    label={t('Page.Profile.Address.City')}
                    value={ProfileStore.user?.contactDetails.address.city}
                />
                <DataView
                    label={t('Page.Profile.Address.Province')}
                    value={
                        ProfileStore.user?.contactDetails?.address?.province
                            ?.name
                    }
                />
                <DataView
                    label={t('Page.Profile.Address.Street')}
                    value={ProfileStore.user?.contactDetails?.address?.street}
                />
                <DataView
                    label={t('Page.Profile.Address.House')}
                    value={ProfileStore.user?.contactDetails?.address?.house}
                />
                <DataView
                    label={t('Page.Profile.Address.BuildingName')}
                    value={
                        ProfileStore.user?.contactDetails?.address?.buildingName
                    }
                />
                <DataView
                    label={t('Page.Profile.Address.PostalCode')}
                    value={
                        ProfileStore.user?.contactDetails?.address?.postalCode
                    }
                />
            </Card>

            <Card title={t('Page.Profile.RepresentativePerson.Header')}>
                <DataView
                    label={t('Page.Profile.RepresentativePerson.FullName')}
                    value={
                        ProfileStore.user?.contactDetails
                            ?.representativeFullName
                    }
                />
                <DataView
                    label={t('Page.Profile.RepresentativePerson.Phone')}
                    value={
                        ProfileStore.user?.contactDetails
                            ?.representativePhoneNumber
                    }
                />
                <DataView
                    label={t('Page.Profile.RepresentativePerson.Email')}
                    value={
                        ProfileStore.user?.contactDetails?.representativeEmail
                    }
                />
            </Card>

            <Card title={t('Page.Profile.СompanyDocuments.Header')}>
                <CompanyDocuments />
            </Card>

            <Card
                columns="1"
                title={t('Page.Profile.CompanyBankAccounts.Header')}
                extra={
                    <StyledButton
                        type="primary"
                        onClick={() => AddNewBankAccountDialog.render()}
                    >
                        {t('Page.Profile.CompanyBankAccounts.AddNew')}
                    </StyledButton>
                }
            >
                <CompanyBankAccounts />
            </Card>
        </BasePage>
    );
});

const StyledButton = styled(Button)`
    background-color: #000000;
    color: #ffffff;
    border-radius: 40px;
    border: none;
    &:active,
    &:focus {
        background-color: #0000007c;
    }
    &:hover {
        background-color: #0000007c;
    }
`;

const StyledCol = styled(Col)`
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    column-gap: 10px;
`;
