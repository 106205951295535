import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import { Logo } from 'src/components/Logo';
import { UserMenu } from 'src/components/UserMenu';
import { CssVariables } from 'src/styles/CssVariables';
import styled from 'styled-components';

export const RegistrationsPagesHeader = observer(() => {
    return (
        <StyledHeader>
            <StyledAppHeader>
                <Logo />

                <UserMenu />
            </StyledAppHeader>
        </StyledHeader>
    );
});

const StyledAppHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledHeader = styled(Layout.Header)`
    padding: 0 29px;
    height: ${CssVariables.headerHeight};
    background-color: #ffffff;
    line-height: ${CssVariables.headerLineHeight};
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
`;
