import { Button, Col, Form as AntForm, Row } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Form } from 'src/components/Form/Form';
import { TextField } from 'src/components/Form/TextField';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { DealTypeFormItem } from 'src/pages/ApplyLoanPage/Components/DealTypeFormItem';
import { DisclosureFormItem } from 'src/pages/ApplyLoanPage/Components/DisclosureFormItem';
import { FinancedItemsFormItem } from 'src/pages/ApplyLoanPage/Components/FinancedItemsFormItem';
import { InvoiceItemsFormItem } from 'src/pages/ApplyLoanPage/Components/InvoiceItemsFormItem';
import {
    ApplyForALoanStore,
    LoanStepId,
} from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';
import styled from 'styled-components';

const store = ApplyForALoanStore;

export const FirstStep = observer(() => {
    const [form] = AntForm.useForm();
    const applicationId = new URLSearchParams(
        Router.getQuerySearchParams(),
    ).get('applicationId');

    const currentApplication = store.initialApplicationData;

    useEffect(() => {
        if (applicationId && !currentApplication?.id) {
            store.loadItem(applicationId as string);
        }
    }, []);

    const cancelForm = currentApplication?.id
        ? () => {
              store.cancelApplicationItem(Number(applicationId) as number);
              store.resetFormApplication();
          }
        : () => {
              form.resetFields();
              store.resetFormApplication();
              Router.navigate('/');
          };

    form.setFieldsValue(currentApplication);

    return (
        <Form
            form={form}
            initialValues={{
                ...currentApplication,
            }}
            onFinish={(values) => {
                currentApplication?.id
                    ? store.setFormLoanApplicationData({
                          ...values,
                          id: currentApplication.id,
                      })
                    : store.setFormLoanApplicationData(values);
                currentApplication?.id
                    ? Router.navigate(
                          RoutePaths.editApplication(
                              LoanStepId.SECOND,
                              currentApplication.id.toString(),
                          ),
                      )
                    : Router.navigate(
                          `${RoutePaths.applyForALoan}/${LoanStepId.SECOND}`,
                      );
            }}
        >
            <TextField name="id" hidden />
            <TextField name="bankAccountId" hidden />
            {currentApplication?.id && (
                <TextField name="feeRepaymentMethod" hidden />
            )}

            <DealTypeFormItem form={form} />
            <FinancedItemsFormItem />
            <InvoiceItemsFormItem form={form} />
            <DisclosureFormItem form={form} />

            <Row gutter={24} justify="center">
                <StyledButtonContainer span={12}>
                    <StyledResetButton key="cancel" onClick={cancelForm}>
                        {t('Button.Cancel')}
                    </StyledResetButton>

                    <StyledSaveButton
                        key="ok"
                        type="primary"
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        {t('Button.Next')}
                    </StyledSaveButton>
                </StyledButtonContainer>
            </Row>
        </Form>
    );
});

const StyledButtonContainer = styled(Col)`
    margin-top: 20px;
    text-align: center;
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;
