import { Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { Env } from 'src/core/Environment';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AppUser } from 'src/stores/AppUserStore/AppUserStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import styled from 'styled-components';

const MenuComponent = () => {
    const registrationPagesRoutes =
        RoutePaths.signupSecondStep || RoutePaths.signupThirdStep;

    const { pathname } = useLocation();

    const currentPageRoute = `${Env.basePath}${pathname}`;

    const isRegistrationPage = currentPageRoute === registrationPagesRoutes;

    return (
        <Menu>
            {!isRegistrationPage && (
                <>
                    <Menu.Item
                        onClick={() => {
                            Router.navigate(RoutePaths.profile);
                        }}
                    >
                        Profile
                    </Menu.Item>
                    <Menu.Divider />
                </>
            )}
            <Menu.Item
                onClick={() => {
                    AuthStore.logout(`${window.location.origin}/`);
                }}
            >
                Log out
            </Menu.Item>
        </Menu>
    );
};

export const UserMenu = observer(() => {
    return (
        <Dropdown overlay={() => <MenuComponent />} placement="bottomRight">
            <StyledUserName>
                <StyledAvatar>
                    {AppUser.userProfile?.username?.[0]}
                </StyledAvatar>
            </StyledUserName>
        </Dropdown>
    );
});

const StyledUserName = styled.div`
    display: flex;
    align-items: center;
`;

const StyledAvatar = styled.div`
    display: inline-flex;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2f333a;
    margin-right: 5px;
    text-transform: uppercase;
    font-weight: 700;
    border: 3px solid #222325;
    color: #ffffff;
`;
