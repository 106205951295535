import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from 'src/components/Form/TextArea';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { Modal } from 'src/components/Modal/Modal';
import { ApplicationStore } from 'src/stores/ApplicationStore/ApplicationStore';
import { addModalRenderer } from 'src/utils/ModalRenderer';

const store = ApplicationStore;

export const RequestOthersConditionsModal = observer(({ ...props }) => {
    const { t } = useTranslation();
    const currentApplication = store.currentItem;

    return (
        <Modal
            {...props}
            title={t(
                'Dialog.RequestOthersConditionsModal.Title',
                'Please, describe your suggestions',
            )}
            subtitle={t(
                'Dialog.RequestOthersConditionsModal.Subtitle',
                'Our credit committee will review them. Please, note, that we do not guarantee their acceptance',
            )}
            isLoading={ApplicationStore.requestConditionsAction.isLoading}
            onFinish={async (values) => {
                await ApplicationStore.requestConditions(
                    currentApplication?.id?.toString() as string,
                    values,
                );
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <TextArea
                        name="contrOffer"
                        rows={8}
                        label=""
                        rules={[
                            ValidationRules.required,
                            ValidationRules.max255Symbols,
                        ]}
                    />
                </Col>
            </Row>
        </Modal>
    );
});

export const AddOtherConditionsModal = addModalRenderer(
    RequestOthersConditionsModal,
);
