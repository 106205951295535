import { Button } from 'antd';
import styled from 'styled-components';

export const StyledSubmitButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

export const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;
