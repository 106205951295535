import { Col, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { LoanViewDTOStatusEnum } from 'src/generated-api-client';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import styled from 'styled-components';

const store = LoansStore;

const { Text } = Typography;

export const MasterElementBriefInfo = observer(() => {
    const { t } = useTranslation();
    const currentLoan = store?.currentItem;

    return (
        <Col span={24}>
            <Row align="bottom">
                <Col flex="auto">
                    <Text strong>
                        {currentLoan?.id && `#${currentLoan?.id} `}
                        {currentLoan?.type &&
                            t(
                                `Loan.Application.Type.${currentLoan?.type}`,
                            )}{' '}
                        /{' '}
                        {currentLoan?.disclosure &&
                            t(
                                `Loan.Application.Disclosure.${currentLoan?.disclosure}`,
                            )}
                    </Text>
                </Col>
                <Col>
                    {currentLoan?.status && (
                        <StyledStatusWrapper status={currentLoan?.status}>
                            {t(`Loan.View.DTOStatus.${currentLoan?.status}`)}
                        </StyledStatusWrapper>
                    )}
                </Col>
            </Row>
        </Col>
    );
});

export const loanMapColors = {
    [LoanViewDTOStatusEnum.ACTIVE]: '#ACF392',
    [LoanViewDTOStatusEnum.OVERDUE]: '#EB7382',
    [LoanViewDTOStatusEnum.WRITTENOFF]: '#EB7382',
    [LoanViewDTOStatusEnum.DEFAULT]: '#EB7382',
    [LoanViewDTOStatusEnum.PAIDUP]: '#ACF392',
};

const StyledStatusWrapper = styled.div<{ status?: string }>`
    box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.52);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    background: ${(props) =>
        loanMapColors[props?.status as keyof typeof loanMapColors]};
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    line-height: 22px;
    color: #000000;
`;
