import { observer } from 'mobx-react-lite';
import React from 'react';
import { Trans } from 'react-i18next';
import {
    StatusView,
    StatusViewType,
} from 'src/components/StatusView/StatusView';
import { CustomerVerificationStatus } from 'src/generated-api-client';

type CustomerVerificationStatusProps = {
    status?: CustomerVerificationStatus;
};

export const CustomerVerificationStatusView = observer(
    ({ status }: CustomerVerificationStatusProps) => {
        const statusInfo = status && CustomerStatusInfo[status];

        return (
            <StatusView
                backgroundColor={statusInfo?.backgroundColor}
                title={statusInfo?.title}
            />
        );
    },
);

export const CustomerStatusInfo: Record<string, StatusViewType> = {
    [CustomerVerificationStatus.WAITDOCUMENTS]: {
        title: <Trans i18nKey="Customer.VerificationStatus.WAIT_DOCUMENTS" />,
        backgroundColor: '#FFFF80',
    },
    [CustomerVerificationStatus.CREDITBUREAUCHECK]: {
        title: (
            <Trans i18nKey="Customer.VerificationStatus.CREDIT_BUREAU_CHECK" />
        ),
        backgroundColor: '#FFFF80',
    },
    [CustomerVerificationStatus.INREVIEW]: {
        title: <Trans i18nKey="Customer.VerificationStatus.IN_REVIEW" />,
        backgroundColor: '#FFFF80',
    },
    [CustomerVerificationStatus.VERIFIED]: {
        title: <Trans i18nKey="Customer.VerificationStatus.VERIFIED" />,
        backgroundColor: '#D6F7A0',
    },
    [CustomerVerificationStatus.DECLINED]: {
        title: <Trans i18nKey="Customer.VerificationStatus.DECLINED" />,
        backgroundColor: '#EB7382',
    },
    [CustomerVerificationStatus.REJECTEDBYCREDITBUREAU]: {
        title: (
            <Trans i18nKey="Customer.VerificationStatus.REJECTED_BY_CREDIT_BUREAU" />
        ),
        backgroundColor: '#EB7382',
    },
    [CustomerVerificationStatus.INFOREQUESTED]: {
        title: <Trans i18nKey="Customer.VerificationStatus.INFO_REQUESTED" />,
        backgroundColor: '#FFFF80',
    },
};
