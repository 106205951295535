import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { BasePage } from 'src/components/BasePage/BasePage';
import { TabDefinition, Tabs } from 'src/components/Tabs/Tabs';
import {
    LoanApplicationDisclosure,
    LoanApplicationType,
} from 'src/generated-api-client';
import { Counterparty } from 'src/pages/LoanPage/Components/Counterparty';
import { LoanBalances } from 'src/pages/LoanPage/Components/LoanBalances';
import { LoanDetails } from 'src/pages/LoanPage/Components/LoanDetails';
import { LoanMasterElement } from 'src/pages/LoanPage/Components/MasterElement/LoanMasterElement';
import { OutAndOutMasterElement } from 'src/pages/LoanPage/Components/MasterElement/OutAndOutMasterElement';
import { OperationsTab } from 'src/pages/LoanPage/Components/OperationsTab';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';

const store = LoansStore;

export const LoanPage = observer(() => {
    const { params } = useRouteMatch<{ loanId: string }>();

    useEffect(() => {
        store.loadItem(params.loanId);
        store.getOperations(params.loanId);

        return () => {
            store.itemLoader.reset();
            store.getOperationsAction.reset();
        };
    }, [params.loanId]);

    const operationsData = store?.getAllSnapshots;

    const showDisclosure =
        store?.currentItem?.disclosure === LoanApplicationDisclosure.DISCLOSED;

    const isOutAndOutInvoiceFinanceType =
        store.currentItem?.type === LoanApplicationType.OUTANDOUTINVOICEFINANCE;

    const applicationTabs: TabDefinition[] = [
        {
            tabKey: 'general-info',
            title: <Trans i18nKey="Page.Loan.Tabs.GeneralInformation" />,
            Component: (
                <>
                    <LoanDetails />
                    {showDisclosure && <Counterparty />}
                    {!isOutAndOutInvoiceFinanceType && <LoanBalances />}
                </>
            ),
        },
        {
            tabKey: 'operations',
            title: <Trans i18nKey="Page.Loan.Tabs.Operations" />,
            Component: <OperationsTab dataSource={operationsData} />,
        },
    ];

    return (
        <BasePage
            header={
                isOutAndOutInvoiceFinanceType ? (
                    <OutAndOutMasterElement />
                ) : (
                    <LoanMasterElement />
                )
            }
            isLoading={store.itemLoader.isLoading}
        >
            <Tabs tabs={applicationTabs} />
        </BasePage>
    );
});
