// @ts-nocheck
import { computed } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { ApplyLoanPage } from 'src/pages/ApplyLoanPage/ApplyLoanPage';
import { DashboardPage } from 'src/pages/DashboardPage/DashboardPage';
import { IndexPage } from 'src/pages/IndexPage/IndexPage';
import { InformationPage } from 'src/pages/InformationPage/InformationPage';
import { LoanApplicationPage } from 'src/pages/LoanApplicationPage/LoanApplicationPage';
import { LoanApplications } from 'src/pages/LoanApplicationsPage/LoanApplications';
import { LoanPage } from 'src/pages/LoanPage/LoanPage';
import { LoansPage } from 'src/pages/LoansPage/LoansPage';
import { OfferLetterPage } from 'src/pages/OfferLetterPage/OfferLetterPage';
import { ProfilePage } from 'src/pages/ProfilePage/ProfilePage';
import { QaPage } from 'src/pages/QaPage/QaPage';
import { SecondStepSignUpPage } from 'src/pages/SecondStepSignUpPage/SecondStepSignUpPage';
import { SignContractPage } from 'src/pages/SignContractPage/SignContractPage';
import { ThirdStepSignupPage } from 'src/pages/ThirdStepSignupPage/ThirdStepSignupPage';
import { UpdateDocumentsPage } from 'src/pages/UpdateDocumentsPage/UpdateDocumentsPage';
import {
    isRouteGroup,
    RouteDefinition,
} from 'src/stores/RouterStore/RouterStore.types';

export class RouterStoreClass {
    // eslint-disable-next-line no-underscore-dangle
    @computed private get routeDefinitions(): RouteDefinition[] {
        return [
            {
                key: '/',
                path: '/',
                Component: IndexPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.signupSecondStep,
                path: RoutePaths.signupSecondStep,
                Component: SecondStepSignUpPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.signupThirdStep,
                path: RoutePaths.signupThirdStep,
                Component: ThirdStepSignupPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.informationPage,
                path: RoutePaths.informationPage,
                Component: InformationPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.applyForALoanStep,
                path: RoutePaths.applyForALoanStep,
                Component: ApplyLoanPage,
                exact: false,
                hidden: false,
            },
            {
                key: RoutePaths.dashboard,
                path: RoutePaths.dashboard,
                Component: DashboardPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.updateDocuments,
                path: RoutePaths.updateDocuments,
                Component: UpdateDocumentsPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.profile,
                path: RoutePaths.profile,
                Component: ProfilePage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.loanApplications,
                path: RoutePaths.loanApplications,
                Component: LoanApplications,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.loanApplication,
                path: RoutePaths.loanApplication,
                Component: LoanApplicationPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.offerLetter,
                path: RoutePaths.offerLetter,
                Component: OfferLetterPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.signContract,
                path: RoutePaths.signContract,
                Component: SignContractPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.loans,
                path: RoutePaths.loans,
                Component: LoansPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.loan,
                path: RoutePaths.loan,
                Component: LoanPage,
                exact: true,
                hidden: false,
            },
            {
                key: RoutePaths.faq,
                path: RoutePaths.faq,
                Component: QaPage,
                exact: true,
                hidden: false,
            },
        ];
    }

    @computed get routes() {
        return this.routeDefinitions
            .flatMap((route) => {
                if (isRouteGroup(route)) {
                    return [route, ...(route.children || [])];
                }

                return route;
            })
            .filter(Boolean) as RouteDefinition[];
    }

    @computed get menuItems(): RouteDefinition[] {
        return this.routeDefinitions.filter((route) => !route.unavailable);
    }
}

export const RouterStore = new RouterStoreClass();
