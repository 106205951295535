import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage/BasePage';
import { DashboardStore } from 'src/stores/DashboardStore/DashboardStore';

import { LeftSide } from './Components/LeftSide';
import { PageHeader } from './Components/PageHeader';
import { RightSide } from './Components/RightSide';

export const DashboardPage = observer(() => {
    useEffect(() => {
        DashboardStore.loadItem();
    }, []);

    return (
        <BasePage>
            <PageHeader />
            <Row gutter={[24, 24]} justify="space-between">
                <Col span={11}>
                    <LeftSide />
                </Col>
                <Col span={11}>
                    <RightSide />
                </Col>
            </Row>
        </BasePage>
    );
});
