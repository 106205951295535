/* eslint-disable max-lines */
import { Rule, RuleObject } from 'antd/lib/form';
import { t } from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Moment } from 'moment';
import { Trans } from 'react-i18next';

export enum AttachmentsFormat {
    jpeg = 'image/jpeg',
    jpg = 'image/jpg',
    gif = 'image/gif',
    png = 'image/png',
    svg = 'image/svg+xml',
    bmp = 'image/bmp',
    tiff = 'image/tiff',
    pdf = 'application/pdf',
    doc = 'application/msword',
    docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    csv = 'text/csv',
    txt = 'text/plain',
    ppt = 'application/vnd.ms-powerpoint',
    pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rtf = 'application/rtf',
    xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls = 'application/vnd.ms-excel',
}

export enum FileSizeUnit {
    bytes = 1,
    Kb = 2 ** 10,
    Mb = 2 ** 20,
    Gb = 2 ** 30,
    Tb = 2 ** 40,
}

export const FileMeasures = {
    mb: 'Mb',
};

export const MAX_FILE_SIZE = 5;

export enum FieldMaxLength {
    Name = 100,
    Address = 100,
    Email = 100,
    Comment = 500,
    PeriodDays = 30,
    PeriodHours = 23,
    PeriodMinutes = 59,
    AmlCheckDurationHours = 1000,
    RepeatedRequestsAmount = 1000,
}

const exeptedFormats = [
    AttachmentsFormat.bmp,
    AttachmentsFormat.jpeg,
    AttachmentsFormat.jpg,
    AttachmentsFormat.csv,
    AttachmentsFormat.svg,
    AttachmentsFormat.doc,
    AttachmentsFormat.docx,
    AttachmentsFormat.pdf,
    AttachmentsFormat.png,
    AttachmentsFormat.tiff,
    AttachmentsFormat.ppt,
    AttachmentsFormat.pptx,
    AttachmentsFormat.rtf,
    AttachmentsFormat.xls,
    AttachmentsFormat.xlsx,
    AttachmentsFormat.txt,
];
const mineTypeImageJpeg = ['jfif', 'pjpeg', 'pjp'];

const NumberPattern = new RegExp(/^\+?\d*$/);
const RegExpName = new RegExp(/^[\p{L}, \s-]+$/u);
const decimalPattern = new RegExp(/^\d{1,100}\.?\d{0,2}$/);

/* eslint-disable no-useless-escape */
// eslint-disable-next-line unicorn/no-unsafe-regex
const emailPattern = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
);

const getIdNumberPattern = (lastTwoYearDigits: string, count = '6') => {
    return new RegExp(`^\\d{4}${lastTwoYearDigits}\\d{${count}}$`);
};

const name: Rule = {
    message: <Trans i18nKey="Form.Rules.Validator.Messages.NameError" />,
    pattern: RegExpName,
};

const minLength: Rule = {
    min: 3,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.MinLength" />,
};

const minAccountBankLength: Rule = {
    min: 12,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.AccountBankMinLength" />
    ),
};

const maxLength: Rule = {
    max: 100,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.MaxLength" />,
};

const maxLengthLegalName: Rule = {
    max: 55,
    message: 'Max 55 symbols',
};

const maxAccountBankLength: Rule = {
    max: 18,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.AccountBankMaxLength" />
    ),
};

const maxTaxIDLength: Rule = {
    max: 10,
    message: 'Please, enter valid tax ID',
};

const minTaxIDLength: Rule = {
    min: 10,
    message: 'Please, enter valid tax ID',
};

const minPhoneLength: Rule = {
    min: 11,
    message: 'Please, enter valid telephone number',
};

const maxPhoneLength: Rule = {
    max: 12,
    message: 'Please, enter valid telephone number',
};

const maxCommentLength: Rule = {
    max: FieldMaxLength.Comment,
    message: (
        <Trans i18nKey="Form.Rules.Validator.Messages.MaxCommentLengthError" />
    ),
};

const CIPCMessage =
    'Please, insure CIPC number format is in line with one of this examples: B1055678458 or 123456789012';

const maxCIPC: Rule = {
    max: 12,
    message: CIPCMessage,
};

const minCIPC: Rule = {
    min: 11,
    message: CIPCMessage,
};

const email: Rule = {
    pattern: emailPattern,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.EmailError" />,
};

const required: Rule = {
    required: true,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.Required" />,
};

const bankDetails: Rule = {
    required: true,
    message: <Trans i18nKey="Form.Rules.Validator.Messages.BankDetails" />,
};

const max255Symbols: Rule = {
    max: 255,
    message: 'Max 255 symbols',
};

const phone: Rule = {
    validator: (_, value) => {
        if (
            (isValidPhoneNumber(value) && NumberPattern.test(value)) ||
            !value.length
        ) {
            return Promise.resolve(true);
        }

        return Promise.reject(new Error(t('Form.Rules.WrongFormat')));
    },
};

const number: Rule = {
    pattern: new RegExp(/^\d{1,}$/),
    message: <Trans i18nKey="Form.Rules.WrongFormat" />,
};

const idNumber: Rule = (form): RuleObject => {
    return {
        validator(_, value: string) {
            return new Promise((resolve, reject) => {
                const year = (form.getFieldValue('birthDate') as Moment).get(
                    'year',
                );
                const lastTwoYearDigits = String(year).slice(2);

                const validators =
                    !value.length ||
                    (year >= 2020
                        ? isNewIdNumber(value, lastTwoYearDigits)
                        : isOldIdNumber(value, lastTwoYearDigits));

                if (validators) {
                    resolve(true);
                } else {
                    reject(t('Form.Rules.Validator.Messages.NationalId'));
                }
            });
        },
    };
};

const onlyNumber: Rule = {
    pattern: new RegExp(/^\d+$/),
    message: <Trans i18nKey="Form.Rules.Validator.Messages.OnlyNumberError" />,
};

const nationalId: Rule = (form): RuleObject => {
    return {
        validator(_, value: string) {
            return new Promise((resolve, reject) => {
                const year = (form.getFieldValue('birthDate') as Moment).get(
                    'year',
                );
                const lastTwoYearDigits = String(year).slice(2);

                const validators =
                    !value.length ||
                    (year >= 2020
                        ? isNewIdNumber(value, lastTwoYearDigits)
                        : isOldIdNumber(value, lastTwoYearDigits));

                if (validators) {
                    resolve(true);
                } else {
                    reject(t('Form.Rules.WrongFormat'));
                }
            });
        },
    };
};

const getFileSizeInBytes = (size: number, unit: FileSizeUnit) => {
    return size * unit;
};

const maxFileSize = (
    size: number,
    unit: FileSizeUnit = FileSizeUnit.Mb,
    textUnit?: string,
) => {
    return {
        validator: (_: any, value: any) => {
            if (value?.length > 0) {
                if (
                    value?.reduce((init: number, file: any) => {
                        return init + file.originFileObj.size;
                    }, 0) < getFileSizeInBytes(size, unit)
                ) {
                    return Promise.resolve(true);
                }

                return Promise.reject(
                    new Error(`Maximum file size is ${size}${textUnit}`),
                );
            }

            return Promise.resolve(true);
        },
    };
};

const isOldIdNumber = (value: string, lastTwoYearDigits: string) => {
    if (value.length === 9 && NumberPattern.test(value)) {
        return true;
    }

    return getIdNumberPattern(lastTwoYearDigits).test(value);
};

const isNewIdNumber = (value: string, lastTwoYearDigits: string) => {
    return getIdNumberPattern(lastTwoYearDigits).test(value);
};

const password: Rule = {
    pattern: new RegExp(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    ),
    message: <Trans i18nKey="Form.Rules.Password" default="Invalid password" />,
};

const confirmPassword: Rule = (form): RuleObject => {
    return {
        validator(_, value) {
            if (!value || form.getFieldValue('password') === value) {
                return Promise.resolve();
            }

            return Promise.reject(
                new Error(
                    `${t('Components.Form.NotMatch', "Passwords don't match")}`,
                ),
            );
        },
    };
};

const amountValidationMessage = 'Form.Rules.Validator.Messages.ValidAmount';

const notMorethenMillion: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value <= 1000000) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(t(amountValidationMessage)));
        },
    };
};

const notMoreThanHundered: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value <= 100) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(t(amountValidationMessage)));
        },
    };
};

const notMoreThanTen: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value <= 10) {
                return Promise.resolve();
            }

            return Promise.reject(new Error(t(amountValidationMessage)));
        },
    };
};

const notMoreThanBillion: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value <= 1000000000) {
                return Promise.resolve();
            }

            return Promise.reject(new Error('Please, enter valid loan amount'));
        },
    };
};

const notMoreThan100M: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value <= 100000000) {
                return Promise.resolve();
            }

            return Promise.reject(new Error('Please, enter valid loan amount'));
        },
    };
};

const validCalculateAmount: Rule = (): RuleObject => {
    return {
        validator(_, value: string) {
            return new Promise((resolve, reject) => {
                const validators =
                    Number(value) <= 0 || Number(value) > 10000000;
                if (value && validators) {
                    return reject(t('Page.Dashboard.Amount.Validation'));
                }
                if (value && !decimalPattern.test(value.toString())) {
                    return reject(
                        t('Page.Dashboard.Amount.Validation.InvalidValues'),
                    );
                }

                return resolve(true);
            });
        },
    };
};

const notLessThan1: Rule = (): RuleObject => {
    return {
        validator(_: any, value: any) {
            if (value < 1) {
                return Promise.reject(new Error('Please, enter valid amount'));
            }

            return Promise.resolve();
        },
    };
};

const validateFilesType: Rule = (): RuleObject => {
    return {
        validator: (_: any, value: any) => {
            const isMineTypeImageJpeg = value?.some((item: any) =>
                mineTypeImageJpeg.includes(
                    item?.name?.split('.')?.[1]?.toLowerCase(),
                ),
            );

            const isValidFormat = value?.some(
                (item: any) => !exeptedFormats.includes(item?.type),
            );

            if (value && value?.length > 0) {
                if (isValidFormat || isMineTypeImageJpeg) {
                    return Promise.reject(
                        new Error(`This file format is not supported`),
                    );
                }

                return Promise.resolve(true);
            }

            return Promise.resolve(true);
        },
    };
};

const CIPCFormatValidation: Rule = {
    pattern: new RegExp(/^(([A-z]{1}[0-9]{10})|([0-9]{12}))$/),
    message:
        'Please, insure CIPC number format is in line with one of this examples: B1055678458 or 123456789012',
};

const isTheSameCIPCValue = (comparisonValue: any) => {
    return {
        validator: (_: any, value: any) => {
            if (value === comparisonValue) {
                return Promise.reject(
                    new Error(`This CIPS is equal to your's company CIPC`),
                );
            }

            return Promise.resolve(true);
        },
    };
};

const isTheSameEmailValue = (comparisonValue: any) => {
    return {
        validator: (_: any, value: any) => {
            if (value === comparisonValue) {
                return Promise.reject(
                    new Error(`Can not be the same as customer's email`),
                );
            }

            return Promise.resolve(true);
        },
    };
};

const decimal: Rule = {
    pattern: new RegExp(/^\d{1,10}\.?\d{0,2}$/),
    message: 'Invalid value, two decimal places allowed',
};

const mustBeLessThan = (fieldPath: any[], message: string, form: any) => {
    return {
        validator: (_: any, value: any) => {
            if (Number(value) >= Number(form.getFieldValue(fieldPath))) {
                return Promise.reject(new Error(message));
            }

            return Promise.resolve(true);
        },
    };
};

export const ValidationRules = {
    maxLength,
    minLength,
    required,
    email,
    name,
    idNumber,
    nationalId,
    phone,
    number,
    minPhoneLength,
    maxPhoneLength,
    minAccountBankLength,
    maxAccountBankLength,
    onlyNumber,
    maxCommentLength,
    maxFileSize,
    password,
    confirmPassword,
    maxLengthLegalName,
    notMorethenMillion,
    notMoreThanHundered,
    notMoreThanTen,
    maxTaxIDLength,
    minTaxIDLength,
    notMoreThanBillion,
    maxCIPC,
    minCIPC,
    validateFilesType,
    isTheSameCIPCValue,
    CIPCFormatValidation,
    decimal,
    max255Symbols,
    mustBeLessThan,
    notMoreThan100M,
    notLessThan1,
    validCalculateAmount,
    isTheSameEmailValue,
    bankDetails,
};
