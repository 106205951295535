/* eslint-disable max-lines */
import {
    Button,
    Col,
    Form as AntForm,
    Form,
    notification,
    Row,
    Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { Brief } from 'src/components/Brief/Brief';
import { DnDUploader } from 'src/components/DnDUploader/DnDUploader';
import { CheckboxField } from 'src/components/Form/CheckboxField';
import {
    FileMeasures,
    FileSizeUnit,
    ValidationRules,
} from 'src/components/Form/ValidationRules';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import {
    CustomerDocumentsStore,
    CustomerDocumentsTypes,
} from 'src/stores/CustomerDocumentsStore/CustomerDocumentsStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import styled from 'styled-components';

const { Title } = Typography;

const store = ProfileStore;

export const ThirdStepSignupPage = observer(() => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const [form] = AntForm.useForm();

    useEffect(() => {
        store.loadProfileData();
    }, []);

    return (
        <BasePage isLoading={isLoading}>
            <SignUpPageContainer>
                <Brief>
                    <Row>
                        <Col flex="auto">
                            <StyledPageTitle level={2}>
                                {t(
                                    'Page.ThirdStepSignupPage.Title',
                                    'Registration form',
                                )}
                            </StyledPageTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col flex="auto">
                            <StyledPageTitle level={3}>
                                <Trans i18nKey="Page.ThirdStepSignupPage.SubTitle" />
                            </StyledPageTitle>
                        </Col>
                    </Row>
                    <Form
                        form={form}
                        onFinish={async (values) => {
                            setIsLoading(true);
                            const documents = Object.entries(values).filter(
                                (item) => item[0] !== 'condition',
                            );
                            try {
                                await CustomerDocumentsStore.uploadCustomerDocuments(
                                    documents as [
                                        type: CustomerDocumentsTypes,
                                        files: any,
                                    ][],
                                );
                                await store.confirmThirdStep();
                            } catch (error) {
                                notification.error({
                                    message: t(
                                        'Error.SomethingWentWrong',
                                        'Something went wrong',
                                    ),
                                    description:
                                        // @ts-ignore
                                        error?.detail || error.title,
                                });
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                    >
                        <Row gutter={24} justify="space-between">
                            <Col span={11}>
                                <DnDUploader
                                    maxCount={20}
                                    name="CUSTOMER_CIPC"
                                    label={t(
                                        'Page.ThirdStepSignupPage.CIPCDocument',
                                    )}
                                    rules={[
                                        ValidationRules.required,
                                        ValidationRules.maxFileSize(
                                            25,
                                            FileSizeUnit.Mb,
                                            FileMeasures.mb,
                                        ),
                                        ValidationRules.validateFilesType,
                                    ]}
                                />
                            </Col>
                            <Col span={11}>
                                <DnDUploader
                                    maxCount={20}
                                    name="CUSTOMER_DIRECTOR_IDS"
                                    label={t(
                                        'Page.ThirdStepSignupPage.CUSTOMER_DIRECTOR_IDS',
                                    )}
                                    rules={[
                                        ValidationRules.required,
                                        ValidationRules.maxFileSize(
                                            25,
                                            FileSizeUnit.Mb,
                                            FileMeasures.mb,
                                        ),
                                        ValidationRules.validateFilesType,
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24} justify="space-between">
                            <Col span={11}>
                                <DnDUploader
                                    maxCount={20}
                                    name="CUSTOMER_TAX_CLEARANCE"
                                    label={t(
                                        'Page.ThirdStepSignupPage.CUSTOMER_TAX_CLEARANCE',
                                        'Tax Clearance Document',
                                    )}
                                    rules={[
                                        ValidationRules.required,
                                        ValidationRules.maxFileSize(
                                            25,
                                            FileSizeUnit.Mb,
                                            FileMeasures.mb,
                                        ),
                                        ValidationRules.validateFilesType,
                                    ]}
                                />
                            </Col>
                            <Col span={11}>
                                <DnDUploader
                                    maxCount={20}
                                    name="CUSTOMER_PROOF_OF_ADDRESS"
                                    label={t(
                                        'Page.ThirdStepSignupPage.CUSTOMER_PROOF_OF_ADDRESS',
                                        'Proof of address',
                                    )}
                                    rules={[
                                        ValidationRules.required,
                                        ValidationRules.maxFileSize(
                                            25,
                                            FileSizeUnit.Mb,
                                            FileMeasures.mb,
                                        ),
                                        ValidationRules.validateFilesType,
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24} justify="start">
                            <Col span={11}>
                                <DnDUploader
                                    maxCount={20}
                                    name="CUSTOMER_AUTHORIZATION_LETTER"
                                    label={t(
                                        'Page.ThirdStepSignupPage.CUSTOMER_AUTHORIZATION_LETTER',
                                    )}
                                    rules={[
                                        ValidationRules.required,
                                        ValidationRules.maxFileSize(
                                            25,
                                            FileSizeUnit.Mb,
                                            FileMeasures.mb,
                                        ),
                                        ValidationRules.validateFilesType,
                                    ]}
                                />
                            </Col>
                        </Row>
                        <AgreementBlock>
                            <Row gutter={24} justify="center">
                                <Col>
                                    <CheckboxField
                                        name="condition"
                                        checkboxLabel={
                                            <>
                                                <div>
                                                    {t(
                                                        'Page.ThirdStepSignupPage.Condition',
                                                        'I declare that information provided is correct and',
                                                    )}
                                                </div>
                                                <a
                                                    target="_blank"
                                                    href={
                                                        RoutePaths.informationPage
                                                    }
                                                    rel="noreferrer"
                                                >
                                                    {t(
                                                        'Page.ThirdStepSignupPage.Condition.Href',
                                                        "give my consent to process company's informations",
                                                    )}
                                                </a>
                                            </>
                                        }
                                        rules={[ValidationRules.required]}
                                    />
                                </Col>
                            </Row>
                        </AgreementBlock>
                        <Row gutter={24} justify="center">
                            <StyledButtonContainer>
                                <StyledResetButton
                                    key="cancel"
                                    loading={
                                        store.setProfileSecondStepDataAction
                                            .isLoading
                                    }
                                    onClick={() => {
                                        form.resetFields();
                                        AuthStore.logout(
                                            `${window.location.origin}/`,
                                        );
                                    }}
                                >
                                    {t('Button.Cancel', 'Cancel')}
                                </StyledResetButton>

                                <StyledBackButton
                                    key="back"
                                    loading={
                                        store.setProfileSecondStepDataAction
                                            .isLoading
                                    }
                                    onClick={() => {
                                        form.resetFields();
                                        Router.navigate(
                                            RoutePaths.signupSecondStep,
                                        );
                                    }}
                                >
                                    {t('Button.Back', 'Back')}
                                </StyledBackButton>

                                <StyledSaveButton
                                    key="ok"
                                    type="primary"
                                    htmlType="submit"
                                    loading={
                                        store.setProfileSecondStepDataAction
                                            .isLoading
                                    }
                                >
                                    {t('Button.Submit', 'Submit')}
                                </StyledSaveButton>
                            </StyledButtonContainer>
                        </Row>
                    </Form>
                </Brief>
            </SignUpPageContainer>
        </BasePage>
    );
});

const SignUpPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 150px;
    .ant-form-item-label {
        overflow: initial;
    }
`;

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    margin-top: 20px;
    text-align: center;
    white-space: nowrap;
    flex-wrap: nowrap;
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledBackButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: transparent;
    color: #000000;
    border: 1.5px solid #000000;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: transparent;
        color: #aaaaaa;
        border: 1.5px solid #aaaaaa;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 9px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:focus,
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;

const AgreementBlock = styled.div`
    dispaly: flex;
    justify-content: center !important;
    align-items: flex-start;
    margin-top: 50px;
    .ant-form-item {
        margin-bottom: 1px;
    }
    .ant-form-item-control-input-content {
        a {
            color: #0000ff;
        }
        label {
            font-size: 18px;
        }
    }
`;
