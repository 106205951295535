import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { LoanSnapshotDTO } from 'src/generated-api-client';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

export const OperationsTab = observer(
    ({ dataSource }: { dataSource?: LoanSnapshotDTO[] }) => {
        return (
            <StyledTable
                pagination={false}
                columns={columns}
                dataSource={dataSource}
                locale={{ emptyText: false }}
            />
        );
    },
);

const columns = [
    {
        title: <Trans i18nKey="Page.Loan.Tabs.Operations.Date" />,
        dataIndex: 'dateTime',
        key: 'dateTime',
        render: (date: any) => <>{moment(date).format('DD.MM.YYYY')}</>,
    },
    {
        title: <Trans i18nKey="Page.Loan.Tabs.Operations.Operation" />,
        dataIndex: 'calculationEvent',
        key: 'calculationEvent',
        render: (calculationEvent: any) => (
            <Trans
                i18nKey={`Loans.SnapshotDTO.calculationEvent.${calculationEvent}`}
            />
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.Tabs.Operations.PrincipalDue" />,
        dataIndex: 'principalDue',
        key: 'principalDue',
        render: (principalDue: any) => (
            <>{NumberHelper.formatThousandSeparator(principalDue, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.Tabs.Operations.AccruedInterestDue" />,
        dataIndex: 'accruedInterestDue',
        key: 'accruedInterestDue',
        render: (accruedInterestDue: any) => (
            <>{NumberHelper.formatThousandSeparator(accruedInterestDue, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.Tabs.Operations.InitiationFeeDue" />,
        dataIndex: 'initiationFeeDue',
        key: 'initiationFeeDue',
        render: (initiationFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(initiationFeeDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans i18nKey="Page.Loan.Tabs.Operations.VatInitiationFeeDue" />
        ),
        dataIndex: 'vatInitiationFeeDue',
        key: 'vatInitiationFeeDue',
        render: (vatInitiationFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(vatInitiationFeeDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans i18nKey="Page.Loan.Tabs.Operations.MonthlyServiceFeeDue" />
        ),
        dataIndex: 'monthlyServiceFeeDue',
        key: 'monthlyServiceFeeDue',
        render: (monthlyServiceFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(monthlyServiceFeeDue, 2)}</>
        ),
    },
    {
        title: (
            <Trans i18nKey="Page.Loan.Tabs.Operations.VatMonthlyServiceFeeDue" />
        ),
        dataIndex: 'vatMonthlyServiceFeeDue',
        key: 'vatMonthlyServiceFeeDue',
        render: (vatMonthlyServiceFeeDue: any) => (
            <>
                {NumberHelper.formatThousandSeparator(
                    vatMonthlyServiceFeeDue,
                    2,
                )}
            </>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.Tabs.Operations.InsuranceFeeDue" />,
        dataIndex: 'insuranceFeeDue',
        key: 'insuranceFeeDue',
        render: (insuranceFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(insuranceFeeDue, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.Tabs.Operations.VatInsuranceFeeDue" />,
        dataIndex: 'vatInsuranceFeeDue',
        key: 'vatInsuranceFeeDue',
        render: (vatInsuranceFeeDue: any) => (
            <>{NumberHelper.formatThousandSeparator(vatInsuranceFeeDue, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.Tabs.Operations.PenaltyInterest" />,
        dataIndex: 'penaltyInterest',
        key: 'penaltyInterest',
        render: (penaltyInterest: any) => (
            <>{NumberHelper.formatThousandSeparator(penaltyInterest, 2)}</>
        ),
    },
    {
        title: <Trans i18nKey="Page.Loan.Tabs.Operations.OverdueTotal" />,
        dataIndex: 'overdueTotal',
        key: 'overdueTotal',
        render: (overdueTotal: any) => (
            <>{NumberHelper.formatThousandSeparator(overdueTotal, 2)}</>
        ),
    },
];

const StyledTable = styled(Table)`
    margin-top: 10px;
    margin-bottom: 40px;
    .ant-table-thead {
        .ant-table-cell {
            font-size: 12px;
            background: transparent;
            font-weight: 600;
        }
        .ant-table-cell::before {
            display: none;
        }
    }
    .ant-table-tbody {
        .ant-table-cell {
            color: #000000;
            font-size: 12px;
        }
    }
    .ant-table-summary {
        background: #bfbfbf5c;
    }
`;
