import { Spinner } from 'src/components/Spinner/Spinner';
import LogoIcon from 'src/icons/logoOfficeMinCap.png';
import styled from 'styled-components';

export const SplashScreen = () => {
    return (
        <StyledAppSplashScreen>
            <StyledWrapper>
                <StyledLogo>
                    <img src={LogoIcon} alt="" />
                </StyledLogo>{' '}
            </StyledWrapper>
            <Spinner />
        </StyledAppSplashScreen>
    );
};

const StyledAppSplashScreen = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledLogo = styled.span`
    font-weight: bold;
    font-size: 32px;
    max-width: 150px;
    img {
        width: 100%;
    }
`;

const StyledWrapper = styled.div`
    color: #0d0f1c;
    display: flex;
    margin-bottom: 30px;

    &:hover {
        text-decoration: none;
        color: #0d0f1c;
    }
`;
