import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { BlockTitle } from 'src/components/BlockTitle/BlockTitle';
import { DataView } from 'src/components/DataView/DataView';
import { DashboardStore } from 'src/stores/DashboardStore/DashboardStore';

export const LeftSide = observer(() => {
    const dashboardData = DashboardStore.currentItem?.data;
    const RUAND_PREFIX = 'R';

    return (
        <Row gutter={[24, 24]} justify="space-between">
            <Col span={24}>
                <BlockTitle>
                    <Trans i18nKey="Page.Dashboard.DealsSummary.Title" />
                </BlockTitle>
            </Col>
            <Col flex="auto">
                <Trans i18nKey="Page.Dashboard.Amount.Label" />
            </Col>
            <Col>
                <DataView
                    noMargins
                    hideLabel
                    format="number-int"
                    prefix={dashboardData?.sumTotalExposure ? RUAND_PREFIX : ''}
                    value={dashboardData?.sumTotalExposure}
                />
            </Col>
            <Col flex="auto">
                <Trans i18nKey="Page.Dashboard.Amount60.Label" />
            </Col>
            <Col>
                <DataView
                    noMargins
                    hideLabel
                    format="number-int"
                    prefix={dashboardData?.amountDue60Days ? RUAND_PREFIX : ''}
                    value={dashboardData?.amountDue60Days}
                />
            </Col>
            <Col flex="auto">
                <Trans i18nKey="Page.Dashboard.Amount30.Label" />
            </Col>
            <Col>
                <DataView
                    noMargins
                    hideLabel
                    format="number-int"
                    prefix={dashboardData?.amountDue30Days ? RUAND_PREFIX : ''}
                    value={dashboardData?.amountDue30Days}
                />
            </Col>
            <Col span={16}>
                <Trans i18nKey="Page.Dashboard.ActiveLoans.Label" />
            </Col>
            <Col>
                <DataView
                    noMargins
                    hideLabel
                    value={dashboardData?.countActiveLoans}
                />
            </Col>
            <Col span={16}>
                <Trans i18nKey="Page.Dashboard.NextPayment.Label" />
            </Col>
            <Col>
                <DataView
                    noMargins
                    hideLabel
                    value={dashboardData?.minPaymentDate}
                />
            </Col>
            <Col span={16}>
                <Trans i18nKey="Page.Dashboard.NextPaymentAmount.Label" />
            </Col>
            <Col>
                <DataView
                    noMargins
                    hideLabel
                    format="number-int"
                    prefix={
                        dashboardData?.nearestPaymentAmount ? RUAND_PREFIX : ''
                    }
                    value={dashboardData?.nearestPaymentAmount}
                />
            </Col>
            <Col span={16}>
                <Trans i18nKey="Page.Dashboard.PaymentWithin.Label" />
            </Col>
            <Col>
                <DataView
                    noMargins
                    hideLabel
                    value={dashboardData?.maxPaymentDate}
                />
            </Col>
            <Col span={24}>
                <BlockTitle>
                    <Trans i18nKey="Page.Dashboard.DocumentsSummary.Title" />
                </BlockTitle>
            </Col>
            <Col span={16}>
                <Trans i18nKey="Page.Dashboard.RegistrationValid.Label" />
            </Col>
            <Col>
                <DataView
                    noMargins
                    hideLabel
                    value={dashboardData?.documentsValidTillDate}
                />
            </Col>
        </Row>
    );
});
