/* eslint-disable no-return-await */
import { action, computed } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import {
    BasicStoreApi,
    Entity,
} from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { Sorter, SorterDirection } from 'src/utils/mobx/Sorter';

export abstract class BasicStore<
    Item extends Entity,
    Filter = any,
    ItemForUpdate = Item,
    ItemForCreate = Item,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ItemDetails = Item,
> {
    abstract api: BasicStoreApi<
        Item,
        ItemForUpdate,
        ItemForCreate,
        ItemDetails
    >;

    filterCriteria?: FilterCriteria<Filter>;
    pager?: Pager;
    sorter?: Sorter;

    listLoader = new AsyncOperationWithStatus((...args: any[]) => {
        return this.api.loadList?.(...args);
    });

    itemLoader = new AsyncOperationWithStatus((...args: any[]) => {
        return this.api.loadItem?.(...args);
    });

    updateItemLoader = new AsyncOperationWithStatus((data: ItemForUpdate) => {
        return this.api.updateItem?.(data);
    });

    createItemLoader = new AsyncOperationWithStatus((data: ItemForCreate) => {
        return this.api.createItem?.(data);
    });

    removeItemLoader = new AsyncOperationWithStatus((id: number | string) => {
        return this.api.removeItem?.(id);
    });

    activateItemLoader = new AsyncOperationWithStatus((id: number | string) => {
        return this.api.activate?.(id);
    });

    deactivateItemLoader = new AsyncOperationWithStatus(
        (id: number | string) => {
            return this.api.deactivate?.(id);
        },
    );

    @action async loadList() {
        this.listLoader.reset();
        await this.listLoader.call();
    }

    @action async loadItem(id?: string | number) {
        await this.itemLoader.call(id);
    }

    @action async updateItem(data: ItemForUpdate) {
        this.updateItemLoader.reset();

        return await this.updateItemLoader.call(data);
    }

    @action async createItem(data: ItemForCreate) {
        this.createItemLoader.reset();

        return await this.createItemLoader.call(data);
    }

    @action async remove(id: string | number) {
        return await this.removeItemLoader?.call(id);
    }

    @action async activate(id: string | number) {
        return await this.activateItemLoader?.call(id);
    }

    @action async deactivate(id: string | number) {
        return await this.deactivateItemLoader?.call(id);
    }

    @computed get list() {
        return (this.listLoader.data || []) as any as Item[];
    }

    @computed get currentItem() {
        return this.itemLoader.data;
    }

    @action setSorting(field?: string | string[], order?: SorterDirection) {
        if (this.sorter) {
            this.sorter.setSorter(field, order);
            this.pager?.reset();
            this.loadList();
        }
    }

    @action setDefaultSorting() {
        this.sorter?.reset();
        this.pager?.reset();
        this.loadList();
    }
}
