import { useEffect } from 'react';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';

type PandaDocEventTypes =
    | 'session_view.document.loaded'
    | 'session_view.document.completed'
    | 'session_view.document.exception';
const eventTypeToSubmit: Array<PandaDocEventTypes> = [
    'session_view.document.completed',
];

const onMessageHandler = (e: MessageEvent<any>, applicationId: string) => {
    const eventType = getEventType(e.data);

    if (eventTypeToSubmit.includes(eventType)) {
        Router.navigate(`${RoutePaths.loanApplications}/${applicationId}`);
    }
};

export const usePandaDoc = (applicationId: string) => {
    useEffect(() => {
        window.addEventListener('message', (e) =>
            onMessageHandler(e, applicationId),
        );

        return () => {
            window.removeEventListener('message', (e) =>
                onMessageHandler(e, applicationId),
            );
        };
    }, []);
};

const getEventType = (eventData: any) => {
    return eventData && eventData.type;
};
