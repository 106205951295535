import { QuestionCircleFilled } from '@ant-design/icons';
import {
    Form as AntForm,
    FormInstance,
    Radio,
    RadioChangeEvent,
    Row,
    Space,
    Tooltip,
    Typography,
} from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import {
    FeeRepaymentMethod,
    LoanApplicationDisclosure,
    LoanApplicationType,
} from 'src/generated-api-client';
import { ApplyForALoanStore } from 'src/stores/ApplyForALoanStore/ApplyForALoanStore';
import styled from 'styled-components';

const { Title } = Typography;

const store = ApplyForALoanStore;

export const DealTypeFormItem = observer(
    ({ form }: { form: FormInstance<any> }) => {
        const onChangeDealType = (e: RadioChangeEvent) => {
            if (
                e.target.value === LoanApplicationType.OUTANDOUTINVOICEFINANCE
            ) {
                store.setFormLoanApplicationData({
                    feeRepaymentMethod: FeeRepaymentMethod.ADDTOLOAN,
                    ...form.getFieldsValue(),
                });
                store.setDealType(e.target.value);
                form.setFieldValue(
                    'feeRepaymentMethod',
                    FeeRepaymentMethod.ADDTOLOAN,
                );
            }
            if (
                e.target.value === LoanApplicationType.PURCHASEORDERFINANCE ||
                e.target.value === LoanApplicationType.FINANCEOFDEBTOR
            ) {
                store.setFormLoanApplicationData({
                    ...form.getFieldsValue(),
                    disclosure: LoanApplicationDisclosure.UNDISCLOSED,
                });
                store.setDealType(e.target.value);
                form.setFieldValue(
                    'disclosure',
                    LoanApplicationDisclosure.UNDISCLOSED,
                );
            } else {
                store.setFormLoanApplicationData({
                    ...form.getFieldsValue(),
                    disclosure: LoanApplicationDisclosure.DISCLOSED,
                });
                store.setDealType(e.target.value);
                form.setFieldValue(
                    'disclosure',
                    LoanApplicationDisclosure.DISCLOSED,
                );
            }
        };

        const dealType = useWatch('type', form);

        return (
            <>
                <Row gutter={24} justify="center">
                    <StyledPageTitle level={5}>
                        {dealType && (
                            <Tooltip
                                title={() => {
                                    switch (dealType) {
                                        case LoanApplicationType.PURCHASEORDERFINANCE:
                                            return t(
                                                'Page.ApplyForALoan.DealType.PURCHASE_ORDER_FINANCE',
                                            );
                                        case LoanApplicationType.INVOICEFINANCE:
                                            return t(
                                                'Page.ApplyForALoan.DealType.INVOICE_FINANCE',
                                            );
                                        case LoanApplicationType.FINANCEOFDEBTOR:
                                            return t(
                                                'Page.ApplyForALoan.DealType.FINANCE_OF_DEBTOR',
                                            );
                                        case LoanApplicationType.OUTANDOUTINVOICEFINANCE:
                                            return t(
                                                'Page.ApplyForALoan.DealType.OUT_AND_OUT_INVOICE_FINANCE',
                                            );

                                        default:
                                            return null;
                                    }
                                }}
                            >
                                <QuestionCircleFilled />
                            </Tooltip>
                        )}

                        {t('Page.ApplyForALoan.DealType', 'Deal type')}
                    </StyledPageTitle>
                </Row>
                <Row gutter={24} justify="center">
                    <AntForm.Item
                        name="type"
                        rules={[ValidationRules.required]}
                    >
                        <Radio.Group onChange={onChangeDealType}>
                            <Space direction="horizontal">
                                <StyledRadio
                                    value={LoanApplicationType.INVOICEFINANCE}
                                >
                                    {t('Loan.Application.Type.INVOICE_FINANCE')}
                                </StyledRadio>
                                <StyledRadio
                                    value={
                                        LoanApplicationType.OUTANDOUTINVOICEFINANCE
                                    }
                                >
                                    {t(
                                        'Loan.Application.Type.OUT_AND_OUT_INVOICE_FINANCE',
                                    )}
                                </StyledRadio>
                                <StyledRadio
                                    value={
                                        LoanApplicationType.PURCHASEORDERFINANCE
                                    }
                                >
                                    {t(
                                        'Loan.Application.Type.PURCHASE_ORDER_FINANCE',
                                    )}
                                </StyledRadio>
                                <StyledRadio
                                    value={LoanApplicationType.FINANCEOFDEBTOR}
                                >
                                    {t(
                                        'Loan.Application.Type.FINANCE_OF_DEBTOR',
                                    )}
                                </StyledRadio>
                            </Space>
                        </Radio.Group>
                    </AntForm.Item>
                </Row>
            </>
        );
    },
);

const StyledPageTitle = styled(Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    .anticon {
        margin-right: 10px;
    }
`;

const StyledRadio = styled(Radio)`
    span {
        font-size: 16px;
    }
    .ant-radio-inner {
        border-color: #000000;
    }
    .ant-radio-inner::after {
        background-color: #5c5c5c;
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: #5c5c5c;
    }
`;
