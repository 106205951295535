import _ from 'lodash';
import { action, computed } from 'mobx';
import {
    ApiResponseBodyCustomerDashboardView,
    CustomerDashboardView,
    DesiredLoanCalculationParams,
    LoanApplicationDisclosure,
    LoanApplicationTerm,
} from 'src/generated-api-client';
import { dashboardApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export class DashboardStoreClass extends BasicStore<
    CustomerDashboardView,
    undefined,
    undefined,
    undefined,
    ApiResponseBodyCustomerDashboardView
> {
    initialValues: DesiredLoanCalculationParams = {
        amount: 100000,
        disclosure: LoanApplicationDisclosure.DISCLOSED,
        term: LoanApplicationTerm.THIRTY,
    };

    pager = new Pager();
    api: BasicStoreApi<
        CustomerDashboardView,
        undefined,
        undefined,
        ApiResponseBodyCustomerDashboardView
    > = {
        loadItem: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                dashboardApi.getCustomerDashboard(),
            );
        },
    };

    calculateLoader = new AsyncOperationWithStatus(
        (data: DesiredLoanCalculationParams) => {
            return RequestHelper.unwrapFromAxiosPromise(
                dashboardApi.calculateDesiredLoan(data),
            );
        },
    );

    @action private calculateParams(data: DesiredLoanCalculationParams) {
        return this.calculateLoader.call(data);
    }

    @computed get calculatedParams() {
        return this.calculateLoader.data?.data;
    }

    calculate = _.throttle(this.calculateParams.bind(this), 300);

    @action async load() {
        await this.calculate(this.initialValues);
    }

    @action reset() {
        this.calculateLoader.reset();
    }
}

export const DashboardStore = new DashboardStoreClass();
