import { action, computed, observable } from 'mobx';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import {
    ContrOfferForm,
    LoanApplicationOfferLetter,
    LoanApplicationViewDTO,
    RequestedInformationForm,
} from 'src/generated-api-client';
import { loanApplicationsApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ApplicationStoreClass extends BasicStore<LoanApplicationViewDTO> {
    @observable private offerLetterData?: LoanApplicationOfferLetter;
    @observable private agreementLink?: string;

    api: BasicStoreApi<LoanApplicationViewDTO> = {
        loadItem: async (id: number) => {
            const result = await RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.entityView2(Number(id)),
            );

            return result.data || {};
        },
    };

    declineAgreementAction = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationsApi.contractSigningDecline(Number(id)),
        ),
    );

    requestAgreementAction = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationsApi.contractSigningUrl(Number(id)),
        ),
    );

    requestConditionsAction = new AsyncOperationWithStatus(
        (id: string, contrOfferForm: ContrOfferForm) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.addContrOffer(Number(id), contrOfferForm),
            ),
    );

    declineOfferLetterAction = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationsApi.declineOfferLetter(Number(id)),
        ),
    );

    acceptOfferLetterAction = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationsApi.approveOfferLetter(Number(id)),
        ),
    );

    getOfferLetterAction = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationsApi.getOfferLetter(Number(id)),
        ),
    );

    downloadAgreementAction = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            loanApplicationsApi.contractSigningDownload(Number(id), {
                responseType: 'arraybuffer',
            }),
        ),
    );

    updateRequestedInfoAction = new AsyncOperationWithStatus(
        (requestedInformationForm: RequestedInformationForm) =>
            RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.updateRequestedInformation(
                    requestedInformationForm,
                ),
            ),
    );

    timeout: any;

    @action
    async updateRequestedInfo(
        requestedInformationForm: RequestedInformationForm,
    ) {
        await this.updateRequestedInfoAction.call(requestedInformationForm);
        if (!this.updateRequestedInfoAction.hasError) {
            this.loadItem(requestedInformationForm.id);
        }
    }

    @action
    async declineAgreement(id: string) {
        await this?.declineAgreementAction.call(id);
        if (!this?.declineAgreementAction.hasError) {
            Router.navigate(`${RoutePaths.index}`);
        }
    }

    @action
    async getOfferLetter(id: string) {
        const result = await this?.getOfferLetterAction.call(id);
        this.offerLetterData = result?.data;
    }

    @action
    async declineOfferLetter(id: string) {
        await this?.declineOfferLetterAction.call(id);
        if (!this?.declineOfferLetterAction.hasError) {
            Router.navigate(`${RoutePaths.index}`);
        }
    }

    @action
    async approveOfferLetter(id: string) {
        await this?.acceptOfferLetterAction.call(id);
        if (!this?.acceptOfferLetterAction.hasError) {
            Router.navigate(
                `${RoutePaths.loanApplications}/${id}/sign-contract`,
            );
        }
    }

    @action
    async requestAgreement(id: string) {
        const res = await this?.requestAgreementAction.call(id);
        if (this?.requestAgreementAction.hasData) {
            this.agreementLink = res?.data;
        } else {
            this.timeout = window.setTimeout(() => {
                this.requestAgreement(id);
            }, 2000);
        }
    }

    @action
    async requestConditions(id: string, contrOfferForm: ContrOfferForm) {
        await this?.requestConditionsAction.call(id, contrOfferForm);
        if (!this?.requestConditionsAction.hasError) {
            Router.navigate(`${RoutePaths.loanApplications}/${id}`);
        }
    }

    @action
    async downloadAgreement(id: string) {
        const res = await this?.downloadAgreementAction.call(id);
        const file = window.URL.createObjectURL(
            new Blob([res], {
                type: 'application/pdf',
            }),
        );
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', 'mincap-agreement');
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }

    @computed get offerLetter() {
        return this.offerLetterData;
    }

    @computed get contractLink() {
        return this.agreementLink;
    }

    @computed get sortedFinancedItemsByASC() {
        return this.currentItem?.financedItems?.sort((a, b) => {
            if (a?.id && b?.id) {
                return a?.id - b?.id;
            }

            return -1;
        });
    }

    @action resetContractLink() {
        this.agreementLink = '';
    }

    @action resetSetTimeOut() {
        window.clearTimeout(this.timeout);
    }
}

export const ApplicationStore = new ApplicationStoreClass();
