import { Button, Form, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BasePage } from 'src/components/BasePage/BasePage';
import { TextField } from 'src/components/Form/TextField';
import { ValidationRules } from 'src/components/Form/ValidationRules';
import { BankAccountDTO } from 'src/generated-api-client';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';
import { FormHelper } from 'src/utils/FormHelper';
import { addModalRenderer, ModalRendererProps } from 'src/utils/ModalRenderer';
import styled from 'styled-components';

const AddNewBankAccountDialogComponent = observer(
    ({ ...props }: ModalRendererProps) => {
        const { t } = useTranslation();
        const [form] = Form.useForm();
        const fields = FormHelper.getFieldDataItems(
            ProfileStore.addNewBankAccountLoader.errorData,
        );

        const onFinish = async (values: BankAccountDTO) => {
            await ProfileStore.addNewBankAccount(values);
            if (!ProfileStore.addNewBankAccountLoader.hasError) {
                await ProfileStore.loadProfileData();
                props.onCancel();
            }
        };

        const onCancel = () => {
            props.onCancel();
            ProfileStore.addNewBankAccountLoader.reset();
        };

        return (
            <StyledModal
                {...props}
                title={t('Page.Profile.AddNewBankAccountDialog.Header')}
                onCancel={onCancel}
                width={800}
                footer={[
                    <StyledResetButton onClick={onCancel}>
                        {t('Button.Cancel')}
                    </StyledResetButton>,
                    <StyledSaveButton
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        {t('Button.Submit')}
                    </StyledSaveButton>,
                ]}
            >
                <BasePage
                    isLoading={ProfileStore.addNewBankAccountLoader.isLoading}
                >
                    <Form
                        form={form}
                        fields={fields}
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <TextField
                            name="bankName"
                            label={t(
                                'Page.Profile.AddNewBankAccountDialog.BankName',
                            )}
                            rules={[ValidationRules.required]}
                        />
                        <TextField
                            name="accountName"
                            label={t(
                                'Page.Profile.AddNewBankAccountDialog.AccountName',
                            )}
                            rules={[ValidationRules.required]}
                        />
                        <TextField
                            name="accountType"
                            label={t(
                                'Page.Profile.AddNewBankAccountDialog.AccountType',
                            )}
                            rules={[ValidationRules.required]}
                        />
                        <TextField
                            name="branchCode"
                            label={t(
                                'Page.Profile.AddNewBankAccountDialog.BranchCode',
                            )}
                            rules={[ValidationRules.required]}
                        />
                        <TextField
                            name="accountNumber"
                            label={t(
                                'Page.Profile.AddNewBankAccountDialog.AccountNumber',
                            )}
                            rules={[ValidationRules.required]}
                        />
                    </Form>
                </BasePage>
            </StyledModal>
        );
    },
);

export const AddNewBankAccountDialog = addModalRenderer(
    AddNewBankAccountDialogComponent,
);

const StyledModal = styled(Modal)`
    .ant-layout-content {
        margin: 0;
        min-width: 100%;
    }
    .ant-form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 20px;
    }
`;

const StyledSaveButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #d8021c;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:active,
    &:hover {
        background: #d8021cba;
        color: #ffffff;
        border: none;
    }
`;

const StyledResetButton = styled(Button)`
    width: 160px;
    height: 42px;
    margin-left: 5px;
    border-radius: 10px;
    border: none;
    background: #222325;
    color: #ffffff;
    .anticon {
        transform: translateY(-2px);
    }
    &:active,
    &:hover {
        background: #222325e3;
        color: #ffffff;
        border: none;
    }
`;
